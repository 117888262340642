import {
  object as YUPObject,
  // number as YUPNumber,
  boolean as YUPBoolean,
  date as YUPDate,
  string as YUPString,
  array as YUPArray,
} from 'yup';
import { PresenterCourse as PresenterCourseAPI } from '../api/presenter-course';
import { PresenterCourse as PresenterCourseInterface } from '../definitions/interfaces/presenter-course';
import { PresenterLessons as PresenterLessonsInterface } from '../definitions/interfaces/presenter-lesson';
import { BranchLookup, SchoolSystem as SchoolSystemInterface } from '../definitions/interfaces/entity-course';
import { Subject as SubjectInterface } from '../definitions/interfaces/subject';
// import { Presenter as PresenterInterface } from '../definitions/interfaces/entity-course';
import { PresenterClass as PresenterClassInterface } from '../definitions/interfaces/presenter-class';
import { AddClassFormInput } from '../definitions/interfaces/inputs/add-class-input';
import { Payload } from '../definitions/interfaces/payload';
import { AddLessonInput } from '../definitions/interfaces/inputs/add-lesson-input';
import { getPayloadData } from './common';
import { AddCourseInput } from '../definitions/interfaces/inputs/add-course-input';
import { CourseBody } from '../definitions/interfaces/entity-courses-listing';
import { Presenter } from './../definitions/interfaces/entity-dashboard-classes';

/**
 * schema to validate add class form.
 */
export const ADD_CLASS_SCHEMA = YUPObject().shape({
  className: YUPString().required('please add course name'),
  startDate: YUPDate().required('please select start date'),
  endDate: YUPDate()
    .when('startDate', (startDate: Date, schema: any) => startDate && schema.min(startDate))
    .required('please select end date'),
  classType: YUPString().required('please select course type'),
  isRecorded: YUPBoolean(),
  // fullClassPrice: YUPNumber().min(1).required('please add full course price'),
});

export const ADD_COURSE_SCHEMA = YUPObject().shape({
  name: YUPString().required('Please add course name'),
  groupFocusValueId: null,
  schoolSystemIds: YUPArray().required('please select school systems'),
  gradeIds: YUPArray().required('please select school system grades'),
  presenterId: YUPString().required('please select a presenter'),
  branchId: YUPString().required('please select a branch'),
  weekDays: YUPArray().required('please select weekdays'),
  description: YUPString().required('please add description'),
  objectives: YUPString().required('please add objective'),
  typeId: null,
  capacity: YUPString().required('please Enter capacity'),
  individualBookingEnabled: YUPBoolean(),
  image: YUPString().required('please add course Image'),
});

/**
 * group of Order helpers functionalities.
 */
export class PresenterCourse {
  /**
   * get details of selected presenter course
   *
   * @static
   * @param {string} courseId
   * @returns {Promise<PresenterCourseInterface>}
   * @memberof PresenterCourse
   */
  public static getPresenterCourseDetails(courseId: string): Promise<PresenterCourseInterface> {
    return PresenterCourseAPI.getPresenterCourseDetails(courseId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   * get details of selected Presenter course
   *
   * @static
   * @param {string} courseId
   * @returns {Promise<PresenterLessonsInterface>}
   * @memberof PresenterCourse
   */
  public static getPresenterCourseLessons(courseId: string): Promise<PresenterLessonsInterface> {
    return PresenterCourseAPI.getPresenterCourseLessons(courseId).then((result) => {
      return getPayloadData(result);
    });
  }

  /**
   * get get allCourses courses
   *
   * @static
   * @returns {Promise<Payload<CourseBody>>}
   * @memberof Session
   */
  public static getAllCourses(offset: number, limit: number): Promise<CourseBody[]> {
    return PresenterCourseAPI.getAllCourses(offset, limit)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   * update presenter course publish status
   *
   * @static
   * @param {number} publishStatusId
   * @returns {Promise<Payload>}
   * @memberof PresenterCourse
   */
  public static updatePublishCourseStatus(
    publishStatusId: number,
    course: PresenterCourseInterface,
    courseLessons: PresenterLessonsInterface
  ): Promise<Payload | void> {
    const courseBookings = (course.capacity.fullBooking ?? 0) + (course.capacity.individualBooking ?? 0);
    const courseHasClasses = courseLessons.lessons?.find((lesson) => lesson.classes?.length > 0);
    // Validate that course contains bookings
    // Can't un-publish a course with bookings
    if (courseBookings > 0 && publishStatusId === 2) {
      return Promise.reject('Cannot un-publish a course with bookings');
    }

    if (!courseHasClasses && publishStatusId === 1) {
      return Promise.reject('Cannot publish a course without classes');
    }

    return PresenterCourseAPI.updatePublishCourseStatus(publishStatusId, course.id).then((result) => {
      return result;
    });
  }

  public static addPresenterCourse(input: AddCourseInput): Promise<PresenterCourseInterface> {
    return PresenterCourseAPI.addPresenterCourse(input).then((result) => {
      return getPayloadData(result);
    });
  }

  /**
   * edit Presenter Course
   *
   * @static
   * @param {AddCourseInput} input
   * @returns {Promise<PresenterCourseInterface>}
   * @memberof PresenterCourse
   */
  public static editPresenterCourse(courseId: string, input: AddCourseInput): Promise<PresenterCourseInterface> {
    return PresenterCourseAPI.editPresenterCourse(courseId, input).then((result) => {
      return getPayloadData(result);
    });
  }
  /**
   * add Presenter Class
   *
   * @static
   * @param {AddClassFormInput} input
   * @returns {Promise<Payload>}
   * @memberof PresenterCourse
   */
  public static addPresenterClass(courseId: string, lessonId: string, input: AddClassFormInput): Promise<unknown> {
    return PresenterCourseAPI.addPresenterClass(courseId, lessonId, input)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }
  /**
   * edit Presenter Class
   *
   * @static
   * @param {AddLessonInput} input
   * @returns {Promise<Payload>}
   * @memberof PresenterCourse
   */
  public static editPresenterClass(
    courseId: string,
    lessonId: string,
    classId: string,
    input: AddClassFormInput
  ): Promise<unknown> {
    return PresenterCourseAPI.editPresenterClass(courseId, lessonId, classId, input)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  /**
   * add Presenter Lesson
   *
   * @static
   * @param {AddLessonInput} input
   * @returns {Promise<Payload>}
   * @memberof PresenterCourse
   */
  public static addPresenterLesson(courseId: string, input: AddLessonInput): Promise<unknown> {
    return PresenterCourseAPI.addPresenterLesson(courseId, input)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }
  /**
   * get all course subjects
   *
   * @static
   * @returns {Promise<SubjectInterface[]>}
   * @memberof PresenterCourse
   */
  public static getAllSubjects(): Promise<SubjectInterface[]> {
    return PresenterCourseAPI.getAllSubjects().then((result) => {
      return getPayloadData(result);
    });
  }

  /**
   * get all course school systems
   *
   * @static
   * @returns {Promise<SchoolSystemInterface[]>}
   * @memberof PresenterCourse
   */
  public static getAllSchoolSystems(): Promise<SchoolSystemInterface[]> {
    return PresenterCourseAPI.getAllSchoolSystems().then((result) => {
      return getPayloadData(result);
    });
  }

  /**
   * get all course presenters
   *
   * @static
   * @returns {Promise<PresenterInterface[]>}
   * @memberof PresenterCourse
   */
  //   public static getAllPresenters(): Promise<PresenterInterface[]> {
  //     return PresenterCourseAPI.getAllPresenters().then((result) => {
  //       return getPayloadData(result);
  //     });
  //   }

  /**
   * get all branches
   *
   * @static
   * @returns {Promise<unknown>}
   * @memberof PresenterCourse
   */
  public static getAllBranches(): Promise<BranchLookup[]> {
    return PresenterCourseAPI.getAllBranches().then((result) => {
      return getPayloadData(result);
    });
  }

  /**
   * get details of selected presenter course
   *
   * @static
   * @param {string} courseId
   * @returns {Promise<PresenterClassInterface>}
   * @memberof presenterCourse
   */
  public static getPresenterClass(
    courseId: string,
    lessonId: string,
    classId: string
  ): Promise<PresenterClassInterface> {
    return PresenterCourseAPI.getPresenterClass(courseId, lessonId, classId).then((result) => {
      return getPayloadData(result);
    });
  }

  public static startOnlineClass(
    courseId: string,
    lessonId: string,
    classId: string,
    presenter: Presenter
  ): Promise<string> {
    return PresenterCourseAPI.startOnlineClass(courseId, lessonId, classId, presenter).then((payload) => {
      return getPayloadData(payload).then((data) => data?.presenterJoinUrl);
    });
  }
}
