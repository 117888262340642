import React, { useState, useEffect } from 'react';
import Toastr from 'toastr';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../../../definitions/consts/routes';
import { CourseStatus, EntityCourse as EntityCourseInterface } from '../../../../definitions/interfaces/entity-course';
import { EntityLessons as EntityLessonsInterface } from '../../../../definitions/interfaces/entity-lesson';
import { EntityCourse as EntityCourseUtilities } from '../../../../utilities/entity-course';
import EntityCourseInfo from './entity-course-info';
import EntityCourseLessons from './entity-course-lessons';
import AddEditLessonModal from './add-edit-lesson-modal';
import Loader from 'src/ts/react-components/common/loader';
// import Loader from 'src/ts/react-components/common/loader';
import { EntityCourse as EntityCourseAPI } from 'src/ts/api/entity-course';

interface SelectOption {
  value: number;
  label: string;
}

const EntityCourse: React.FunctionComponent = () => {
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(null);
  const [loadingCourseDetails, setLoadingCourseDetails] = useState<boolean>(true);
  const [courseDetails, setCourseDetails] = useState<EntityCourseInterface | null>(null);
  const [courseLessons, setCourseLessons] = useState<EntityLessonsInterface | null>(null);
  const { courseId } = useParams<{ courseId: string; lessonId: string }>();
  const [showModal, setShowModal] = useState(false);
  const [shouldRefreshLessonsListing, setShouldRefreshLessonsListing] = useState<boolean>(false);
  const [modalState, setModalState] = useState('');
  const history = useHistory();

  useEffect(() => {
    //fetch Course details based on id
    if (courseId) {
      setLoadingCourseDetails(true);
      EntityCourseUtilities.getEntityCourseDetails(courseId)
        .then((response) => {
          setCourseDetails(response);
          setLoadingCourseDetails(false);
          setSelectedOption({
            value: response.status.id,
            label: response.status.name === CourseStatus.published ? 'Published' : 'Not published',
          });
        })
        .catch((error: any) => {
          Toastr.error(error);
          console.error(error);
        });
    }
  }, [courseId]);

  const getLessons = () => {
    EntityCourseUtilities.getEntityCourseLessons(courseId)
      .then((response) => {
        setCourseLessons(response);
      })
      .catch((error: any) => {
        Toastr.error(error);
        console.error(error);
      });
  };
  useEffect(() => {
    //fetch Course details based on id
    if (courseId) {
      getLessons();
    }
  }, [courseId, shouldRefreshLessonsListing]);

  const handleUpdatePublishStatus = (publishOption: SelectOption) => {
    if (publishOption.value !== selectedOption.value) {
      EntityCourseUtilities.updatePublishCourseStatus(publishOption.value, courseDetails, courseLessons)
        .then(() => {
          setSelectedOption(publishOption);
          setCourseDetails({
            ...courseDetails,
            status: {
              id: publishOption.value,
              name: publishOption.value === 1 ? CourseStatus.published : CourseStatus.unpublished,
            },
          });
          setLoadingCourseDetails(false);
          Toastr.success('Course published successfully');
        })
        .catch((error: any) => {
          Toastr.error(error);
        });
    }
  };
  let weekDays = ``;
  const publishOptions: SelectOption[] = [];
  if (courseDetails?.status?.name === CourseStatus.unpublished) {
    publishOptions.push({ value: 1, label: 'Published' });
  }

  if (courseDetails?.status?.name === CourseStatus.published) {
    publishOptions.push({ value: 2, label: 'Not published' });
  }

  for (let index = 0; index < courseDetails?.weekDays.length; index = index + 1) {
    if (index < courseDetails?.weekDays.length - 2) {
      weekDays += `${courseDetails?.weekDays[index]}, `;
    } else if (index < courseDetails?.weekDays.length - 1) {
      weekDays += `${courseDetails?.weekDays[index]} & `;
    } else {
      weekDays += `${courseDetails?.weekDays[index]}`;
    }
  }

  const [selectedLessonId, setSelectedLessonId] = useState('');
  const [selectedLessonName, setSelectedLessonName] = useState('');
  const handleOpenModal = (state, lessonId?: string, lessonName?: string) => {
    setSelectedLessonId(lessonId);
    setSelectedLessonName(lessonName);
    if (state == 'add') {
      setModalState('add');
      setShowModal(true);
    } else if (state == 'edit') {
      setModalState('edit');
      setShowModal(true);
    }
  };

  // const selectedLessonId = courseLessons?.lessons?.find((lesson) => lesson.id == lessonId);
  const handleEditeCourse = () => {
    history.push({
      pathname: `${ROUTES.editEntityCourse.getPath && ROUTES.editEntityCourse.getPath(courseId)}`,
      state: courseDetails,
    });
  };

  const markAsDone = (classId) => {
    EntityCourseAPI.confirmClass(classId)
      // .then()
      .then(() => getLessons())
      .catch((error) => console.error(error));
  };

  const [searchParams, setSearchParams] = useState<any>();
  useEffect(() => {
    setSearchParams(location.search);
    return () => {
      if (history.action === 'POP') {
        history.push({
          pathname: `${ROUTES.entityDashboard.getPath('Courses')}`,
          search: searchParams,
        });
      }
    };
  }, [history, searchParams]);

  if (loadingCourseDetails) return <Loader />;
  else {
    return (
      <>
        <EntityCourseInfo
          courseImage={courseDetails?.image}
          createdAt={courseDetails?.createdAt}
          courseTitle={courseDetails?.title}
          groupFocusName={courseDetails?.groupFocusValue.name}
          schooleSystems={courseDetails?.schoolSystems}
          reviewSummary={courseDetails?.reviewSummary}
          presenter={courseDetails?.presenter}
          handleUpdatePublishStatus={handleUpdatePublishStatus}
          publishOptions={publishOptions}
          selectedOption={selectedOption}
          courseStatusName={courseDetails?.status?.name}
          isCourseStatusPuplished={CourseStatus.published}
          handleEditeCourse={handleEditeCourse}
          capacity={courseDetails?.capacity}
          currentValue={courseDetails?.capacity.fullBooking}
          totalValue={courseDetails?.capacity.total}
          description={courseDetails?.description}
          objectives={courseDetails?.objectives}
        />
        {/* start of course Rules Section */}
        <div className="entity-course__rules">
          <h2 className="entity-course__rules-title">course rules</h2>
          <p className="entity-course__rules-paragraph">{`Weekdays: ${weekDays}`}</p>
          <p className="entity-course__rules-paragraph">{`Takes place ${courseDetails?.type.name} ${
            courseDetails?.branch?.area?.name && ` & at ${courseDetails?.branch.area.name} branch`
          }`}</p>
          <p className="entity-course__rules-paragraph">{`Walk-in is ${
            courseDetails?.individualBookingEnabled ? 'available (24 hours prior)' : 'not available'
          } `}</p>
        </div>
        {/* end of course Rules Section */}
        {/* start of course Lessons Section */}
        <EntityCourseLessons
          courseDetailsId={courseDetails?.id}
          lessonsCount={courseLessons?.count?.lessons}
          classesCount={courseLessons?.count?.classes}
          courseDetailsStatus={courseDetails?.status}
          courseDetailsLessons={courseLessons?.lessons}
          handleOpenModal={handleOpenModal}
          setShouldRefreshLessonsListing={setShouldRefreshLessonsListing}
          courseId={courseId}
          coursePuplishedStatus={CourseStatus?.published}
          selectedOption={selectedOption}
          presenter={courseDetails?.presenter}
          markAsDone={markAsDone}
          // buttonState={buttonState}
          // setButtonState={setButtonState}
        />
        {/* end Lessons Accordion section */}
        {/* Modal of edit and add leson */}
        <div className="add-lesson__modal">
          {showModal && courseId && (
            <AddEditLessonModal
              modalState={modalState}
              showModal={showModal}
              selectedLessonId={selectedLessonId}
              selectedLessonName={selectedLessonName}
              setShowModal={setShowModal}
              courseId={courseId}
              setShouldRefreshLessonsListing={setShouldRefreshLessonsListing}
            />
          )}
        </div>
      </>
    );
  }
};

export default EntityCourse;
