import React, { useEffect } from 'react';
import time from '../../../../../static/images/icons/time.svg';
import { capitalizeFirstLetter } from 'src/ts/utilities/common';
import { formate } from 'src/ts/utilities/moment-formate-Date';

interface UnconfirmedClassCardProps {
  classId: string;
  endedAt: string;
  image: string;
  courseName: string;
  teacherName: string;
  teacherPronoun: string;
  className: string;
  confirmClass: (classId: string) => void;
}

const UnConfirmedClass: React.FunctionComponent<UnconfirmedClassCardProps> = (props) => {
  const [btnState, setBtnState] = React.useState<string | null>('unconfirmed');

  const handleBtnState = async (state, classId) => {
    switch (state) {
      case 'confirm':
        setBtnState('confirm');
        break;
      case 'confirmed':
        await props.confirmClass(classId);
        setBtnState(null);
        break;
      default:
        setBtnState('unconfirmed');
    }
  };

  useEffect(() => {
    setBtnState('unconfirmed');
  }, []);

  return (
    <div className="uncofirmed_card--wrapper">
      <div className="uncofirmed_card">
        <div className="unconfirm_img">
          <img src={props.image} alt="" height="200" width="200" />
        </div>
        <div className="card_body">
          <p className="card__date">
            <img src={time} alt="duration" />
            {`Ended ${formate(props.endedAt)}`}
          </p>
          <p className="card__title">{props.className}</p>
          <p className="card__subtitle">
            {capitalizeFirstLetter(props?.courseName)}
            by {capitalizeFirstLetter(props?.teacherPronoun)} {capitalizeFirstLetter(props?.teacherName)}
          </p>

          {btnState == 'unconfirmed' ? (
            <div className="btn--container">
              <button className="btn btn--blue" onClick={() => handleBtnState('confirm', props.classId)}>
                Mark as done
              </button>
            </div>
          ) : btnState == 'confirm' ? (
            <button className="btn btn--green" onClick={() => handleBtnState('confirmed', props.classId)}>
              confirm
            </button>
          ) : (
            <button className="btn btn--green"> &#10003; Marked as done</button>
          )}
        </div>
      </div>
    </div>
  );
};
export default UnConfirmedClass;
