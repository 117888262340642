import { Network } from './network';
import { ENDPOINTS } from './endpoints';
import { Payload } from '../definitions/interfaces/payload';
import { AddTeacherBody, singleTeacher, Teacher } from '../definitions/interfaces/teacher';

export class EntityTeacher {
  /**
   * fetch entity teachers
   *
   * @static
   * @returns {Promise<Payload<Teacher>>}
   * @memberof  EntityTeacher
   */
  public static getAllTeachers(): Promise<Payload<Teacher[]>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getAllTeachers.url}`, {
      method: ENDPOINTS.getAllTeachers.method,
    });
  }

  /**
   * delete entity teachers
   *
   * @static
   * @returns {Promise<Payload>}
   * @memberof EntityTeacher
   */
  public static deleteTeacher(teacherId: string): Promise<Payload> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.deleteTeacher.url(teacherId)}`, {
      method: ENDPOINTS.deleteTeacher.method,
    });
  }

  /**
   * add entity teacher
   *
   * @static
   * @param {string} input
   * @returns {Promise<Payload>}
   * @memberof entityTeacher
   */
  public static addEntityTeacher(input: AddTeacherBody): Promise<Payload> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.addEntityTeacher.url}`, {
      method: ENDPOINTS.addEntityTeacher.method,
      body: JSON.stringify(input),
    });
  }

  /**
   * edit entity teacher
   *
   * @static
   * @param {string} input
   * @returns {Promise<Payload>}
   * @memberof entityTeacher
   */
  public static editEntityTeacher(teacherId: string, input: AddTeacherBody): Promise<Payload> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.editEntityTeacher.url(teacherId)}`, {
      method: ENDPOINTS.editEntityTeacher.method,
      body: JSON.stringify(input),
    });
  }

  /**
   * get entity teacher by id
   *
   * @static
   * @param {string} input
   * @returns {Promise<Payload>}
   * @memberof entityTeacher
   */
  public static getEntityTeacherById(teacherId: string): Promise<Payload<singleTeacher>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getEntityTeacherById.url(teacherId)}`, {
      method: ENDPOINTS.getEntityTeacherById.method,
    });
  }
}
