import { EntityCommonInterface } from './common';

export interface Presenter {
  id: string;
  title: string;
  name: string;
  image: string;
  firstName?: string;
  lastName?: string;
}

export interface Branch {
  id: string;
  name: string;
  area: EntityCommonInterface;
}

export interface BranchLookup {
  addressName: string;
  area: { id: number; name: string };
  branchId: string;
  branchName: string;
  branchTitle: string;
  createdAt: string;
  organizationId: string;
}

export interface Capacity {
  total: number;
  fullBooking: number;
  individualBooking: number;
}

export interface SchoolSystem {
  id: number;
  name: string;
  grades: EntityCommonInterface[];
}

export enum ClassType {
  Offline = 'Offline',
  Online = 'Online',
}

export interface ReviewSummary {
  count: number;
  rate: number;
}
export enum CourseStatus {
  unpublished = 'unpublished',
  published = 'published',
}

export interface PresenterCourse {
  id: string;
  status: EntityCommonInterface<CourseStatus>;
  createdAt: string;
  title: string;
  image: string;
  groupFocusValue: EntityCommonInterface;
  weekDays: string[];
  schoolSystems: SchoolSystem[];
  presenter: Presenter;
  branch: Branch;
  description: string;
  objectives: string;
  type: EntityCommonInterface<ClassType>;
  capacity: Capacity;
  fullBookingEnabled: boolean;
  individualBookingEnabled: boolean;
  reviewSummary: ReviewSummary;
}
