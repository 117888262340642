/**
 * @author Salma Hefnawy
 * @date 2021-09-02
 * @description implementation of format date function.
 * @filename format-date.ts
 */

// eslint-disable-next-line prettier/prettier
export function formatDate(
  date: Date
): {
  hours: number;
  minutes: string | number;
  ampm: 'pm' | 'am';
} {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;

  return {
    hours: hours ? hours : 12,
    minutes: minutes < 10 ? `0${minutes}` : `${minutes}`,
    ampm,
  };
}

export function convertStringDateFromUTC(dateString: string): Date {
  if (!dateString) {
    return undefined;
  }
  return new Date(`${dateString} GMT`);
}

export function convertDateToUTC(date: Date | string): string {
  if (typeof date === 'string') {
    return new Date(date).toUTCString();
  }

  return date.toUTCString();
}

export function getDoubleDigits(input: number): string {
  return input < 10 ? `0${input}` : `${input}`;
}
