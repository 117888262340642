import React from 'react';

const Privacy: React.FC = () => {
  return (
    <div className="privacy-container">
      <p>
        {' '}
        We at <b>Orcas</b> are as serious about your privacy as you are, which is why we want to give you full
        disclosure of what we do with your data.
      </p>
      <h2> We collect some personal data.</h2>
      <p>We collect personal data, but so does everyone. </p>
      <p>
        Personal data collection happens so we can get to know you and understand your needs, which, in turn, helps us
        serve you better.
      </p>
      <p>
        We want you to be fully aware, so we’ll give you full information about the personal data we collect and how we
        use it.
      </p>
      <ol>
        <li>Full name</li>
        <span>
          We collect your first and last name to be able to personalize your experience better making it truly yours.
        </span>
        <li> Email</li>
        <span>
          {' '}
          We collect your personal email address to be able to contact you in the future as well as send you fun,
          informative, and useful emails and newsletters.
        </span>
        <li> Phone number</li>{' '}
        <span>
          We take your phone number so our teams can consistently follow up with you and make sure your needs are met
          and our services meet the highest standards we can offer.
        </span>
        <li> Credit cardinformation</li>{' '}
        <span>
          In order for you to make online purchases with Orcas, as in book sessions online, we collect your credit card
          information to process it. Full disclosure, we do not keep your online payment data and it is disregarded as
          soon as the purchase is made.
        </span>
        <li>Location</li>
        <span>
          {' '}
          We collect location information with your consent only to make finding a tutor easier for you by connecting
          you to the nearest tutors available. We do not keep the exact location information, but we are aware of the
          area to be able to make our services even more convenient for you.
        </span>
      </ol>
      <p>
        {' '}
        In other words, we do not have your address nor location, we just know the area you live in so we can make our
        services super accessible for you.
      </p>
      <h3>How do we use this information?</h3>
      <p>
        As stated multiple times, we only use your personal information to have better understanding of your needs, the
        services to offer you, and how to make your experience with Orcas better and more tailored for you.{' '}
      </p>
      <h3>Is your information safe with Orcas? </h3>
      <p>Short answer is: Yes. Definitely.</p>
      <p>
        All your information is secured by your log-in information that only you have access to. Should you ever stop
        using our services, you can easily contact us to have all your data removed.
      </p>
      <h3> How to remove your information from Orcas database</h3>
      <p> Whenever you feel the need to remove your data from our database, the process is quite simple.</p>
      <ol>
        <li>Go to this URL</li>
        <a
          href="https://orcasmobile.com/p-orcas-cores/public/api_v4/user/facebook/deletion"
          target="_blank"
          rel="noreferrer"
        >
          :https://orcasmobile.com/p-orcas-cores/public/api_v4/user/facebook/deletion
        </a>
        <li> Submit a form with your information (preferably use the number or email you used to sign up)</li>
      </ol>{' '}
      <p>
        {' '}
        We will receive your request immediately and work on processing it. Within 24 hours, all your data will be
        removed from the Orcas database.
      </p>
    </div>
  );
};

export default Privacy;
