/* eslint-disable prettier/prettier */
import { Network } from './network';
import { Payload } from '../definitions/interfaces/payload';
import { ENDPOINTS } from './endpoints';
import { CourseDetailsInterface } from '../definitions/interfaces/CourseDetailsInterface';
import { LessonDetailsInterface } from '../definitions/interfaces/LessonDetailsInterface';

export class UserCourses {
  /**
   * fetch user courses
   *
   * @static
   * @returns {Promise<Payload<CourseDetailsInterface>>}
   * @memberof  user courses
   */


  public static getLearnerCourseDetails(courseId: string): Promise<Payload<CourseDetailsInterface|null>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getLearnerCourseDetails.url(courseId)}`, {
      method: ENDPOINTS.getLearnerCourseDetails.method,
    });
  }

  public static getLearnerLessonDetails(courseId: string): Promise<Payload<LessonDetailsInterface|null>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getLearnerLessonDetails.url(courseId)}`, {
      method: ENDPOINTS.getLearnerCourseDetails.method,
    });
  }
}
