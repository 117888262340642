import { Network } from './network';
import { ENDPOINTS } from './endpoints';
import { JobInfo } from './../react-components/pages/tutor/tutor-jobs/index';
import { Payload } from '../definitions/interfaces/payload';
/**
 * implement network related calls.
 */
export class TutorDashboard {
  /**
   *fetch  tutor jobs
   *
   * @static
   * @returns {Promise<Payload<JobInfo>>}
   * @memberof  TutorDashboard
   */
  public static getTutorJobs(): Promise<Payload<JobInfo[]>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT_PARTNER}${ENDPOINTS.getTutorJobs.path}`, {
      method: ENDPOINTS.getTutorJobs.method,
    });
  }

  public static joinClassRoom(jobId: string): Promise<Payload<{ partnerLink: string }>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT_PARTNER}${ENDPOINTS.joinClassRoomTutor.path}`, {
      method: ENDPOINTS.joinClassRoomTutor.method,
      body: JSON.stringify({ jobId }),
    });
  }
}
