import React, { useState } from 'react';
import EntityLogin from './entity-login';
import TeacherLogin from './teacher-login';

const TabsEntityLogin: React.FunctionComponent = () => {
  const [activeTab, setActiveTab] = useState('entityLogin');

  const handleTabTeacher = () => {
    // update the state to tab1
    setActiveTab('teacherLogin');
  };
  const handleTabEntity = () => {
    // update the state to tab2
    setActiveTab('entityLogin');
  };
  return (
    <div className="login-tabs-container">
      <ul className="login-tabs">
        <li className={activeTab === 'entityLogin' ? 'active' : ''} onClick={handleTabEntity}>
          Entity moderator
        </li>
        <li className={activeTab === 'teacherLogin' ? 'active' : ''} onClick={handleTabTeacher}>
          Entity teacher
        </li>
      </ul>
      <div className="outlet">{activeTab === 'entityLogin' ? <EntityLogin /> : <TeacherLogin />}</div>
    </div>
  );
};

export default TabsEntityLogin;
