/**
 * @author Raghda Wessam
 * @date 2021-03-10
 * @description declaration of available routes in the app.
 * @filename routes.ts
 */
import Login from 'src/ts/react-components/pages/login';
import EntityLogin from 'src/ts/react-components/pages/entity-login';
import NotFound from 'src/ts/react-components/pages/not-found';
import UserDashboard from 'src/ts/react-components/pages/user-dashboard';
import CourseDetails from 'src/ts/react-components/pages/course-details';
import EntityCourse from 'src/ts/react-components/pages/entity-course/course-details/index';
import AddClass from 'src/ts/react-components/pages/entity-course/add-class';
import AddCourse from 'src/ts/react-components/pages/entity-course/add-course';
import EntityPagesWrapper from 'src/ts/react-components/pages/entity-pages-wrapper';
import { UserType } from '../interfaces/common';
import Privacy from 'src/ts/react-components/common/privacy';
import TutorLogin from 'src/ts/react-components/pages/tutor/tutor-login';
import TutorJobs from 'src/ts/react-components/pages/tutor/tutor-jobs';
import PresenterCourseDetails from 'src/ts/react-components/pages/presenter-pages/presenter-course-details';
import AddPresenterClass from 'src/ts/react-components/pages/presenter-pages/presenter-course-details/add-presenter-class';
import AddPresenterCourse from 'src/ts/react-components/pages/presenter-pages/presenter-course-details/add-presenter-course';
import PresenterPageContainer from 'src/ts/react-components/pages/presenter-pages/presenter-page-container';

/**
 * map of available routes in the app to their locations in the url.
 */

export const ROUTES = {
  // Add Available Routes with it's Components and other details
  login: {
    path: '/',
    component: Login,
    exact: true,
    public: true,
  },
  userDashboard: {
    path: '/user-dashboard',
    component: UserDashboard,
    exact: true,
    public: false,
    userType: UserType.student,
  },
  courseDetails: {
    getPath: (courseId: string): string => `/${courseId}/course-details`,
    path: '/:courseId/course-details',
    component: CourseDetails,
    exact: true,
    public: false,
    userType: UserType.student,
  },
  notFound: {
    path: '/not-found',
    component: NotFound,
    exact: true,
    public: true,
  },
  privacy: {
    path: '/privacy-policy',
    component: Privacy,
    exact: true,
    public: true,
  },
  entityCourse: {
    getPath: (courseId: string): string => `/entity-course/${courseId}`,
    path: '/entity-course/:courseId',
    component: EntityCourse,
    exact: true,
    public: false,
    userType: UserType.entity,
  },
  entityDashboard: {
    getPath: (mainPage: string): string => `/entity/${mainPage}`,
    path: '/entity/:mainPage',
    component: EntityPagesWrapper,
    exact: true,
    public: false,
    userType: UserType.entity,
  },

  entityLogin: {
    path: '/entity-login',
    component: EntityLogin,
    exact: true,
    public: true,
  },
  addClass: {
    getPath: (courseId: string, lessonId: string): string => `/entity-course/add-class/${courseId}/${lessonId}`,
    path: '/entity-course/add-class/:courseId/:lessonId',
    component: AddClass,
    exact: true,
    public: false,
    userType: UserType.entity,
  },
  editClass: {
    getPath: (courseId: string, lessonId: string, classId: string): string =>
      `/entity-course/edit-class/${courseId}/${lessonId}/${classId}`,
    path: '/entity-course/edit-class/:courseId/:lessonId/:classId',
    component: AddClass,
    exact: true,
    public: false,
    userType: UserType.entity,
  },
  addEntityCourse: {
    path: '/entity-dashboard/add-course',
    component: AddCourse,
    exact: true,
    public: false,
    userType: UserType.entity,
  },
  editEntityCourse: {
    getPath: (courseId: string): string => `/entity-dashboard/edit-course/${courseId}`,
    path: '/entity-dashboard/edit-course/:courseId',
    component: AddCourse,
    exact: true,
    public: false,
    userType: UserType.entity,
  },
  tutorLogin: {
    path: '/tutor-login',
    component: TutorLogin,
    exact: true,
    public: true,
  },
  tutorJobs: {
    path: '/tutor-jobs',
    component: TutorJobs,
    exact: true,
    public: false,
  },
  presenterCourseDetails: {
    getPath: (courseId: string): string => `/presenter-course/${courseId}`,
    path: '/presenter-course/:courseId',
    component: PresenterCourseDetails,
    exact: true,
    public: false,
    userType: UserType.teacher,
  },
  editPresenterClass: {
    getPath: (courseId: string, lessonId: string, classId: string): string =>
      `/presenter-course/edit-class/${courseId}/${lessonId}/${classId}`,
    path: '/presenter-course/edit-class/:courseId/:lessonId/:classId',
    component: AddPresenterClass,
    exact: true,
    public: false,
    userType: UserType.teacher,
  },
  addPresenterClass: {
    getPath: (courseId: string, lessonId: string): string => `/presenter-course/add-class/${courseId}/${lessonId}`,
    path: '/presenter-course/add-class/:courseId/:lessonId',
    component: AddPresenterClass,
    exact: true,
    public: false,
    userType: UserType.teacher,
  },
  addPresenterCourse: {
    path: '/presenter-dashboard/add-course',
    component: AddPresenterCourse,
    exact: true,
    public: false,
    userType: UserType.teacher,
  },
  editPresenterCourse: {
    getPath: (courseId: string): string => `/presenter-dashboard/edit-course/${courseId}`,
    path: '/presenter-dashboard/edit-course/:courseId',
    component: AddPresenterCourse,
    exact: true,
    public: false,
    userType: UserType.teacher,
  },
  presenterDashboard: {
    getPath: (mainPage: string): string => `/presenter/${mainPage}`,
    path: '/presenter/:mainPage',
    component: PresenterPageContainer,
    exact: true,
    public: false,
    userType: UserType.teacher,
  },
};
