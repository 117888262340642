import React from 'react';
import Hamburger from './Hamburger';
// import { NavLink } from 'react-router-dom';

export interface MenuInterface {
  handleClick?: () => void;
  isOpen?: boolean;
}
const HamburgerMenu: React.FunctionComponent<MenuInterface> = (props) => {
  const { handleClick, isOpen } = props;

  return (
    <>
      <Hamburger onClick={handleClick} className={isOpen ? 'hamburger hamburgeractive' : 'hamburger'} />
      <div className={isOpen ? 'navigation navigationactive' : 'navigation'}>{props.children}</div>
    </>
  );
};

export default HamburgerMenu;
