import React, { useState } from 'react';
import { ROUTES } from 'src/ts/definitions/consts/routes';
import * as TABS_INTERFACES from '../../../../definitions/interfaces/tabs';
import { useHistory, useParams } from 'react-router-dom';

const TabItem: React.FunctionComponent<TABS_INTERFACES.TabItemProps> = ({
  title,
  active = false,
  onClick,
}: TABS_INTERFACES.TabItemProps) => {
  return (
    <li onClick={onClick}>
      <a className={active ? 'tabs__item active' : 'tabs__item'}>{title}</a>
    </li>
  );
};

const Tabs: React.FunctionComponent<TABS_INTERFACES.TabsProps> = ({ tabs }: TABS_INTERFACES.TabsProps) => {
  const { mainPage } = useParams<{ mainPage: string }>();
  const [activeTab, setActiveTab] = useState<string>(mainPage);
  const history = useHistory();
  const handleClickTab = (title: string) => {
    setActiveTab(title);
    history.push(ROUTES.presenterDashboard.getPath(title));
  };
  React.useEffect(() => {
    setActiveTab(mainPage);
  }, [mainPage]);
  const content = tabs
    .filter((tab) => tab.title == activeTab)
    .map((tab, id) => <React.Fragment key={id}>{tab.content}</React.Fragment>);
  return (
    <>
      <div className="navbar">
        <ul>
          {tabs.map(({ title }, id) => (
            <TabItem key={id} title={title} active={activeTab === title} onClick={() => handleClickTab(title)} />
          ))}
        </ul>
      </div>
      <div className="tabs_content">{content}</div>
    </>
  );
};

export default Tabs;
