import React from 'react';
import { ROUTES } from 'src/ts/definitions/consts/routes';
import { capitalizeFirstLetter } from 'src/ts/utilities/common';
import { dateCompare } from 'src/ts/utilities/date-compare';

import { useHistory } from 'react-router-dom';
import { PresenterCourse as PresenterCourseUtilities } from '../../../../utilities/presenter-course';
import { ClassType } from 'src/ts/definitions/interfaces/common';
import { formate } from 'src/ts/utilities/moment-formate-Date';

import MarkAsDone from './mark-as-done';
import PresenterAccordionLessons from './presenter-accodion-lessons';
import { Presenter } from 'src/ts/definitions/interfaces/entity-dashboard-classes';
interface LessonsProps {
  lessonsCount: number;
  classesCount: number;
  courseDetailsStatus: { id: number; name: string };
  courseDetailsLessons: any;
  handleOpenModal: (state) => void;
  setShouldRefreshLessonsListing: React.Dispatch<React.SetStateAction<boolean>>;
  courseId: string;
  coursePuplishedStatus: string;
  courseDetailsId: string;
  selectedOption: { label: string; value: number };
  presenter: { image: string; firstName?: string; lastName?: string; title: string };
}
const PresenterCourseLessons: React.FunctionComponent<LessonsProps> = (props) => {
  const {
    courseDetailsId,
    lessonsCount,
    classesCount,
    courseDetailsStatus,
    courseDetailsLessons,
    handleOpenModal,
    setShouldRefreshLessonsListing,
    courseId,
    coursePuplishedStatus,
    selectedOption,
    presenter,
  } = props;
  const history = useHistory();

  const startOnlineClass = (classId: string, lessonId: string, presenterDetails: Presenter): void => {
    PresenterCourseUtilities.startOnlineClass(courseDetailsId, lessonId, classId, presenterDetails).then((joinUrl) => {
      window.open(joinUrl, '_blank');
    });
  };

  return (
    <div className="accordion">
      <div className="accordion__info">
        <div>
          <h2 className="accordion__title">Course schedule & lessons</h2>
          <p className="accordion__description"> {`${lessonsCount} Lessons | ${classesCount} classes`}</p>
        </div>
        <button
          className={`btn accordion__add-lesson-button entity-course__actions-button ${
            courseDetailsStatus.id === 1 && 'btn--disabled'
          }`}
          type="button"
          disabled={courseDetailsStatus.id === 1}
          onClick={() => handleOpenModal('add')}
        >
          <p>add new lesson</p>
        </button>
      </div>
      {courseDetailsLessons?.map(({ name, isCompleted, classes, id }, index) => (
        <PresenterAccordionLessons
          lessonId={id}
          courseId={courseId}
          key={index}
          name={name}
          isComplete={isCompleted}
          setModalState={handleOpenModal}
          setShouldRefreshListing={setShouldRefreshLessonsListing}
          isCoursePublished={courseDetailsStatus.name === coursePuplishedStatus ? true : false}
        >
          {classes && classes.length > 0
            ? classes.map((oneClass, index) => (
                <div key={index} className="accordion-content">
                  <div>
                    <p className="accordion-content__title">{capitalizeFirstLetter(oneClass?.name)}</p>
                    <p className="accordion-content__details">
                      {formate(oneClass.startAt)} | {oneClass.type.name}
                      {oneClass.isRecorded ? ' recorded' : ' not recorded'}
                    </p>
                  </div>
                  <div className="accordion-content__actions">
                    {dateCompare(oneClass.startAt, oneClass.endAt) === 'ended' && !isCompleted ? (
                      <MarkAsDone classId={oneClass.id} />
                    ) : dateCompare(oneClass.startAt, oneClass.endAt) === 'ended' && isCompleted ? (
                      <MarkAsDone classId={oneClass.id} btnState="confirmed" />
                    ) : null}
                    {oneClass.type?.name === ClassType.Online &&
                    dateCompare(oneClass.startAt, oneClass.endAt) === 'ongoing' ? (
                      <button
                        className="accordion-content__start-button btn"
                        type="button"
                        onClick={() => {
                          startOnlineClass(oneClass?.id, id, presenter);
                        }}
                        disabled={selectedOption?.label === 'Not published'}
                      >
                        <p>start classroom</p>
                      </button>
                    ) : null}
                    <button
                      className="accordion-content__edit-button btn"
                      type="button"
                      onClick={() => {
                        history.push({
                          pathname: `${
                            ROUTES.editPresenterClass.getPath &&
                            ROUTES.editPresenterClass.getPath(courseId, id, oneClass.id)
                          }`,
                          state: courseDetailsStatus.name,
                        });
                      }}
                    >
                      <p>edit class</p>
                    </button>
                  </div>
                </div>
              ))
            : null}
          {courseDetailsStatus.id !== 1 && (
            <div key={index} className="accordion-action">
              <button
                className="accordion-action__add-button"
                type="button"
                onClick={() => {
                  history.push(`${ROUTES.addPresenterClass.getPath && ROUTES.addPresenterClass.getPath(courseId, id)}`);
                }}
              >
                Add new class
              </button>
            </div>
          )}
        </PresenterAccordionLessons>
      ))}
    </div>
  );
};
export default PresenterCourseLessons;
