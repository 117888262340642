import React from 'react';
import { ROUTES } from 'src/ts/definitions/consts/routes';
import { capitalizeFirstLetter } from 'src/ts/utilities/common';
import { dateCompare } from 'src/ts/utilities/date-compare';
import EntityAccordionLessons from '../EntityAccordionLessons';
import MarkAsDone from '../mark-as-done';
import { useHistory } from 'react-router-dom';
import { Presenter } from '../../../../../ts/definitions/interfaces/entity-dashboard-classes';
import { EntityCourse as EntityCourseUtilities } from '../../../../utilities/entity-course';
import { ClassType } from 'src/ts/definitions/interfaces/common';
import { formate } from 'src/ts/utilities/moment-formate-Date';
interface LessonsProps {
  lessonsCount: number;
  classesCount: number;
  courseDetailsStatus: { id: number; name: string };
  courseDetailsLessons: any;
  handleOpenModal: (state) => void;
  setShouldRefreshLessonsListing: React.Dispatch<React.SetStateAction<boolean>>;
  courseId: string;
  coursePuplishedStatus: string;
  courseDetailsId: string;
  selectedOption: { label: string; value: number };
  presenter: { image: string; firstName?: string; lastName?: string; title: string };

  markAsDone: (id: string) => void;
}
const EntityCourseLessons: React.FunctionComponent<LessonsProps> = (props) => {
  const {
    courseDetailsId,
    lessonsCount,
    classesCount,
    courseDetailsStatus,
    courseDetailsLessons,
    handleOpenModal,
    setShouldRefreshLessonsListing,
    courseId,
    coursePuplishedStatus,
    selectedOption,
    presenter,
    markAsDone,
  } = props;
  const history = useHistory();

  const startOnlineClass = (classId: string, lessonId: string, presenterDetails: Presenter): void => {
    EntityCourseUtilities.startOnlineClass(courseDetailsId, lessonId, classId, presenterDetails).then((joinUrl) => {
      window.open(joinUrl, '_blank');
    });
  };

  return (
    <div className="accordion">
      <div className="accordion__info">
        <div>
          <h2 className="accordion__title">Course schedule & lessons</h2>
          <p className="accordion__description"> {`${lessonsCount} Lessons | ${classesCount} classes`}</p>
        </div>
        <button
          className={`btn accordion__add-lesson-button entity-course__actions-button ${
            courseDetailsStatus.id === 1 && 'btn--disabled'
          }`}
          type="button"
          disabled={courseDetailsStatus.id === 1}
          onClick={() => handleOpenModal('add')}
        >
          <p>add new lesson</p>
        </button>
      </div>
      {courseDetailsLessons?.map(({ name, isCompleted, classes, id }, index) => (
        <EntityAccordionLessons
          lessonId={id}
          courseId={courseId}
          key={index}
          name={name}
          isComplete={isCompleted}
          setModalState={handleOpenModal}
          setShouldRefreshListing={setShouldRefreshLessonsListing}
          isCoursePublished={courseDetailsStatus.name === coursePuplishedStatus ? true : false}
        >
          {classes && classes.length > 0
            ? classes.map((oneClass, index) => (
                <div key={index} className="accordion-content">
                  <div>
                    <p className="accordion-content__title">{capitalizeFirstLetter(oneClass?.name)}</p>
                    <p className="accordion-content__details">
                      {formate(oneClass.startAt)} | {oneClass.type.name}
                      {oneClass.isRecorded ? ' (recorded) ' : ' (not recorded) '}
                      <>
                        |<span className="currency"> EGP {`${oneClass.price.fullBooking} for full course`} </span>|{' '}
                        <span className="currency">
                          EGP
                          {` ${oneClass.price.individualBooking}`}
                        </span>{' '}
                      </>
                    </p>
                  </div>
                  <div className="accordion-content__actions">
                    {dateCompare(oneClass.startAt, oneClass.endAt) === 'ended' && !oneClass.isCompleted ? (
                      <MarkAsDone classId={oneClass.id} markAsDone={markAsDone} />
                    ) : dateCompare(oneClass.startAt, oneClass.endAt) === 'ended' && oneClass.isCompleted ? (
                      <MarkAsDone classId={oneClass.id} btnState="confirmed" markAsDone={markAsDone} />
                    ) : null}
                    {oneClass.type?.name === ClassType.Online &&
                    dateCompare(oneClass.startAt, oneClass.endAt) === 'ongoing' ? (
                      <button
                        className="accordion-content__start-button btn"
                        type="button"
                        onClick={() => {
                          startOnlineClass(oneClass?.id, id, presenter);
                        }}
                        disabled={selectedOption?.label === 'Not published'}
                      >
                        <p>start classroom</p>
                      </button>
                    ) : null}
                    <button
                      className="accordion-content__edit-button btn"
                      type="button"
                      onClick={() => {
                        history.push({
                          pathname: `${
                            ROUTES.editClass.getPath && ROUTES.editClass.getPath(courseId, id, oneClass.id)
                          }`,
                          state: courseDetailsStatus.name,
                        });
                      }}
                    >
                      <p>edit class</p>
                    </button>
                  </div>
                </div>
              ))
            : null}
          {courseDetailsStatus.id !== 1 && (
            <div key={index} className="accordion-action">
              <button
                className="accordion-action__add-button"
                type="button"
                onClick={() => {
                  history.push(`${ROUTES.addClass.getPath && ROUTES.addClass.getPath(courseId, id)}`);
                }}
              >
                Add new class
              </button>
            </div>
          )}
        </EntityAccordionLessons>
      ))}
    </div>
  );
};
export default EntityCourseLessons;
