import React from 'react';
import entityImg from '../../../../static/images/Entity.jpg';
import TabsEntityLogin from './tabs-entity-login';

const EntityModeratorLogin: React.FunctionComponent = () => {
  return (
    <div className="login login--entity">
      <div className="login__media login__media--entity">
        <img src={entityImg} alt="sample" />
      </div>
      <div className="login__form-container">
        <h1 className="login__form-title">Login as an Entity</h1>
        <p className="login__form-subtitle">Login with the same account you use in your Orcas mobile App</p>
        <TabsEntityLogin />
      </div>
    </div>
  );
};

export default EntityModeratorLogin;
