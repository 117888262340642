import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom';
import { ROUTES } from './ts/definitions/consts/routes';
import Header from './ts/react-components/common/header';
import { Student } from '../src/ts/definitions/interfaces/user';
import { Entity } from '../src/ts/definitions/interfaces/entity';
import { USER_CONTEXT } from './ts/contexts/user-context';
import { getCurrentUser } from './ts/utilities/common';
import { UserType } from './ts/definitions/interfaces/common';
import { Tutor } from './ts/definitions/interfaces/tutor';
import { Teacher } from './ts/definitions/interfaces/teacher-entity';

const App: React.FunctionComponent = () => {
  const [user, setUser] = useState<Student | Entity | Tutor | Teacher>(undefined);
  const [userType, setUserType] = useState<UserType | undefined>(undefined);
  const [loadingUser, setLoadingUser] = useState<boolean>(true);

  useEffect(() => {
    getCurrentUser()
      .then((response) => {
        setUser(response?.user);
        setLoadingUser(false);
        setUserType(response?.type);
      })
      .catch(() => {
        setLoadingUser(false);
      });
  }, []);

  const availableRoutes = Object.keys(ROUTES).reduce((prev: any, cur: string) => {
    //TODO: change type of prev
    const Component = ROUTES[cur].component;
    if (!loadingUser && !user && !ROUTES[cur].public) {
      return prev;
    }

    if (ROUTES[cur].userType === userType) {
      return [
        ...prev,
        <Route
          key={ROUTES[cur].path}
          path={ROUTES[cur].path}
          exact={ROUTES[cur].exact}
          render={(renderProps: RouteComponentProps) => <Component {...renderProps} {...ROUTES[cur].props} />}
        />,
      ];
    }

    return [
      ...prev,
      <Route
        key={ROUTES[cur].path}
        path={ROUTES[cur].path}
        exact={ROUTES[cur].exact}
        render={(renderProps: RouteComponentProps) => <Component {...renderProps} {...ROUTES[cur].props} />}
      />,
    ];
  }, []);

  const updateUser = (user?: Student | Entity | Tutor, type?: UserType) => {
    setUser(user);
    setUserType(type);
  };

  if (loadingUser) {
    return <></>;
  }

  return (
    <USER_CONTEXT.Provider
      value={{
        user,
        updateUser,
        type: userType,
      }}
    >
      <div className="app__container">
        <div className="app__content">
          <BrowserRouter>
            <Header />
            <div className="app__page-content">
              <Switch>
                {availableRoutes}
                <Route key="not-found" render={() => <Redirect to={ROUTES.login.path} />} />
              </Switch>
            </div>
          </BrowserRouter>
        </div>
      </div>
    </USER_CONTEXT.Provider>
  );
};

export default App;
