/**
 * @author Raghda Wessam
 * @date 2021-08-10
 * @description implementation of authentication related API calls.
 * @filename authentication.ts
 */

import { Network } from './network';
import { ENDPOINTS } from './endpoints';
import { LoginInput } from '../definitions/interfaces/inputs/login';
import { Payload } from '../definitions/interfaces/payload';
import { Student } from '../definitions/interfaces/user';
import { UserType } from '../definitions/interfaces/common';

/**
 * implement network related calls.
 */
export class Authentication {
  /**
   * call Login endpoint and set user authentication keys in local storage.
   *
   * @param input login inputs.
   */

  public static login(input: LoginInput): Promise<Payload<Student>> {
    const body = {
      ...input,
    };
    const promise = Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.login.path}`, {
      method: ENDPOINTS.login.method,
      body: JSON.stringify(body),
    });

    return promise.then((payload: Payload<Student>) => {
      if (payload?.data?.token && process.env.REACT_APP_TOKEN_DETAILS) {
        Network.storeTokenDetails(
          payload.data.token.access,
          payload.data.token.refresh,
          Number(payload.data.token.expireAt),
          UserType.student
        );
      }
      return payload;
    });
  }

  /**
   *fetch current logged in user
   *
   * @static
   * @returns {Promise<Payload<Student>>}
   * @memberof Authentication
   */
  public static getUser(): Promise<Payload<Student>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getUser.path}`, {
      method: ENDPOINTS.getUser.method,
    });
  }

  /**
   *logout user
   *
   * @static
   * @returns {Promise<Payload>}
   * @memberof Authentication
   */
  public static logout(): Promise<Payload> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.logout.path}`, {
      method: ENDPOINTS.logout.method,
    });
  }
}
