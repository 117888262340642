/**
 * @author Raghda Wessam
 * @date 2021-08-14
 * @description declaration card component layout.
 * @filename card.tsx
 */
import React from 'react';

export interface ProgressRingProps {
  radius: number;
  /**
   * circle's stroke width
   */
  stroke: number;
  /**
   * percentage of current value from total value
   */
  progress?: number;
  currentValue?: number;
  totalValue?: number;
  /**
   * explains what we are calculating the percentage for, in words
   */
  valueDescription?: string;
}
const ProgressRing: React.FunctionComponent<ProgressRingProps> = (props) => {
  const { radius, stroke, progress, currentValue, totalValue, valueDescription } = props;

  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;

  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div className="progress-bar">
      <svg height={radius * 2} width={radius * 2}>
        <circle
          stroke="#CBCBCB"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + ' ' + circumference}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />

        <svg height={radius * 2} width={radius * 2}>
          <circle
            stroke="#1D81A8"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={circumference + ' ' + circumference}
            style={{ strokeDashoffset }}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
      </svg>
      <div className="progress-bar__desc">
        <p className="progress-bar__booked">{currentValue}</p>
        <p className="progress-bar__paragraph">{valueDescription}</p>
        <p className="progress-bar__total">{`max ${totalValue}`}</p>
      </div>
    </div>
  );
};

export default ProgressRing;
