import React from 'react';
import { Lessons } from 'src/ts/definitions/interfaces/LessonDetailsInterface';
import moment from 'moment';
import { Session } from 'src/ts/utilities/session';
import UserAccordionLessons from './user-accordion-lessons';
interface LessonDetailsProps {
  lesson: Lessons;
}

const LessonsOutline: React.FunctionComponent<LessonDetailsProps> = (props) => {
  const { lesson } = props;
  const formateDate = (date) => {
    const formattedDate = moment(date).format('DD MMM');
    const getTime = moment(new Date(date));

    const formattedTime = getTime.format('h:mma');
    return `${formattedDate}, ${formattedTime}`;
  };
  const joinOnlineCourse = (classId: string) => {
    Session.joinOnlineCourse(classId)
      .then((response) => {
        const joinUrl = response?.onLineSession?.joinUrl;

        if (joinUrl) {
          window.open(joinUrl, '_blank');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <UserAccordionLessons name={lesson?.name} isDone={lesson?.isCompleted}>
      <div className="accordion-lessons__class-content">
        {lesson?.classes?.map((classItem) => (
          <div className="accordion-lessons__class-info" key={classItem?.id}>
            <div className="accordion-lessons__class-info__description">
              {classItem.isCompleted && (
                <p className="accordion-name__done accordion-name__user-done">
                  <span>&#10003;</span> done
                </p>
              )}
              {classItem.isRunning && (
                <p className="accordion-name__user-live-now">
                  <span>&#8226;</span> Live now
                </p>
              )}
              <h3>{classItem.name}</h3>
              <span>
                {formateDate(classItem?.startAt)} | {classItem.type.name} |{' '}
                {classItem.isRecorded ? 'recorded' : 'not recorded'}
              </span>
            </div>
            <button
              className={classItem.canJoinOnline && classItem.id ? 'btn btn--green ' : 'btn btn--disabled '}
              onClick={() => {
                if (classItem.canJoinOnline && classItem.id) {
                  joinOnlineCourse(classItem.id);
                }
              }}
            >
              Join class now
            </button>
          </div>
        ))}
      </div>
    </UserAccordionLessons>
  );
};

export default LessonsOutline;
