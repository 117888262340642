import React from 'react';
import ProgressRing from '../../../common/progress-ring';
import SelectField from '../../../common/select-field';
import { capitalizeFirstLetter } from 'src/ts/utilities/common';
// import moment from 'moment';
import { formate } from 'src/ts/utilities/moment-formate-Date';
import placeHolderImg from 'src/static/images/placeholder.png';
export interface EntityCourseInfoInterface {
  courseImage: string;
  courseTitle: string;
  schooleSystems: any; //array of objects
  groupFocusName: string;
  createdAt: string;
  reviewSummary: { count: number; rate: number }; //
  presenter: { image: string; firstName?: string; lastName?: string; title: string };
  description: string;
  objectives: string;
  capacity: { fullBooking: number; total: number };
  totalValue: number;
  currentValue: number;
  handleUpdatePublishStatus: (publishOption) => void;
  publishOptions: any; // array of object :label and value
  selectedOption: any; // array of object :label and value
  courseStatusName: string;
  isCourseStatusPuplished: string;
  handleEditeCourse: () => void;
}
const EntityCourseInfo: React.FunctionComponent<EntityCourseInfoInterface> = (props) => {
  const {
    courseImage,
    createdAt,
    courseTitle,
    groupFocusName,
    schooleSystems,
    reviewSummary,
    presenter,
    description,
    objectives,
    capacity,
    currentValue,
    totalValue,
    handleUpdatePublishStatus,
    publishOptions,
    selectedOption,
    courseStatusName,
    isCourseStatusPuplished,
    handleEditeCourse,
  } = props;

  const bookingPercentage = Math.round((capacity.fullBooking / capacity.total) * 100);

  const courseDate = `Created ${formate(createdAt)}`;
  return (
    <>
      <div className="entity-course">
        <div className="entity-course__details">
          <div className="entity-course__details-image">
            <img src={`${courseImage}`} alt="" />
          </div>
          <div className="entity-course__details-content">
            <div>
              <p className="entity-course__details-date">{courseDate}</p>
              <h1 className="entity-course__details-name main-title">{`${courseTitle}`}</h1>
              <p className="entity-course__details-subject">{`${groupFocusName} | ${schooleSystems.map((system) => {
                return (
                  ' ' +
                  system.name +
                  ' ' +
                  `(${system.grades.map((grade) => {
                    return grade.name + ' ';
                  })})`
                );
              })}`}</p>
              <p className="entity-course__details-rating">
                <span className="star">&#9733;</span>
                {`${reviewSummary.rate} (${reviewSummary.count} learners)`}
              </p>
            </div>
            <div className="entity-course__details-presenter">
              <img
                className="entity-course__details-presenter-image"
                src={presenter.image || placeHolderImg}
                alt={presenter.firstName}
              />
              <p className="entity-course__details-presenter-name">
                {`by ${capitalizeFirstLetter(presenter.title)} ${capitalizeFirstLetter(
                  presenter.firstName
                )} ${presenter.lastName.toLocaleLowerCase()}`}
              </p>
            </div>
          </div>
        </div>
        <div className="entity-course__actions">
          <SelectField
            title="publishSelector"
            showTitle={false}
            onChange={(option) => {
              handleUpdatePublishStatus(option);
            }}
            optionValues={publishOptions}
            selectedOption={selectedOption}
            isSearchable={false}
          />

          <button
            className={`btn entity-course__actions-button ${
              courseStatusName === isCourseStatusPuplished ? 'btn--disabled' : ''
            }`}
            type="button"
            disabled={courseStatusName === isCourseStatusPuplished}
            onClick={handleEditeCourse}
          >
            edit course
          </button>
        </div>
      </div>
      <div className="entity-course__container">
        <div className="entity-course__description-container">
          <div className="entity-course__description">
            <h2 className="entity-course__description-title">course description</h2>
            <p className="entity-course__description-paragraph">{capitalizeFirstLetter(description)}</p>
          </div>
          <div className="entity-course__description">
            <h2 className="entity-course__description-title">Course objectives</h2>
            <p className="entity-course__description-paragraph">{capitalizeFirstLetter(objectives)}</p>
          </div>
        </div>
        <ProgressRing
          radius={105}
          stroke={4}
          progress={bookingPercentage}
          currentValue={currentValue}
          totalValue={totalValue}
          valueDescription="students booked"
        />
      </div>
    </>
  );
};
export default EntityCourseInfo;
