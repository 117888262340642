import React, { useState } from 'react';
import ExpandIcon from '../../../../static/images/icons/lesson_expand_arrow.svg';
import CollapseIcon from '../../../../static/images/icons/lesson_collapse_arrow.svg';

export interface UserAccordionLessonsProps {
  name: string;
  isDone: boolean;
  children: React.ReactNode;
}
const UserAccordionLessons: React.FunctionComponent<UserAccordionLessonsProps> = (props) => {
  const { name, isDone, children } = props;

  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className="accordion-name" onClick={() => setIsActive(!isActive)}>
        <div className="accordion-name__content">
          <p>{name}</p>
          {isDone && (
            <p className="accordion-name__done accordion-name__user-done">
              done <span>&#10003;</span>
            </p>
          )}
        </div>
        <div className="accordion-edit-icon">
          <div style={{ cursor: 'pointer' }}>
            {isActive ? <img src={CollapseIcon} alt="expand" /> : <img src={ExpandIcon} alt="expand" />}
          </div>
        </div>
      </div>
      {isActive && children}
    </div>
  );
};

export default UserAccordionLessons;
