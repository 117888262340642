/**
 * @author Raghda Wessam
 * @date 2021-03-10
 * @description implementation of network calls.
 * @filename network.ts
 */
import { ERRORS } from '../definitions/consts/errors';
import { TokenPayload } from '../definitions/interfaces/user';
import { exist as _exist } from '../utilities/common';
import { ENDPOINTS } from './endpoints';
import { add } from 'date-fns';
import { UserType } from '../definitions/interfaces/common';

/**
 * class responsible for executing network requests.
 *
 * @export
 * @class network
 */
export class Network {
  public static fetch(url: string, init: RequestInit, addAuth = true, skipUpdateAuth = false): Promise<any> {
    let promise: Promise<unknown> = Promise.resolve(null);

    if (addAuth && !skipUpdateAuth) {
      promise = promise
        .then(() => {
          return Network.updateAuthenticationTokens();
        })
        .catch(() => {
          return Promise.resolve();
        });
    }

    return promise.then(() => {
      return fetch(url, {
        mode: 'cors',
        ...init,
        headers: Network.getHeaders(init.headers, addAuth),
      })
        .catch(() => {
          return Promise.reject(ERRORS.unexpected);
        })
        .then((response: Response) => {
          return Network.handleResponseBasedOnStatus(response);
        });
    });
  }

  public static storeTokenDetails(accessToken: string, refreshToken: string, expireAt: number, type: UserType): void {
    const tokenDetails = {
      accessToken,
      refreshToken,
      expireAt: add(new Date(), { seconds: expireAt }).toString(),
      type,
    };
    localStorage.setItem(process.env.REACT_APP_TOKEN_DETAILS, JSON.stringify(tokenDetails));
  }

  public static removeTokenDetails(): void {
    localStorage.removeItem(process.env.REACT_APP_TOKEN_DETAILS);
  }

  public static refreshToken(): Promise<unknown> {
    let promise: Promise<unknown>;

    if (process.env.REACT_APP_TOKEN_DETAILS) {
      const token = JSON.parse(localStorage.getItem(process.env.REACT_APP_TOKEN_DETAILS));
      const refreshToken = token?.refreshToken;
      const tokenType = token?.type;

      if (tokenType === UserType.student) {
        promise = Network.fetch(
          `${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.refreshToken.path}`,
          {
            method: ENDPOINTS.refreshToken.method,
            body: JSON.stringify({ refreshToken }),
          },
          true,
          true
        );
      } else if (tokenType === UserType.entity) {
        promise = Network.fetch(
          `${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.refreshEntityToken.path}`,
          {
            method: ENDPOINTS.refreshEntityToken.method,
            body: JSON.stringify({ refreshToken }),
          },
          true,
          true
        );
      } else if (tokenType === UserType.tutor) {
        promise = Network.fetch(
          `${process.env.REACT_APP_API_ENDPOINT_PARTNER}${ENDPOINTS.refreshTutorToken.path}`,
          {
            method: ENDPOINTS.refreshTutorToken.method,
            body: JSON.stringify({ refreshToken }),
          },
          true,
          true
        );
      }
      promise = promise.then((payload: TokenPayload) => {
        if (_exist(payload, ['accessToken', 'refreshToken', 'expireAt', 'type'])) {
          Network.storeTokenDetails(payload.access, payload.refresh, Number(payload.expireAt), tokenType);
        } else {
          return Promise.reject();
        }
        return Promise.resolve();
      });
    } else {
      promise = Promise.reject();
    }

    return promise;
  }

  public static updateAuthenticationTokens(): Promise<unknown> {
    const token = JSON.parse(localStorage.getItem(process.env.REACT_APP_TOKEN_DETAILS));
    const currentExpiresAt = token?.expireAt;

    if (!_exist(currentExpiresAt)) {
      return Promise.resolve();
    }
    const currentTokenExpirationDate = new Date(currentExpiresAt);

    if (currentTokenExpirationDate < new Date()) {
      return Network.refreshToken();
    }

    return Promise.resolve();
  }

  /**
   * get headers that should be added to the request.
   *
   * @static
   * @param {HeadersInit} [originalHeaders] optional headers to be
   * added/overwrite the default headers.
   * @returns {HeadersInit} headers object that needs to be added to the request.
   * @memberof Network
   */
  public static getHeaders(originalHeaders?: HeadersInit, addAuth = true): HeadersInit {
    let headers: HeadersInit = {
      'content-type': 'application/json',
      'Accept-Language': 'en',
      platform: 'orcas-website',
      version: '1.0',
      country: 'eg',
    };

    const token = JSON.parse(localStorage.getItem(process.env.REACT_APP_TOKEN_DETAILS));
    const accessToken = token?.accessToken;
    if (addAuth && accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    headers = {
      ...headers,
      ...originalHeaders,
    };

    return headers;
  }

  /**
   * handle various types of errors from network request based on response status.
   *
   * @static
   * @param {Response} response response from network request
   * @returns {Promise<any>} promise to return an error with a specific message
   * @memberof Network
   */
  private static handleResponseBasedOnStatus(response: Response): Promise<unknown> {
    let promise: Promise<unknown>;

    switch (response.status) {
      case 200:
      case 201:
        promise = response.json();
        break;
      case 400:
      case 422:
        promise = response.json().then((data) => {
          return Promise.reject(data && (data.errors || data.message));
        });
        break;
      case 401:
        // refresh token if unauthorized
        promise = response.json().then(() => {
          // this.removeTokenDetails();
          // window.location.assign('/login');
          return Promise.reject();
        });
        break;
      case 403:
        promise = response.json().then((data) => {
          return Promise.reject(data?.message);
        });
        break;
      default:
        promise = response.json().then((data) => {
          return Promise.reject(data?.message);
        });
    }

    return promise.catch((error) => {
      return Promise.reject(error ?? ERRORS.unexpected);
    });
  }
}
