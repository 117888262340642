import React, { useState } from 'react';
import ExpandIcon from '../../../../../static/images/icons/lesson_expand_arrow.svg';
import CollapseIcon from '../../../../../static/images/icons/lesson_collapse_arrow.svg';
import EditIcon from '../../../../../static/images/icons/edit_teacher.svg';
import DeleteIcon from '../../../../../static/images/icons/delete_icon.svg';
import { PresenterCourse } from 'src/ts/api/presenter-course';
import Toastr from '../../../common/toastr';

export interface EntityAccordionLessonsProps {
  name: string;
  isComplete?: boolean;
  children: React.ReactNode;
  setModalState?: any;
  lessonId?: string;
  courseId?: string;
  setShouldRefreshListing?: React.Dispatch<React.SetStateAction<boolean>>;
  isCoursePublished: boolean;
}
const PresenterAccordionLessons: React.FunctionComponent<EntityAccordionLessonsProps> = (props) => {
  // eslint-disable-next-line prettier/prettier
  const {
    name,
    isComplete,
    children,
    setModalState,
    lessonId,
    courseId,
    setShouldRefreshListing,
    isCoursePublished,
  } = props;

  const [isActive, setIsActive] = useState(false);

  const deleteEntityLesson = (courseId, lessonId) => {
    PresenterCourse.deletePresenterLesson(courseId, lessonId)
      .then(() => {
        Toastr.success('Lesson deleted successfully');
        setShouldRefreshListing(true);
      })
      .catch((error) => {
        Toastr.error(error);
        console.error(error);
      })
      .finally(() => {
        setShouldRefreshListing(false);
      });
  };

  return (
    <div className="accordion-item">
      <div className="accordion-name">
        <div className="accordion-name__content">
          <p>{name}</p>
          {isComplete && (
            <p className="accordion-name__done">
              marked as done <span>&#10003;</span>
            </p>
          )}
        </div>
        <div className="accordion-edit-icon">
          <>
            <button
              // className="
              className={`btn  ${isCoursePublished ? 'btn--disabled' : 'delete-button'}`}
              disabled={isCoursePublished}
              onClick={() => deleteEntityLesson(courseId, lessonId)}
            >
              <img src={DeleteIcon} alt="" />
              <p> Delete lesson</p>
            </button>
            <button
              // className="edit-button"
              className={`btn  ${isCoursePublished ? 'btn--disabled' : 'edit-button'}`}
              disabled={isCoursePublished}
              onClick={() => setModalState('edit', lessonId, name)}
            >
              <img src={EditIcon} alt="edit_icon" />
              <p> Edit lesson</p>
            </button>
          </>
          <div style={{ cursor: 'pointer' }} onClick={() => setIsActive(!isActive)}>
            {isActive ? <img src={CollapseIcon} alt="expand" /> : <img src={ExpandIcon} alt="expand" />}
          </div>
        </div>
      </div>
      {isActive && children}
    </div>
  );
};

export default PresenterAccordionLessons;
