import { TutorDashboard as TutorDashboardAPI } from '../api/tutor-dashboard';
import { getPayloadData } from './common';

export class TutorDashboardUtilities {
  public static joinClassRoom(jobId: string): Promise<string> {
    return TutorDashboardAPI.joinClassRoom(jobId).then((payload) => {
      return getPayloadData(payload).then((data) => data?.partnerLink);
    });
  }
}
