/**
 * @author Raghda Wessam
 * @date 2021-08-15
 * @description implementation of Session related utilities.
 * @filename session.ts
 */

import { Session as SessionAPI } from '../api/session';
import { UnconfirmedCourses as UnconfirmedCoursesInterface } from '../definitions/interfaces/unconfirmed-courses';
import { UpdateAttendanceInput } from '../definitions/interfaces/inputs/update-attendance-input';
import { AddReviewInput } from '../definitions/interfaces/inputs/add-review-input';
import { Course, Course as CourseInterface } from '../definitions/interfaces/course';
import { Session as SessionInterface } from '../definitions/interfaces/session';
import { getPayloadData } from './common';
import { CourseBody } from '../definitions/interfaces/entity-courses-listing';
import { SearchBody } from '../definitions/interfaces/search-body';

/**
 *a class that contains session related utilities.
 *
 * @export
 * @class Session
 */
export class Session {
  /**
   * get live now sessions
   *
   * @static
   * @returns {Promise<Payload>}
   * @memberof Session
   */
  public static getLiveNowSessions(): Promise<{
    sessions: SessionInterface[];
    courses: CourseInterface[];
  }> {
    return SessionAPI.getLiveNowSessions()
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   * get unconfirmed courses
   *
   * @static
   * @returns {Promise<Payload<UnconfirmedCoursesInterface>>}
   * @memberof Session
   */
  public static getUnconfirmedCourses(): Promise<UnconfirmedCoursesInterface> {
    return SessionAPI.getUnconfirmedCourses()
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   * get get allCourses courses
   *
   * @static
   * @returns {Promise<Payload<CourseBody>>}
   * @memberof Session
   */
  public static getAllCourses(offset: number, limit: number, searchBody?: SearchBody): Promise<CourseBody[]> {
    return SessionAPI.getAllCourses(offset, limit, searchBody)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   *update attendance
   *
   * @static
   * @param {UpdateAttendanceInput} input
   * @returns {Promise<Payload>}
   * @memberof Sessions
   */
  public static updateAttendance(input: UpdateAttendanceInput): Promise<unknown> {
    return SessionAPI.updateAttendance(input)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   * add class review
   *
   * @static
   * @param {AddReviewInput} input
   * @returns {Promise<Payload>}
   * @memberof Sessions
   */
  public static addClassReview(input: AddReviewInput): Promise<unknown> {
    return SessionAPI.addClassReview(input)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   *join online session
   *
   * @static
   * @param {string} sessionId
   * @returns {Promise<Session>}
   * @memberof Session
   */

  public static joinOnlineSession(sessionId: string): Promise<SessionInterface> {
    return SessionAPI.joinOnlineSession(sessionId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   *join online course
   *
   * @static
   * @param {string} classId
   * @returns {Promise<Course>}
   * @memberof Session
   */
  public static joinOnlineCourse(classId: string): Promise<Course> {
    return SessionAPI.joinOnlineCourse(classId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   *leave online class / session
   *
   * @static
   * @param {string} sessionId
   * @returns {Promise<unknown>}
   * @memberof Session
   */
  public static leaveClassroom(sessionId: string): Promise<unknown> {
    return SessionAPI.leaveClassroom(sessionId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
