import React, { useEffect, useState } from 'react';
import Tabs from './presenter-tabs';
import Loader from '../../../common/loader';
import PresenterCourses from '../../presenter-pages/presenter-courses';
import PresenterDashboard from '../presenter-dashboard';

interface tabItems {
  id: number;
  title: string;
  content: any;
}
const PresenterPageContainer: React.FunctionComponent = () => {
  const [tabItems, setTabItems] = useState<tabItems[] | null>(null);

  useEffect(() => {
    setTabItems([
      {
        id: 1,
        title: 'Dashboard',
        content: <PresenterDashboard />,
      },
      {
        id: 2,
        title: 'Courses',
        content: <PresenterCourses />,
      },
    ]);
  }, []);

  if (tabItems?.length)
    return (
      <div className="entity-tabs-wrapper">
        <Tabs tabs={tabItems} />
      </div>
    );
  else return <Loader />;
};

export default PresenterPageContainer;
