import React, { useEffect, useState } from 'react';
import clock from '../../../../../static/images/icons/time.svg';
import { TutorDashboard } from 'src/ts/api/tutor-dashboard';
import { TutorDashboardUtilities } from 'src/ts/utilities/tutor-dashboard';
import { formate } from 'src/ts/utilities/moment-formate-Date';

interface Job {
  id: string;
  createdAt: string;
  completedMinutes: number;
  paidMinutes: number;
  xw;
  totalMinutes: number;
  status: { id: string; name: string };
}
interface Request {
  id: string;
  focusValue: string;
  price: { amount: number; currency: string };
  referenceTag: string;
  childrenCount: number;
}

export interface JobInfo {
  job: Job;
  parent: { firstName: string; id: string; image: string };
  request: Request;
  service: { id: string; name: string };
}

const TutorJobs: React.FunctionComponent<JobInfo[]> = () => {
  // const userContext = useContext(USER_CONTEXT);
  const [jobInfo, setJobInfo] = useState<JobInfo[] | null>(null);
  const [isDisableBts, setIsDisableBtns] = useState<boolean>(false);

  useEffect(() => {
    TutorDashboard.getTutorJobs()
      .then((response) => {
        setJobInfo(response?.data);
      })
      .catch((err) => console.error(err));
  }, []);

  const startOnlineClass = (jobId: string): void => {
    setIsDisableBtns(true);
    TutorDashboardUtilities.joinClassRoom(jobId).then((joinUrl) => {
      window.open(joinUrl, '_blank');
      setIsDisableBtns(false);
    });
  };

  if (!jobInfo) return <div>loading ...</div>;
  return (
    <section className="tutor-dashboard">
      <h2 className="section-title">My Packages</h2>
      <div className="tutor-jobs">
        {jobInfo?.map((jobItem) => {
          return (
            <div className="tutor-jobs__card" key={jobItem?.job.id}>
              <div className="card-content">
                <span className="reference-tag">{jobItem?.request.referenceTag}</span>
                <span className="start-date">{`Started ${formate(jobItem?.job.createdAt)}`}</span>
                <h4>{jobItem?.request?.focusValue} tutoring sessions</h4>
                <div className="tutor-desc">
                  <div className="tutor-info">
                    <span>
                      With <b>{jobItem?.parent.firstName}</b> | For{' '}
                      <b>
                        {jobItem?.request.childrenCount > 1
                          ? jobItem?.request.childrenCount + ' ' + 'children'
                          : jobItem?.request.childrenCount + ' ' + 'child'}
                      </b>
                    </span>
                    <div className="job-hours">
                      <img src={clock} alt="clock" className="clock" />
                      <span>
                        {jobItem?.job.completedMinutes / 60}/{jobItem?.job.totalMinutes / 60} hours consumed
                      </span>
                    </div>
                  </div>
                  {/* <img src={jobItem?.parent?.image || ''} alt="tutor img" className="parent-img" /> */}
                </div>
                <button
                  className="btn btn--green"
                  onClick={() => {
                    startOnlineClass(jobItem?.job?.id);
                  }}
                  disabled={isDisableBts}
                >
                  Start an online classroom
                </button>
                <span className="hint">* Check your Orcas mobile app to make sure that this package is paid for</span>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default TutorJobs;
