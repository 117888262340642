/**
 * @author Raghda Wessam
 * @date 2021-03-21
 * @description implementation of Orders related API calls.
 * @filename order.ts
 */
import { Network } from './network';
import { ENDPOINTS } from './endpoints';
import { BranchLookup, EntityCourse as EntityCourseInterface } from '../definitions/interfaces/entity-course';
import { EntityLessons as EntityLessonsInterface } from '../definitions/interfaces/entity-lesson';
import { Subject as SubjectInterface } from '../definitions/interfaces/subject';
import { SchoolSystem as SchoolSystemInterface } from '../definitions/interfaces/entity-course';
import { Presenter as PresenterInterface } from '../definitions/interfaces/entity-course';
import { Payload } from '../definitions/interfaces/payload';
import { AddClassFormInput } from '../definitions/interfaces/inputs/add-class-input';
import { AddLessonInput } from '../definitions/interfaces/inputs/add-lesson-input';
import { AddCourseInput } from '../definitions/interfaces/inputs/add-course-input';
import { EntityDashboardClasses, Presenter } from '../definitions/interfaces/entity-dashboard-classes';
import { EntityClass as EntityClassInterface } from '../definitions/interfaces/entity-class';
import { exist } from '../utilities/common';

/**
 * implement network related calls.
 */
export class EntityCourse {
  /**
   *fetch  entity course details
   *
   * @static
   * @param {string} courseId
   * @returns {Promise<Payload<EntityCourseInterface>>}
   * @memberof  EntityCourse
   */
  public static getEntityCourseDetails(courseId: string): Promise<Payload<EntityCourseInterface>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getEntityCourseDetails.url(courseId)}`, {
      method: ENDPOINTS.getEntityCourseDetails.method,
    });
  }

  /**
   *fetch  entity course lessons
   *
   * @static
   * @param {string} courseId
   * @returns {Promise<Payload<EntityLessonInterface[]>>}
   * @memberof  EntityCourse
   */
  public static getEntityCourseLessons(courseId: string): Promise<Payload<EntityLessonsInterface>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getEntityCourseLessons.url(courseId)}`, {
      method: ENDPOINTS.getEntityCourseLessons.method,
    });
  }

  /**
   * Create a new course in the data source and lint it with the Entity.
   */
  public static addEntityCourse(input: AddCourseInput): Promise<Payload<EntityCourseInterface>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.addEntityCourse.url}`, {
      method: ENDPOINTS.addEntityCourse.method,
      body: JSON.stringify(input),
    });
  }

  /**
   * edit entity course
   *
   * @static
   * @param {AddCourseInput} input
   * @returns {Promise<Payload<EntityCourseInterface>>}
   * @memberof EntityCourse
   */
  public static editEntityCourse(courseId: string, input: AddCourseInput): Promise<Payload<EntityCourseInterface>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.editEntityCourse.url(courseId)}`, {
      method: ENDPOINTS.editEntityClass.method,
      body: JSON.stringify(input),
    });
  }
  /**
   * add entity class
   *
   * @static
   * @param {string} input
   * @returns {Promise<Payload>}
   * @memberof EntityCourse
   */
  public static addEntityClass(courseId: string, lessonId: string, input: AddClassFormInput): Promise<Payload> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.addEntityClass.url(courseId, lessonId)}`, {
      method: ENDPOINTS.addEntityClass.method,
      body: JSON.stringify(input),
    });
  }

  /**
   * edit entity class
   *
   * @static
   * @param {string} input
   * @returns {Promise<Payload>}
   * @memberof EntityCourse
   */
  public static editEntityClass(
    courseId: string,
    lessonId: string,
    classId: string,
    input: AddClassFormInput
  ): Promise<Payload> {
    return Network.fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.editEntityClass.url(courseId, lessonId, classId)}`,
      {
        method: ENDPOINTS.editEntityClass.method,
        body: JSON.stringify(input),
      }
    );
  }
  /**
   * update entity course publish status
   *
   * @static
   * @param {number} publishStatusId
   * @returns {Promise<Payload>}
   * @memberof EntityCourse
   */
  public static updatePublishCourseStatus(publishStatusId: number, courseId: string): Promise<Payload> {
    const url = `${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.updatePublishCourseStatus.url(courseId)}`;
    return Network.fetch(url, {
      method: ENDPOINTS.updatePublishCourseStatus.method,
      body: JSON.stringify({ publishStatusId }),
    });
  }
  /**
   * add entity lesson
   *
   * @static
   * @param {string} input
   * @returns {Promise<Payload>}
   * @memberof EntityCourse
   */
  public static addEntityLesson(courseId: string, input: AddLessonInput): Promise<Payload> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.addEntityLesson.url(courseId)}`, {
      method: ENDPOINTS.addEntityLesson.method,
      body: JSON.stringify(input),
    });
  }
  /**
   * edit entity lesson
   *
   * @static
   * @param {string} input
   * @returns {Promise<Payload>}
   * @memberof EntityCourse
   */
  public static editEntityLesson(courseId: string, lessonId: string, newLessonName: AddLessonInput): Promise<Payload> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.editEntityLesson.url(courseId, lessonId)}`, {
      method: ENDPOINTS.editEntityLesson.method,
      body: JSON.stringify(newLessonName),
    });
  }
  /**
   * delete entity lesson
   *
   * @static
   * @param {string} input
   * @memberof EntityCourse
   */
  public static deleteEntityLesson(courseId: string, lessonId: string): Promise<Payload> {
    return Network.fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.deleteEntityLesson.url(courseId, lessonId)}`,
      {
        method: ENDPOINTS.deleteEntityLesson.method,
      }
    );
  }

  /**
   *Get all course subjects
   *
   * @static
   * @returns {Promise<Payload<SubjectInterface[]>>}
   * @memberof EntityCourse
   */
  public static getAllSubjects(): Promise<Payload<SubjectInterface[]>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getAllSubjects.path}`, {
      method: ENDPOINTS.getAllSubjects.method,
    });
  }
  /**
   * get Unconfirmed Classes
   *
   * @static
   * @param {number} offset
   * @param {number}  limit
   * @returns {Promise<Payload>}
   * @memberof EntityCourse
   */
  public static getUnconfirmedClasses(offset: number, limit: number): Promise<Payload<EntityDashboardClasses[]>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getUnconfirmedClasses.url(offset, limit)}`, {
      method: ENDPOINTS.getUnconfirmedClasses.method,
    });
  }

  /**
   *Get all course school systems
   *
   * @static
   * @returns {Promise<Payload<SchoolSystemInterface[]>>}
   * @memberof EntityCourse
   */
  public static getAllSchoolSystems(): Promise<Payload<SchoolSystemInterface[]>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getAllSchoolSystems.path}`, {
      method: ENDPOINTS.getAllSchoolSystems.method,
    });
  }

  /**
   * Get all course presenters
   *
   * @static
   * @returns {Promise<Payload<PresenterInterface[]>>}
   * @memberof EntityCourse
   */
  public static getAllPresenters(): Promise<Payload<PresenterInterface[]>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getAllPresenters.path}`, {
      method: ENDPOINTS.getAllPresenters.method,
    });
  }

  /**
   * get Unconfirmed Classes
   *
   * @static
   * @param {number} offset
   * @param {number}  limit
   * @returns {Promise<Payload>}
   * @memberof EntityCourse
   */
  public static getUpcommingClasses(offset: number, limit: number): Promise<Payload<EntityDashboardClasses[]>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getUpcommingClasses.url(offset, limit)}`, {
      method: ENDPOINTS.getUpcommingClasses.method,
    });
  }
  /**
   * confirm Class
   *
   * @static
   * @param {string} classId
   * @returns {Promise<Payload>}
   * @memberof EntityCourse
   */
  public static confirmClass(classId: string): Promise<Payload> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.confirmClass.path}`, {
      method: ENDPOINTS.confirmClass.method,
      body: JSON.stringify({ classId: classId }),
    });
  }

  /**
   * Get all branches
   *
   * @static
   * @returns {Promise<Payload<BranchLookup>>}
   * @memberof EntityCourse
   */
  public static getAllBranches(): Promise<Payload<BranchLookup[]>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getAllBranches.path}`, {
      method: ENDPOINTS.getAllBranches.method,
    });
  }

  /**
   *fetch  entity class details
   *
   * @static
   * @param {string} courseId
   * @returns {Promise<Payload<EntityClassInterface>>}
   * @memberof  EntityCourse
   */
  public static getEntityClass(
    courseId: string,
    lessonId: string,
    classId: string
  ): Promise<Payload<EntityClassInterface>> {
    return Network.fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getEntityClass.url(courseId, lessonId, classId)}`,
      {
        method: ENDPOINTS.getEntityClass.method,
      }
    );
  }

  public static startOnlineClass(
    courseId: string,
    lessonId: string,
    classId: string,
    presenter: Presenter
  ): Promise<Payload<{ presenterJoinUrl: string }>> {
    const url = `${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.startOnlineClass.url(courseId, lessonId, classId)}`;
    const input: any = presenter;
    if (exist(input) && !exist(input, ['presenterId'])) {
      input.presenterId = input?.id;
    }
    return Network.fetch(url, {
      method: ENDPOINTS.startOnlineClass.method,
      body: JSON.stringify(input),
    });
  }
}
