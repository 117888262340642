/**
 * @author Raghda Wessam
 * @date 2021-08-11
 * @description Implementation of user context
 * @filename user-context.ts
 */
import * as React from 'react';
import { Student } from '../definitions/interfaces/user';
import { Entity } from '../definitions/interfaces/entity';
import { Tutor } from '../definitions/interfaces/tutor';
import { Teacher } from '../definitions/interfaces/teacher-entity';
import { UserType } from '../definitions/interfaces/common';

/**
 * user context that holds user data.
 */
export const USER_CONTEXT: React.Context<{
  /**
   * created user from BE.
   */
  user?: Student | Entity | Tutor | Teacher;
  // user?: Student | moderator | teacher | Tutor;

  type?: UserType;

  /**
   * setting user data
   */
  updateUser: (user?: Student | Entity | Tutor | Teacher, type?: UserType) => void;
}> = React.createContext({
  updateUser: null,
});
