import React, { useContext, useEffect, useState } from 'react';
import Tabs from './institute-tab-item';
import Courses from './courses';
import Teachers from './teachers';
import Profile from './profile/indes';
import EntityDashboard from '../entity-dashboard';
import { USER_CONTEXT } from 'src/ts/contexts/user-context';
import { UserType } from '../../../definitions/interfaces/common';
import Loader from '../../common/loader';
import PresenterCourses from '../presenter-pages/presenter-courses';

interface tabItems {
  id: number;
  title: string;
  content: any;
}
const EntityPagesWrapper: React.FunctionComponent = () => {
  const userContext = useContext(USER_CONTEXT);
  const [tabItems, setTabItems] = useState<tabItems[] | null>(null);

  useEffect(() => {
    if (userContext.user && userContext.type === UserType.entity) {
      setTabItems([
        {
          id: 1,
          title: 'Dashboard',
          content: <EntityDashboard />,
        },
        {
          id: 2,
          title: 'Courses',
          content: <Courses />,
        },
        {
          id: 3,
          title: 'Teachers',
          content: <Teachers />,
        },
        {
          id: 4,
          title: 'Profile',
          content: <Profile />,
        },
      ]);
    } else if (userContext.user && userContext.type === UserType.teacher) {
      setTabItems([
        {
          id: 1,
          title: 'Dashboard',
          content: <EntityDashboard />,
        },
        {
          id: 2,
          title: 'Courses',
          content: <PresenterCourses />,
        },
      ]);
    }
  }, [userContext]);

  if (tabItems?.length)
    return (
      <div className="entity-tabs-wrapper">
        <Tabs tabs={tabItems} />
      </div>
    );
  else return <Loader />;
};

export default EntityPagesWrapper;
