/**
 * @author Salma Hefnawy
 * @date 2021-08-25
 * @description Select field layout.
 * @filename select-field.tsx
 */
import React from 'react';
import Select from 'react-select';

/**
 *props for generic select field
 *
 * @interface SelectFieldProps
 */
interface SelectFieldProps {
  /**
   *label for select field
   *
   * @type {string}
   * @memberof SelectFieldProps
   */
  title: string;

  /**
   *boolean to show label/title for select field
   *
   * @type {boolean}
   * @memberof SelectFieldProps
   */
  showTitle: boolean;

  /**
   * array of options passed to select field
   *
   * @type {{
   *     value: number | string;
   *     label: string;
   *   }[]}
   * @memberof SelectFieldProps
   */
  optionValues?:
    | {
        value: number | string;
        label: string;
      }[]
    | {
        label: string;
        options: {
          value: number | string;
          label?: string;
        }[];
      }[];

  /**
   *handle change of select field value
   *
   * @memberof SelectFieldProps
   */
  onChange?: (value: any) => void;

  defaultValue?: {
    value: number | string;
    label: string;
  };

  selectedOption?:
    | {
        value: number | string;
        label?: string;
      }
    | {
        value: number | string;
        label?: string;
      }[]
    | null;

  isSearchable: boolean;
  isMulti?: boolean;
  placeholder?: string;
  className?: string;
}

const SelectField: React.FC<SelectFieldProps> = (props) => {
  const { title, optionValues, onChange, selectedOption, isSearchable, isMulti, placeholder, className } = props;
  return (
    <div className="select">
      {props.showTitle && <label className="select__title">{props.title}</label>}

      <Select
        className="select__wrapper"
        classNamePrefix={className ? className : 'react-select'}
        isClearable={false}
        isSearchable={isSearchable}
        name={title}
        options={optionValues}
        onChange={onChange}
        value={selectedOption}
        placeholder={placeholder ? placeholder : 'select'}
        isMulti={isMulti}
      />
    </div>
  );
};

export default SelectField;
