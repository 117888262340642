/**
 * @author Raghda Wessam
 * @date 2021-08-14
 * @description Implementation of Common utilities.
 * @filename common.ts
 */
import { Entity } from '../definitions/interfaces/entity';
import { Payload } from '../definitions/interfaces/payload';
import { Student } from '../definitions/interfaces/user';
import { Tutor } from './../definitions/interfaces/tutor';
import { Authentication as AuthenticationUtilities } from '../../ts/utilities/authentication';
import { EntityAuthentication as EntityAuthenticationUtilities } from '../../ts/utilities/entity-authentication';
import { TeacherAuthentication as TeacherAuthenticationUtilities } from '../../ts/utilities/teacher-authentication';
import { TutorAuthentication as TutorAuthenticationUtilities } from '../../ts/utilities/tutor-authentication';
import { UserType } from '../definitions/interfaces/common';
import { Teacher } from '../definitions/interfaces/teacher-entity';

/**
 * check if this value not null and not undefined.
 *
 * @param {*} value an object to check if it exists or not.
 * @param {Array<string>} [keys=undefined] List of keys to look for inside the object.
 * @returns {boolean} return true if the Object and it's keys both exist.
 */
export function exist(value: unknown, keys: string[] = undefined): boolean {
  // first missing key if found
  let missingKey: string;
  let isExist = true;

  if (value === undefined || value === null) {
    isExist = false;
  }

  if (isExist && keys !== undefined && keys !== null) {
    missingKey = keys.find((key: string) => {
      return value[key] === null || value[key] === undefined;
    });
  }

  return isExist && !missingKey;
}

/**
 *check if the given value is empty.
 *
 * @export
 * @param {*} value
 * @returns {boolean}
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isEmpty(value: any): boolean {
  return value == undefined || value == null || value.length == 0;
}

/**
 * get data from payload.
 *
 * @export
 * @template T Type of the payload.
 * @param {Payload<T>} payload Payload to extract data from.
 * @returns {Promise<T>} Promise to return the data from the payload.
 */
export function getPayloadData<T extends unknown = unknown>(payload: Payload<T>): Promise<T> {
  if (!exist(payload)) {
    return Promise.reject();
  }

  if (!exist(payload.data)) {
    return Promise.reject(payload.errors);
  }

  return Promise.resolve(payload.data);
}

export function getCurrentUser(): Promise<{ user: Entity | Student | Tutor | Teacher; type: UserType }> {
  const token = JSON.parse(localStorage.getItem(process.env.REACT_APP_TOKEN_DETAILS));
  const tokenType = token?.type;
  let promise;
  let result: { user: Entity | Student | Tutor | Teacher; type: UserType } = undefined;
  switch (tokenType) {
    case 'student':
      promise = AuthenticationUtilities.getUser().then((response) => {
        result = {
          user: response?.data,
          type: UserType.student,
        };
      });
      break;
    case 'entity':
      promise = EntityAuthenticationUtilities.getEntity().then((response) => {
        result = {
          user: response?.data,
          type: UserType.entity,
        };
      });
      break;
    case 'tutor':
      promise = TutorAuthenticationUtilities.getTutor().then((response) => {
        result = {
          user: response?.data,
          type: UserType.tutor,
        };
      });
      break;
    case 'teacher':
      promise = TeacherAuthenticationUtilities.getTeacher().then((response) => {
        result = {
          user: response?.data,
          type: UserType.teacher,
        };
      });
      break;
    default:
      promise = Promise.resolve();
      break;
  }

  return promise.then(() => {
    return result;
  });
}

/**
 * get data from payload.
 *
 * @export
 * @param {string} string string to uppercase first letter.
 * @returns string with uppercase.
 */
export function capitalizeFirstLetter(string: string): string {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export function userLogout(): Promise<UserType> {
  const token = JSON.parse(localStorage.getItem(process.env.REACT_APP_TOKEN_DETAILS));
  const tokenType = token?.type;
  let promise;
  let type: UserType = undefined;
  switch (tokenType) {
    case 'student':
      promise = AuthenticationUtilities.logout().then(() => {
        type = UserType.student;
      });
      break;
    case 'entity':
      promise = EntityAuthenticationUtilities.entityLogout().then(() => {
        type = UserType.entity;
      });
      break;
    case 'tutor':
      promise = TutorAuthenticationUtilities.tutorLogout().then(() => {
        type = UserType.tutor;
      });
      break;
    case 'teacher':
      promise = TeacherAuthenticationUtilities.teacherLogout().then(() => {
        type = UserType.teacher;
      });
      break;
    default:
      promise = Promise.resolve();
      break;
  }
  return promise.then(() => {
    return type;
  });
}
