/**
 * @author Raghda Wessam
 * @date 2021-08-10
 * @description login page.
 * @filename login.tsx
 */
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field, FormikState } from 'formik';
import Toastr from 'toastr';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import { LOGIN_SCHEMA } from '../../../utilities/authentication';
import { Authentication as AuthenticationUtilities } from '../../../utilities/authentication';
import 'react-phone-number-input/style.css';
import { LoginInput } from '../../../definitions/interfaces/inputs/login';
import { ROUTES } from '../../../definitions/consts/routes';
import { USER_CONTEXT } from '../../../contexts/user-context';
import { UserType } from '../../../definitions/interfaces/common';
// images
import loginImg from '../../../../static/images/learner-login/learner-login.jpeg';

const Login: React.FunctionComponent = () => {
  const userContext = useContext(USER_CONTEXT);
  const [mobileNumberValue, setMobileNumberValue] = useState('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isDisableBts, setIsDisableBtns] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (userContext.user && userContext.type === UserType.entity) {
      history.push(ROUTES.entityDashboard.getPath('Dashboard'));
    }

    if (userContext.user && userContext.type === UserType.student) {
      history.push(ROUTES.userDashboard.path);
    }
    if (userContext.user && userContext.type === UserType.tutor) {
      history.push(ROUTES.tutorJobs.path);
    }
    if (userContext.user && userContext.type === UserType.teacher) {
      history.push(ROUTES.presenterDashboard.getPath('Dashboard'));
    }
  }, [userContext]);

  const handleSubmit = (
    values: LoginInput,
    resetForm: (
      nextState?: Partial<
        FormikState<{
          mobileNumber: string;
          countryCode: string;
          password: string;
        }>
      >
    ) => void
  ) => {
    setIsDisableBtns(true);
    AuthenticationUtilities.login(values)
      .then((response) => {
        if (userContext?.updateUser) {
          userContext.updateUser(response?.data, UserType.student);
        }
        Toastr.success('Successfully logged in');
        setMobileNumberValue('');
        resetForm();
        history.push({
          pathname: `${ROUTES.userDashboard.path}`,
        });
      })
      .catch((error) => {
        Toastr.error(error);
        console.error(error);
        setIsDisableBtns(false);
      });
  };

  const handlePhoneNumberChange = (
    value: string,
    setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void
  ) => {
    if (value && value !== undefined) {
      setFieldValue('mobileNumber', `${parsePhoneNumber(value)?.nationalNumber}`);
      setFieldValue('countryCode', `+${parsePhoneNumber(value)?.countryCallingCode}`);
    }
  };

  return (
    <div className="login login--entity login-tutor">
      <div className="login__media login__media--entity login_tutor_img_container">
        <img src={loginImg} alt="" />
      </div>
      <div className="login__form-container">
        <h1 className="login__form-title">Login as learner</h1>
        <p className="login__form-subtitle">Login with the same account you use in your Orcas mobile App</p>
        <div className="login__form-wrapper">
          <Formik
            initialValues={{
              mobileNumber: '',
              countryCode: '',
              password: '',
            }}
            enableReinitialize={true}
            validationSchema={LOGIN_SCHEMA}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, resetForm);
            }}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form className="login__form">
                <div className="input-wrapper">
                  <label htmlFor="mobileNumber">Mobile Number</label>
                  <PhoneInput
                    placeholder="Ex 0100 234 5678"
                    value={mobileNumberValue}
                    defaultCountry="EG"
                    onChange={(value) => {
                      setMobileNumberValue(value);
                      handlePhoneNumberChange(value, setFieldValue);
                    }}
                  />
                  {errors.mobileNumber && touched.mobileNumber ? (
                    <div className="login__error">{errors.mobileNumber}</div>
                  ) : null}

                  {errors.countryCode && touched.countryCode ? (
                    <div className="login__error">{errors.countryCode}</div>
                  ) : null}
                </div>

                <div className="input-wrapper">
                  <label htmlFor="password">Password</label>
                  <Field type={showPassword ? 'text' : 'password'} name="password" placeholder="Your password here" />
                  {errors.password && touched.password ? <div className="login__error">{errors.password}</div> : null}
                </div>

                <div className="input-wrapper">
                  <label htmlFor="checked" className="show-pass-input">
                    <Field
                      type="checkbox"
                      name="checked"
                      value="Show password"
                      checked={showPassword}
                      onChange={() => {
                        const updatedCheckValue = !showPassword;
                        setShowPassword(updatedCheckValue);
                      }}
                    />
                    Show password
                  </label>
                </div>
                {/* //TODO: add loader */}
                <button className="login__form-btn btn btn--green" type="submit" disabled={isDisableBts}>
                  Login
                </button>
              </Form>
            )}
          </Formik>

          <div className="login__forget-password-container">
            <h3 className="login__forget-password-title">Forgot you password?</h3>
            <div className="forget-password">
              <h4 className="forget-password__title">Here’s how to recover your password:</h4>
              <ul className="forget-password__steps unstyle">
                <li>1- Open orcas app </li>
                <li>2- Enter your mobile number</li>
                <li>3- press forgot password button and follow the steps</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
