import React, { useEffect, useState } from 'react';
import EditIcon from '../../../../../static/images/icons/edit_teacher.svg';
import { EntityTeachers } from 'src/ts/utilities/entity-teachers';
import { Teacher } from 'src/ts/definitions/interfaces/teacher';
import Modal from '../../../common/modal';
import AddTeacher from './add-teacher';
import DeleteIcon from '../../../../../static/images/icons/delete_icon.svg';
import { capitalizeFirstLetter } from 'src/ts/utilities/common';
import EditTeacher from './edit-teacher';
import Loader from 'src/ts/react-components/common/loader';
import { formate } from 'src/ts/utilities/moment-formate-Date';
import placeHolderImg from 'src/static/images/placeholder.png';

const Teachers: React.FunctionComponent = () => {
  const [Teachers, setTeachers] = useState<Teacher[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [teacherId, setTeacherId] = useState('');

  const getAllTeacher = async () => {
    EntityTeachers.getAllTeachers().then((response) => {
      setTeachers(response);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    getAllTeacher();
  }, []);

  const deleteTeacher = (id: string) => {
    setIsLoading(true);
    EntityTeachers.deleteTeacher(id).then(() => {
      setIsLoading(false);
      getAllTeacher();
    });
  };

  const handelEditTeacherClick = (teacherId: string) => {
    setShowModal(false);
    setShowEditModal(true);
    setTeacherId(teacherId);
  };
  if (isLoading) return <Loader />;
  else
    return (
      <>
        <div className="heading-and-button-wrapper">
          <h2 className="section-title">Teachers</h2>
          <button
            onClick={() => {
              setTeacherId('');
              setShowModal(true);
              setShowEditModal(false);
            }}
            className="add-new-teacher-btn"
          >
            <span>+ </span>
            <p> Add New Teacher</p>
          </button>
        </div>

        {Teachers &&
          Teachers.map((teacher) => {
            return (
              <div key={teacher.presenterId} className="teacher-wrapper">
                <div className="teacher-wrapper__teacher-deatils">
                  <img src={teacher.image || placeHolderImg} alt="Presenter Image" />
                  <div className="teacher-wrapper__name-and-date-of-join">
                    <h3>
                      {capitalizeFirstLetter(teacher.title)} {capitalizeFirstLetter(teacher.firstName)}{' '}
                      {capitalizeFirstLetter(teacher.lastName)}
                    </h3>
                    <span>Profile created {formate(teacher.createdAt)}</span>
                  </div>
                </div>
                <div className="teacher-wrapper__cta-btn">
                  <button className="delete-btn" onClick={() => deleteTeacher(teacher.presenterId)}>
                    <img src={DeleteIcon} alt="Delete icon" />
                    <p> Delete Teacher</p>
                  </button>
                  <button onClick={() => handelEditTeacherClick(teacher.presenterId)}>
                    <img src={EditIcon} alt="edit_icon" />
                    <p> Edit Teacher</p>
                  </button>
                </div>
              </div>
            );
          })}

        <div className="add-teacher__modal">
          {showModal && (
            <Modal showModal={showModal}>
              <AddTeacher getAllTeachers={getAllTeacher} setShowModal={setShowModal} />
            </Modal>
          )}
        </div>

        <div className="edit-teacher__modal">
          {showEditModal && (
            <Modal showModal={showEditModal}>
              <EditTeacher getAllTeachers={getAllTeacher} teacherId={teacherId} setShowModal={setShowEditModal} />
            </Modal>
          )}
        </div>
      </>
    );
};

export default Teachers;
