/**
 * @author Salma Hefnawy
 * @date 2021-08-23
 * @description implementation of entity authentication related API calls.
 * @filename entity-authentication.ts
 */

import { Network } from './network';
import { ENDPOINTS } from './endpoints';
import { EntityLoginInput } from '../definitions/interfaces/inputs/entity-login';
import { Payload } from '../definitions/interfaces/payload';
import { Entity } from '../definitions/interfaces/entity';
import { UserType } from '../definitions/interfaces/common';

/**
 * implement network related calls.
 */
export class EntityAuthentication {
  /**
   * call Login endpoint and set organization authentication keys in local storage.
   *
   * @param input entity login inputs.
   */

  public static entityLogin(input: EntityLoginInput): Promise<Payload<Entity>> {
    const body = {
      ...input,
    };
    const promise = Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.entityLogin.path}`, {
      method: ENDPOINTS.entityLogin.method,
      body: JSON.stringify(body),
    });

    return promise.then((payload: Payload<Entity>) => {
      if (payload?.data?.token && process.env.REACT_APP_TOKEN_DETAILS) {
        Network.storeTokenDetails(
          payload.data.token.access,
          payload.data.token.refresh,
          Number(payload.data.token.expireAt),
          UserType.entity
        );
      }
      return payload;
    });
  }

  /**
   *fetch current logged in entity
   *
   * @static
   * @returns {Promise<Payload<Entity>>}
   * @memberof EntityAuthentication
   */
  public static getEntity(): Promise<Payload<Entity>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getEntity.path}`, {
      method: ENDPOINTS.getEntity.method,
    });
  }

  /**
   *logout entity
   *
   * @static
   * @returns {Promise<Payload>}
   * @memberof EntityAuthentication
   */
  public static entityLogout(): Promise<Payload> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.entityLogout.path}`, {
      method: ENDPOINTS.entityLogout.method,
    });
  }
}
