import React from 'react';

export interface CourseInfoProps {
  subjectName?: string;
  courseTitle?: string;
  schoolSystems?: string;
  grades: string;
  courseDescription: string;
  courseObjective: string;
  courseImage: string;
  presenterImage: string;
  presenterFName: string;
  presenterLName: string;
  organizationImage: string;
  organizationName: string;
  organizationAddress: string;
  presenterTitle: string;
}
const CourseInfo: React.FunctionComponent<CourseInfoProps> = (props) => {
  const {
    courseTitle,
    subjectName,
    schoolSystems,
    grades,
    courseDescription,
    courseObjective,
    courseImage,
    presenterTitle,
    presenterImage,
    presenterFName,
    presenterLName,
    organizationImage,
    organizationName,
    organizationAddress,
  } = props;
  return (
    <>
      <div className="figure">
        <div className="figure-details-contet">
          <div className="figure-details-contet__heading">
            <h1>{courseTitle} course</h1>
            <h3>
              {subjectName} | {schoolSystems} | {grades}
            </h3>
          </div>
          {/* description-objective */}
          <div className="figure-details-contet__description-objective">
            <h3>Course description</h3>
            <p>{courseDescription}</p>
          </div>

          <div className="figure-details-contet__description-objective">
            <h3>Course objectives</h3>
            <p>{courseObjective}</p>
          </div>
        </div>
        <img width="100%" src={courseImage} alt="course-img" />
      </div>
      {/* down the figure  */}

      <div className="presenter-enitity-info">
        <div className="presenter-branch-info__details">
          <img src={presenterImage} alt="presenter-img" />
          <h3>
            By {presenterTitle} {presenterFName} {presenterLName}
          </h3>
        </div>
        <div className="presenter-branch-info__details">
          <img src={organizationImage} />
          <div className="branch-info">
            <h3>{organizationName}</h3>
            <span>{organizationAddress}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseInfo;
