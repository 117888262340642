import { getPayloadData } from './common';
import { AddTeacherBody, Teacher } from '../definitions/interfaces/teacher';
import { EntityTeacher } from '../api/entity-teacher';
export class EntityTeachers {
  /**
   * get all entity teachers
   *
   * @static
   * @returns {Promise<Teacher[]>}
   * @memberof EntityCourse
   */
  public static getAllTeachers(): Promise<Teacher[]> {
    return EntityTeacher.getAllTeachers()
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   * delete entity teachers
   *
   * @static
   * @returns {Promise<>}
   * @memberof EntityCourse
   */
  public static deleteTeacher(teacherId: string): Promise<unknown> {
    return EntityTeacher.deleteTeacher(teacherId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   * delete entity teachers
   *
   * @static
   * @returns {Promise<>}
   * @memberof EntityCourse
   */
  public static addEntityTeacher(addTeacherInput: AddTeacherBody): Promise<unknown> {
    return EntityTeacher.addEntityTeacher(addTeacherInput)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   * edit entity teachers
   *
   * @static
   * @returns {Promise<>}
   * @memberof EntityCourse
   */
  public static editEntityTeacher(teacherId: string, editTeacherInput: AddTeacherBody): Promise<unknown> {
    return EntityTeacher.editEntityTeacher(teacherId, editTeacherInput)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   * get entity teacher by id
   *
   * @static
   * @returns {Promise<>}
   * @memberof EntityCourse
   */
  public static getEntityTeacherById(teacherId: string): Promise<unknown> {
    return EntityTeacher.getEntityTeacherById(teacherId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
