/**
 * @author Salma Hefnawy
 * @date 2021-08-23
 * @description implementation of Entity Authentication related utilities.
 * @filename entity-authentication.ts
 */
import * as YUP from 'yup';
import { EntityAuthentication as EntityAuthenticationAPI } from '../api/entity-authentication';
import { EntityLoginInput } from '../definitions/interfaces/inputs/entity-login';
import { Payload } from '../definitions/interfaces/payload';
import { Entity } from '../definitions/interfaces/entity';

/**
 * schema to validate the fields of entity login.
 */
export const ENTITY_LOGIN_SCHEMA = YUP.object().shape({
  email: YUP.string().required('Please enter email'),
  password: YUP.string().required('Please enter password').min(6, 'Password should be at least 6 characters'),
});

/**
 * a class that contains entity authentication related utilities.
 *
 * @export
 * @class EntityAuthentication
 */
export class EntityAuthentication {
  /**
   *entity login with email & password
   *
   * @static
   * @param {EntityLoginInput} input
   * @returns {Promise<Payload<Entity>>}
   * @memberof EntityAuthentication
   */
  public static entityLogin(input: EntityLoginInput): Promise<Payload<Entity>> {
    return EntityAuthenticationAPI.entityLogin(input)
      .then((result: Payload<Entity>) => {
        return result;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   *fetch current logged in entity
   *
   * @static
   * @returns {Promise<Payload<Entity>>}
   * @memberof EntityAuthentication
   */
  public static getEntity(): Promise<Payload<Entity>> {
    return EntityAuthenticationAPI.getEntity()
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  /**
   *logout current Entity
   *
   * @static
   * @returns {Promise<Payload>}
   * @memberof EntityAuthentication
   */
  public static entityLogout(): Promise<Payload> {
    return EntityAuthenticationAPI.entityLogout()
      .then((result: Payload) => {
        if (process.env.REACT_APP_TOKEN_DETAILS) localStorage.removeItem(process.env.REACT_APP_TOKEN_DETAILS);
        return result;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
