import React, { useEffect, useState } from 'react';
import { EntityProfile } from 'src/ts/api/entity-profile';
import SelectField from '../../../common/select-field';
import Toastr from '../../../common/toastr';

// export interface areasFieldProps {

// }
export interface AreaInCitiesInterface {
  areaId: number;
  areaNameEn: string;
  areaNameAr: string;
}
export interface CityInterface {
  cityId: number;
  cityName: string;
  cityAreas: AreaInCitiesInterface[];
}
export interface AreasFieldProps {
  selectedArea?: any;
  setSelectedAreas?: any;
}

const AreasField: React.FunctionComponent<AreasFieldProps | null> = (props) => {
  const { selectedArea, setSelectedAreas } = props;
  const [areasCity, setAreasCity] = useState<CityInterface[] | null>(null);

  useEffect(() => {
    EntityProfile.getAllCityAreas()
      .then((response) => {
        setAreasCity(response.data);
        setSelectedAreas(selectedArea);
      })
      .catch((error: any) => {
        Toastr.error(error);
        console.error(error);
      });
  }, []);
  useEffect(() => {
    if (areasCity) {
      const gradeIds = [];
      for (const areas of areasCity) {
        for (const area of areas.cityAreas) {
          gradeIds.push({
            value: area.areaId,
            label: area.areaNameEn,
          });
        }
      }
      setSelectedAreas(gradeIds);
    }
  }, []);
  const schoolSystemsOptions = areasCity?.map((city) => {
    return {
      label: city.cityName,
      options: city?.cityAreas.map((g) => {
        return {
          value: g.areaId,
          label: g.areaNameEn,
        };
      }),
    };
  });

  return (
    <>
      <SelectField
        title="school-system"
        isMulti={false}
        showTitle={false}
        isSearchable={true}
        placeholder="Select an area"
        optionValues={schoolSystemsOptions ?? []}
        className="basic-multi-select"
        selectedOption={selectedArea}
        onChange={(selectedItems) => {
          setSelectedAreas(selectedItems);
        }}
      />
    </>
  );
};

export default AreasField;
