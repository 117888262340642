import React, { useState } from 'react';
import time from '../../../../static/images/icons/time.svg';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/ts/definitions/consts/routes';
import { EntityCourse } from 'src/ts/utilities/entity-course';
import { Presenter } from 'src/ts/definitions/interfaces/entity-dashboard-classes';
import { capitalizeFirstLetter } from 'src/ts/utilities/common';
import { formate } from 'src/ts/utilities/moment-formate-Date';

export interface EntityClassesProps {
  startDate?: string;
  courseName: string;
  teacherName: string;
  image: string;
  lessonName?: string;
  className?: string;
  courseId: string;
  lessonId: string;
  classId: string;
  presenterDetails?: Presenter;
  teacherTitle: string;
}

const UpcomingClassCard: React.FunctionComponent<EntityClassesProps> = (props) => {
  const [isDisableBts, setIsDisableBtns] = useState<boolean>(false);
  const startOnlineClass = (): void => {
    setIsDisableBtns(true);
    EntityCourse.startOnlineClass(props.courseId, props.lessonId, props.classId, props.presenterDetails)
      .then((joinUrl) => {
        setIsDisableBtns(false);
        window.open(joinUrl, '_blank');
      })
      .catch((err) => {
        console.error(err);
        setIsDisableBtns(false);
      });
  };

  return (
    <div className="card card_entity">
      <img className="card__image" src={props.image} alt="course image" />
      <div className="card__body">
        <div className="card__text-wrapper">
          <p className="card__date">
            <img src={time} alt="duration" />
            {`Starts ${formate(props.startDate)}`}
          </p>
          <p className="card__title">
            {capitalizeFirstLetter(props.courseName)} by {`${capitalizeFirstLetter(props.teacherTitle)}. `}
            {capitalizeFirstLetter(props.teacherName)}
          </p>
          <p className="card__subtitle">
            {capitalizeFirstLetter(props.lessonName)} | {capitalizeFirstLetter(props.className)}
          </p>
        </div>
        <div className="card__action">
          <Link to={ROUTES.entityCourse.getPath(props.courseId)} className="button btn">
            View course details
          </Link>
          <button type="button" className="button btn btn--green" onClick={startOnlineClass} disabled={isDisableBts}>
            Start a classroom
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpcomingClassCard;
