import React, { useEffect } from 'react';

const MarkAsDone: React.FunctionComponent<{
  classId: string;
  btnState?: string;
  markAsDone: (id: string) => void;
}> = (props) => {
  const { classId, btnState, markAsDone } = props;

  const [buttonState, setButtonState] = React.useState('');
  useEffect(() => {
    if (btnState) setButtonState(props.btnState);
  }, []);

  let result = (
    <button className="accordion-content__done-button btn" type="button" onClick={() => setButtonState('confirming')}>
      <p>mark as done</p>
    </button>
  );
  const callApi = () => {
    markAsDone(classId);
    setButtonState('confirmed');
  };
  switch (buttonState) {
    case 'confirmed':
      result = (
        <button className="accordion-content__confirming-button btn btn--green" disabled>
          &#10003; Marked as done
        </button>
      );
      break;
    case 'confirming':
      result = (
        <button className="btn accordion-content__confirming-button btn--green" onClick={callApi}>
          confirm
        </button>
      );
      break;
    default:
      break;
  }

  return result;
};

export default MarkAsDone;
