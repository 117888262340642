import { Network } from './network';
import { ENDPOINTS } from './endpoints';

import { PresenterCourse as PresenterCourseInterface } from '../definitions/interfaces/presenter-course-details';

import { PresenterLessons as PresenterLessonsInterface } from '../definitions/interfaces/presenter-lesson';
import { Subject as SubjectInterface } from '../definitions/interfaces/subject';
import { SchoolSystem as SchoolSystemInterface } from '../definitions/interfaces/presenter-course';
import { Payload } from '../definitions/interfaces/payload';
import { AddClassFormInput } from '../definitions/interfaces/inputs/add-class-input';
import { AddLessonInput } from '../definitions/interfaces/inputs/add-lesson-input';
import { AddCourseInput } from '../definitions/interfaces/inputs/add-course-input';
import { EntityDashboardClasses } from '../definitions/interfaces/entity-dashboard-classes';
import { PresenterClass as PresenterClassInterface } from '../definitions/interfaces/presenter-class';
import { CourseBody } from '../definitions/interfaces/entity-courses-listing';
import { SearchBody } from '../definitions/interfaces/search-body';
import { BranchLookup } from '../definitions/interfaces/entity-course';

import { exist } from '../utilities/common';
import { Presenter } from './../definitions/interfaces/entity-dashboard-classes';

/**
 * implement network related calls.
 */
export class PresenterCourse {
  /**
   *fetch  presenter course details
   *
   * @static
   * @param {string} courseId
   * @returns {Promise<Payload<PresenterCourseInterface>>}
   * @memberof  PresenterCourse
   */
  public static getPresenterCourseDetails(courseId: string): Promise<Payload<PresenterCourseInterface>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getPresenterCourseDetails.url(courseId)}`, {
      method: ENDPOINTS.getPresenterCourseDetails.method,
    });
  }

  /**
   *fetch  presenter course lessons
   *
   * @static
   * @param {string} courseId
   * @returns {Promise<Payload<PresenterLessonInterface[]>>}
   * @memberof  PresenterCourse
   */
  public static getPresenterCourseLessons(courseId: string): Promise<Payload<PresenterLessonsInterface>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getPresenterCourseLessons.url(courseId)}`, {
      method: ENDPOINTS.getPresenterCourseLessons.method,
    });
  }

  /**
   * Create a new course in the data source and lint it with the Entity.
   */
  public static addPresenterCourse(input: AddCourseInput): Promise<Payload<PresenterCourseInterface>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.addPresenterCourse.url}`, {
      method: ENDPOINTS.addPresenterCourse.method,
      body: JSON.stringify(input),
    });
  }

  /**
   * edit entity course
   *
   * @static
   * @param {AddCourseInput} input
   * @returns {Promise<Payload<PresenterCourseInterface>>}
   * @memberof EntityCourse
   */
  public static editPresenterCourse(
    courseId: string,
    input: AddCourseInput
  ): Promise<Payload<PresenterCourseInterface>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.editPresenterCourse.url(courseId)}`, {
      method: ENDPOINTS.editPresenterCourse.method,
      body: JSON.stringify(input),
    });
  }
  /**
   * add entity class
   *
   * @static
   * @param {string} input
   * @returns {Promise<Payload>}
   * @memberof PresenterCourse
   */
  public static addPresenterClass(courseId: string, lessonId: string, input: AddClassFormInput): Promise<Payload> {
    return Network.fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.addPresenterClass.url(courseId, lessonId)}`,
      {
        method: ENDPOINTS.addPresenterClass.method,
        body: JSON.stringify(input),
      }
    );
  }

  /**
   * edit presenter class
   *
   * @static
   * @param {string} input
   * @returns {Promise<Payload>}
   * @memberof PresenterCourse
   */
  public static editPresenterClass(
    courseId: string,
    lessonId: string,
    classId: string,
    input: AddClassFormInput
  ): Promise<Payload> {
    return Network.fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.editPresenterClass.url(courseId, lessonId, classId)}`,
      {
        method: ENDPOINTS.editPresenterClass.method,
        body: JSON.stringify(input),
      }
    );
  }
  /**
   * update presenter course publish status
   *
   * @static
   * @param {number} publishStatusId
   * @returns {Promise<Payload>}
   * @memberof PresenterCourse
   */
  public static updatePublishCourseStatus(publishStatusId: number, courseId: string): Promise<Payload> {
    const url = `${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.updatePublishCourseStatusPresenter.url(courseId)}`;
    return Network.fetch(url, {
      method: ENDPOINTS.updatePublishCourseStatusPresenter.method,
      body: JSON.stringify({ publishStatusId }),
    });
  }
  /**
   * add entity lesson
   *
   * @static
   * @param {string} input
   * @returns {Promise<Payload>}
   * @memberof PresenterCourse
   */
  public static addPresenterLesson(courseId: string, input: AddLessonInput): Promise<Payload> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.addPresenterLesson.url(courseId)}`, {
      method: ENDPOINTS.addPresenterLesson.method,
      body: JSON.stringify(input),
    });
  }
  /**
   * edit presenter lesson
   *
   * @static
   * @param {string} input
   * @returns {Promise<Payload>}
   * @memberof PresenterCourse
   */
  public static editPresenterLesson(
    courseId: string,
    lessonId: string,
    newLessonName: AddLessonInput
  ): Promise<Payload> {
    return Network.fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.editPresenterLesson.url(courseId, lessonId)}`,
      {
        method: ENDPOINTS.editPresenterLesson.method,
        body: JSON.stringify(newLessonName),
      }
    );
  }
  /**
   * delete presenter lesson
   *
   * @static
   * @param {string} input
   * @memberof Presenterourse
   */
  public static deletePresenterLesson(courseId: string, lessonId: string): Promise<Payload> {
    return Network.fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.deletePresenterLesson.url(courseId, lessonId)}`,
      {
        method: ENDPOINTS.deletePresenterLesson.method,
      }
    );
  }

  /**
   *Get All courses
   *
   * @static
   * @returns {Promise<Payload<Courses>>}
   * @memberof Sessions
   */
  public static getAllCourses(offset: number, limit: number, searchBody?: SearchBody): Promise<Payload<CourseBody[]>> {
    const body = {
      ...searchBody,
    };
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getPresenterCourses.path(offset, limit)}`, {
      method: ENDPOINTS.getPresenterCourses.method,
      body: JSON.stringify(body),
    });
  }
  /**
   *Get all course subjects
   *
   * @static
   * @returns {Promise<Payload<SubjectInterface[]>>}
   * @memberof EntityCourse
   */
  public static getAllSubjects(): Promise<Payload<SubjectInterface[]>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getAllPresenterSubjects.path}`, {
      method: ENDPOINTS.getAllPresenterSubjects.method,
    });
  }
  /**
   * get Unconfirmed Classes
   *
   * @static
   * @param {number} offset
   * @param {number}  limit
   * @returns {Promise<Payload>}
   * @memberof EntityCourse
   */
  public static getUnconfirmedClasses(offset: number, limit: number): Promise<Payload<EntityDashboardClasses[]>> {
    return Network.fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getPresenterUnconfirmedClasses.url(offset, limit)}`,
      {
        method: ENDPOINTS.getPresenterUnconfirmedClasses.method,
      }
    );
  }

  /**
   *Get all course school systems
   *
   * @static
   * @returns {Promise<Payload<SchoolSystemInterface[]>>}
   * @memberof EntityCourse
   */
  public static getAllSchoolSystems(): Promise<Payload<SchoolSystemInterface[]>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getAllPresenterSchoolSystems.path}`, {
      method: ENDPOINTS.getAllPresenterSchoolSystems.method,
    });
  }

  /**
   * Get all course presenters
   *
   * @static
   * @returns {Promise<Payload<PresenterInterface[]>>}
   * @memberof EntityCourse
   */
  // public static getAllPresenters(): Promise<Payload<PresenterInterface[]>> {
  //   return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getAllPresenters.path}`, {
  //     method: ENDPOINTS.getAllPresenters.method,
  //   });
  // }

  /**
   * get Unconfirmed Classes
   *
   * @static
   * @param {number} offset
   * @param {number}  limit
   * @returns {Promise<Payload>}
   * @memberof EntityCourse
   */
  public static getUpcommingClasses(offset: number, limit: number): Promise<Payload<EntityDashboardClasses[]>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getPresenterUpcomming.url(offset, limit)}`, {
      method: ENDPOINTS.getPresenterUpcomming.method,
    });
  }
  /**
   * confirm Class
   *
   * @static
   * @param {string} classId
   * @returns {Promise<Payload>}
   * @memberof EntityCourse
   */
  public static confirmClass(classId: string): Promise<Payload> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.confirmPresenterClass.path}`, {
      method: ENDPOINTS.confirmPresenterClass.method,
      body: JSON.stringify({ classId: classId }),
    });
  }

  /**
   * Get all branches
   *
   * @static
   * @returns {Promise<Payload<BranchLookup>>}
   * @memberof EntityCourse
   */
  public static getAllBranches(): Promise<Payload<BranchLookup[]>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getAllPresenterBranches.path}`, {
      method: ENDPOINTS.getAllPresenterBranches.method,
    });
  }

  /**
   *fetch  presenter class details
   *
   * @static
   * @param {string} courseId
   * @returns {Promise<Payload<PresenterClassInterface>>}
   * @memberof  PresenterCourse
   */
  public static getPresenterClass(
    courseId: string,
    lessonId: string,
    classId: string
  ): Promise<Payload<PresenterClassInterface>> {
    return Network.fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getPresenterClass.url(courseId, lessonId, classId)}`,
      {
        method: ENDPOINTS.getPresenterClass.method,
      }
    );
  }

  public static startOnlineClass(
    courseId: string,
    lessonId: string,
    classId: string,
    presenter: Presenter
  ): Promise<Payload<{ presenterJoinUrl: string }>> {
    const url = `${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.startPresenterOnlineClass.url(
      courseId,
      lessonId,
      classId
    )}`;
    const input: any = presenter;
    if (exist(input) && !exist(input, ['presenterId'])) {
      input.presenterId = input?.id;
    }
    return Network.fetch(url, {
      method: ENDPOINTS.startPresenterOnlineClass.method,
      body: JSON.stringify(input),
    });
  }
}
