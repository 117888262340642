import React, { useState, useContext, useEffect } from 'react';
import { Formik, Form, Field, FormikState } from 'formik';
import Toastr from 'toastr';
import { TUTOR_LOGIN_SCHEMA } from '../../../../utilities/tutor-authentication';
import { TutorLoginInput } from '../../../../definitions/interfaces/inputs/tutor-login';
import { TutorAuthentication as TutorAuthenticationUtilities } from '../../../../utilities/tutor-authentication';
import { UserType } from '../../../../definitions/interfaces/common';
import { USER_CONTEXT } from '../../../../contexts/user-context';
import { ROUTES } from 'src/ts/definitions/consts/routes';
import { useHistory } from 'react-router-dom';
import tutorLogin from '../../../../../static/images/tutor-login.png';

const TutorLogin: React.FunctionComponent = () => {
  const userContext = useContext(USER_CONTEXT);
  const [showPassword, setShowPassword] = useState(false);
  const [isDisableBts, setIsDisableBtns] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (userContext.user && userContext.type === UserType.entity) {
      history.push(ROUTES.entityDashboard.getPath('Dashboard'));
    }
    if (userContext.user && userContext.type === UserType.student) {
      history.push(ROUTES.userDashboard.path);
    }
    if (userContext.user && userContext.type === UserType.tutor) {
      history.push(ROUTES.tutorJobs.path);
    }
    if (userContext.user && userContext.type === UserType.teacher) {
      history.push(ROUTES.presenterDashboard.getPath('Dashboard'));
    }
  }, [userContext]);

  const handleSubmit = (
    values: TutorLoginInput,
    resetForm: (
      nextState?: Partial<
        FormikState<{
          email: string;
          password: string;
        }>
      >
    ) => void
  ) => {
    setIsDisableBtns(true);
    TutorAuthenticationUtilities.tutorLogin(values)
      .then((response) => {
        if (userContext?.updateUser) {
          userContext.updateUser(response?.data, UserType.tutor);
        }
        Toastr.success('Successfully logged in');
        resetForm();
        history.push({
          pathname: `${ROUTES.tutorJobs.path}`,
        });
      })
      .catch((error) => {
        Toastr.error(error);
        console.error(error);
        setIsDisableBtns(false);
      });
  };

  return (
    <div className="login login--entity login-tutor">
      <div className="login__media login__media--entity login_tutor_img_container">
        <img src={tutorLogin} alt="" />
      </div>
      <div className="login__form-container">
        <h1 className="login__form-title">Login as a tutor</h1>
        <p className="login__form-subtitle">Login with the same account you use to login to the Orcas mobile app</p>
        <div className="login__form-wrapper">
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            enableReinitialize={true}
            validationSchema={TUTOR_LOGIN_SCHEMA}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, resetForm);
            }}
          >
            {({ errors, touched }) => (
              <Form className="login__form login__form--entity">
                <div className="input-wrapper">
                  <label htmlFor="email">Email address</label>
                  <Field type="email" name="email" placeholder="Your email here" />
                  {errors.email && touched.email ? <div className="login__error">{errors.email}</div> : null}
                </div>

                <div className="input-wrapper">
                  <label htmlFor="password">Password</label>
                  <Field type={showPassword ? 'text' : 'password'} name="password" placeholder="Your password here" />
                  {errors.password && touched.password ? <div className="login__error">{errors.password}</div> : null}
                </div>

                <div className="input-wrapper">
                  <label htmlFor="checked" className="show-pass-input">
                    <Field
                      type="checkbox"
                      name="checked"
                      value="Show password"
                      checked={showPassword}
                      onChange={() => {
                        const updatedCheckValue = !showPassword;
                        setShowPassword(updatedCheckValue);
                      }}
                    />
                    Show password
                  </label>
                </div>
                {/* //TODO: add loader */}
                <button className="login__form-btn btn btn--green" type="submit" disabled={isDisableBts}>
                  Login
                </button>
              </Form>
            )}
          </Formik>

          <div className="login__forget-password-container">
            <h3 className="login__forget-password-title">Forgot you password?</h3>
            <p className="login__forget-password-email">Contact the Partner Help Center or reset it using the app.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorLogin;
