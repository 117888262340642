import React, { useState, useEffect } from 'react';
import { EntityDashboardClasses } from 'src/ts/definitions/interfaces/entity-dashboard-classes';
import UpcomingClassCard from './upcomming-class-card';
import UnConfirmedClass from './unconfirmed-class-card';
import Loader from '../../../common/loader';
// import { USER_CONTEXT } from 'src/ts/contexts/user-context';
import { PresenterCourse } from 'src/ts/api/presenter-course';
import Carousel from 'src/ts/react-components/common/carousel';

const PresenterDashboard: React.FC = () => {
  // const userContext = useContext(USER_CONTEXT);
  // const currentUser = userContext.type;
  // state of window-width
  const [width, setWidth] = useState<number | any>(window.innerWidth);
  // set window width with resizing
  const handleResize = () => setWidth(window.innerWidth);
  //  subscribe on window Resizing
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // states of  Response < Upcomming courses API>
  const [upCommingClasses, setUpCommingClasses] = useState<EntityDashboardClasses[]>([]);
  const [loadingUpcomming, setLoadingUpcomming] = useState<boolean | null>(null);

  // states of  Response < Unconfirmed courses API>
  const [unConfirmedCourses, setUnConfirmedCourses] = useState<EntityDashboardClasses[]>([]);
  const [loadingUnconfirmed, setLoadingUnconfirmed] = useState<boolean | null>(null);

  // get upcomming classes
  const getUpcommingClasses = () => {
    setLoadingUpcomming(true);
    PresenterCourse.getUpcommingClasses(0, 10)
      .then((response) => {
        setUpCommingClasses(response.data);
        setLoadingUpcomming(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingUpcomming(false);
      });
  };
  // get unconfirmed classes
  const getUnConfirmClasses = () => {
    setLoadingUnconfirmed(true);
    PresenterCourse.getUnconfirmedClasses(0, 10)
      .then((response) => {
        setUnConfirmedCourses(response.data);
        setLoadingUnconfirmed(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingUnconfirmed(false);
      });
  };
  useEffect(() => {
    getUpcommingClasses();
    getUnConfirmClasses();
  }, []);

  // confirmClass
  const confirmClass = (classId: any) => {
    PresenterCourse.confirmClass(classId)
      .then(() => getUnConfirmClasses())
      .catch((error) => console.error(error));
  };
  const [showedItems, setShowedItems] = useState<number | null>(null);
  const getShowedItems = () => {
    if (width >= 1200) setShowedItems(4);
    else if (width < 1200 && width >= 820) setShowedItems(3);
    else if (width < 820 && width >= 480) setShowedItems(2);
    else setShowedItems(1);
  };
  useEffect(() => {
    getShowedItems();
  }, [width]);
  if (loadingUnconfirmed && loadingUpcomming) return <Loader />;
  else
    return (
      <>
        <h1 style={{ margin: '50px 0' }}>My Dashboard</h1>
        {upCommingClasses?.length && !loadingUpcomming ? (
          <>
            <h2 className="course_heading">Upcoming classes</h2>
            <Carousel show={width > 700 ? 2 : 1} withIndicator containerClassName="margin-b-100">
              {upCommingClasses?.map((course, index) => {
                return (
                  <UpcomingClassCard
                    key={index}
                    classId={course?.class?.id}
                    lessonId={course?.class?.lessonId}
                    courseId={course.course?.id}
                    courseName={course.course?.title}
                    teacherName={course.presenter?.firstName}
                    teacherTitle={course.presenter?.title}
                    lessonName={course.class?.lessonName}
                    className={course.class?.name}
                    startDate={course?.class?.startAt}
                    image={course.course?.image}
                    presenterDetails={course?.presenter}
                  />
                );
              })}
            </Carousel>
          </>
        ) : (
          ''
        )}
        {/* <div style={{ height: '100px' }}></div> */}

        {unConfirmedCourses?.length ? (
          <>
            <h2 className="course_heading">Unconfirmed Past Classes</h2>
            <Carousel show={showedItems} withIndicator>
              {unConfirmedCourses?.map((course) => {
                return (
                  <UnConfirmedClass
                    key={course?.class?.id}
                    classId={course.class?.id}
                    courseName={course.course?.title}
                    teacherName={course.presenter?.name}
                    teacherPronoun={course.presenter?.title}
                    className={course.class?.name}
                    image={course.course?.image}
                    endedAt={course?.class.endedAt}
                    confirmClass={confirmClass}
                  />
                );
              })}
            </Carousel>
          </>
        ) : (
          ''
        )}
      </>
    );
};
export default PresenterDashboard;
