import React, { useState } from 'react';
import Toastr from 'toastr';
import CloseModalIcon from '../../../../../static/images/icons/popup_close.svg';
import { PresenterCourse as PresenterCourseUtilities } from '../../../../utilities/presenter-course';
import { AddLessonInput } from '../../../../definitions/interfaces/inputs/add-lesson-input';

export interface AddLessonProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  courseId: string;
  setShouldRefreshListing: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddPresenterLesson: React.FunctionComponent<AddLessonProps> = (props) => {
  const { setShowModal, courseId, setShouldRefreshListing } = props;
  const [lessonName, setLessonName] = useState('');
  const [isDisableBts, setIsDisableBtns] = useState<boolean>(false);

  const handleAddLesson = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsDisableBtns(true);
    const addLessonInput: AddLessonInput = {
      lessonName,
    };

    PresenterCourseUtilities.addPresenterLesson(courseId, addLessonInput)
      .then(() => {
        Toastr.success('Lesson added successfully');
        setShouldRefreshListing(true);
        setIsDisableBtns(false);
      })
      .catch((error) => {
        Toastr.error(error);
        console.error(error);
        setIsDisableBtns(false);
      })
      .finally(() => {
        setLessonName('');
        setShowModal(false);
        setShouldRefreshListing(false);
        setIsDisableBtns(false);
      });
  };

  return (
    <div className="add-lesson">
      <div className="add-lesson__header">
        <h1 className="add-lesson__header-title">add new lesson</h1>
        <button
          type="button"
          className="add-lesson__header-button"
          onClick={() => {
            setShowModal(false);
            setLessonName('');
          }}
        >
          <img className="add-lesson__header-image" src={CloseModalIcon} alt="" />
        </button>
      </div>
      <div className="add-lesson__content">
        <div className="add-lesson__input">
          <label htmlFor="lesson-name">
            lesson name <span className="required-field"> *</span>
          </label>
          <input placeholder="Lesson name here" type="text" onChange={(e) => setLessonName(e.target.value)} />
        </div>
        <button
          type="button"
          className="add-lesson__content-button btn"
          onClick={(e) => handleAddLesson(e)}
          disabled={isDisableBts}
        >
          save
        </button>
      </div>
    </div>
  );
};
export default AddPresenterLesson;
