import React from 'react';
import arrowIcon from '../../../static/images/icons/arrow.svg';

const Pagination: React.FunctionComponent<any> = ({
  currentDisplayedCourses,
  itemPerPage,
  paginate,
  currentItemsLength,
  isLastPage,
}) => {
  return (
    <nav className="nav-wrapper">
      <ul className="pagination">
        <button
          onClick={() => paginate(currentDisplayedCourses - itemPerPage)}
          className={'pagination-item navigate prev '}
          disabled={currentDisplayedCourses === 0 ? true : false}
        >
          <img src={arrowIcon} alt="arrow" />
        </button>

        <button
          onClick={() => paginate(currentDisplayedCourses + itemPerPage)}
          className="pagination-item navigate next"
          disabled={currentItemsLength < itemPerPage ? true : false || isLastPage}
        >
          <img src={arrowIcon} alt="arrow" />
        </button>
      </ul>
    </nav>
  );
};

export default Pagination;
