import React, { useState } from 'react';
import { EntityProfile } from 'src/ts/api/entity-profile';
import Toastr from 'toastr';
import CloseModal from '../../../../../static/images/icons/popup_close.svg';
import AreasField from './areas-field';

export interface AddEditProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  getBranches: () => void;
}
export interface SelectedArea {
  value?: any;
  label?: string;
}
const AddBranch: React.FunctionComponent<AddEditProps> = (props) => {
  const { setShowModal, getBranches } = props;

  const [formData, setFormData] = useState({
    branchAddress: '',
    branchName: '',
    areaId: '',
    lat: '',
    lng: '',
  });

  const [selectedArea, setSelectedArea] = useState<SelectedArea | null>();
  const [isDisableBtn, setIsDisableBtn] = useState<boolean>(false);
  const handleCreateForm = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const submitCreateBranch = (e) => {
    e.preventDefault();
    setIsDisableBtn(true);
    EntityProfile.createNewBranch({
      branchTitle: formData.branchName,
      addressName: formData.branchAddress,
      lat: formData.lat,
      lng: formData.lng,
      areaId: selectedArea?.value,
    })
      .then(() => {
        getBranches();
        setShowModal(false);
        Toastr.success('Branch added successfully');
        setIsDisableBtn(false);
      })
      .catch((err) => {
        console.error(err);
        setIsDisableBtn(false);
      });
  };

  function SubmitButton() {
    if (formData.branchAddress && formData.branchName && formData.lat && formData.lng) {
      return (
        <button className="btn btn--green" type="submit" onClick={submitCreateBranch} disabled={isDisableBtn}>
          Save
        </button>
      );
    } else {
      return (
        <button disabled className="btn btn--disabled" type="submit">
          Save
        </button>
      );
    }
  }

  return (
    <div className="add-branch-wrapper">
      <div className="adding-head">
        <h4>Add New Branch</h4>
        <img src={CloseModal} alt="close model" onClick={() => setShowModal(false)} />
      </div>

      <hr />
      <div className="add-branch-wrapper__form">
        <div className="form-multi-fields">
          <div className="field-container">
            <label htmlFor="branchName">
              Branch name <span className="required-field"> *</span>
            </label>
            <input
              type="text"
              id="branchName"
              name="branchName"
              onChange={handleCreateForm}
              placeholder="Branch name here"
            />
          </div>
          <div className="field-container">
            <label htmlFor="branchArea">
              Branch area <span className="required-field"> *</span>
            </label>
            <AreasField selectedArea={selectedArea} setSelectedAreas={setSelectedArea} />
          </div>
        </div>
        <div className="form-full-width">
          <div className="field-container">
            <label htmlFor="branchAddress">
              Branch address <span className="required-field"> *</span>
            </label>
            <input
              type="text"
              id="branchAddress"
              name="branchAddress"
              onChange={handleCreateForm}
              placeholder="Full Address here"
            />
          </div>
        </div>
        <div className="form-multi-fields">
          <div className="field-container">
            <label htmlFor="lat">
              Location Latitude <span className="required-field"> *</span>
            </label>
            <input type="text" id="lat" name="lat" onChange={handleCreateForm} placeholder="Enter latitude here" />
          </div>
          <div className="field-container">
            <label htmlFor="lng">
              Location longitude <span className="required-field"> *</span>
            </label>
            <input type="text" id="lng" name="lng" onChange={handleCreateForm} placeholder="Enter longitude here" />
          </div>
        </div>
      </div>
      {/* <button className="btn btn--green" type="submit" onClick={(e) => submitCreateBranch(e)}>
        Save
      </button> */}
      <SubmitButton />
    </div>
  );
};

export default AddBranch;
