import React, { useState, useContext, useEffect } from 'react';
import { Formik, Form, Field, FormikState } from 'formik';
import Toastr from 'toastr';
import { TEACHER_LOGIN_SCHEMA } from '../../../utilities/teacher-authentication';
import { TeacherLoginInput } from '../../../definitions/interfaces/inputs/teacher-login';
import { TeacherAuthentication as TeacherAuthenticationUtilities } from '../../../utilities/teacher-authentication';
import { UserType } from '../../../definitions/interfaces/common';
import { USER_CONTEXT } from '../../../contexts/user-context';
import { ROUTES } from 'src/ts/definitions/consts/routes';
import { useHistory } from 'react-router-dom';

const TeacherLogin: React.FunctionComponent = () => {
  const userContext = useContext(USER_CONTEXT);
  const [showPassword, setShowPassword] = useState(false);
  const [isDisableBts, setIsDisableBtns] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (userContext.user && userContext.type === UserType.entity) {
      history.push(ROUTES.entityDashboard.getPath('Dashboard'));
    }
    if (userContext.user && userContext.type === UserType.student) {
      history.push(ROUTES.userDashboard.path);
    }
    if (userContext.user && userContext.type === UserType.tutor) {
      history.push(ROUTES.tutorJobs.path);
    }
    if (userContext.user && userContext.type === UserType.teacher) {
      history.push(ROUTES.presenterDashboard.getPath('Dashboard'));
    }
  }, [userContext]);

  const handleSubmit = (
    values: TeacherLoginInput,
    resetForm: (
      nextState?: Partial<
        FormikState<{
          email: string;
          password: string;
        }>
      >
    ) => void
  ) => {
    setIsDisableBtns(true);
    TeacherAuthenticationUtilities.presenterLogin(values)
      .then((response) => {
        if (userContext?.updateUser) {
          userContext.updateUser(response?.data, UserType.teacher);
        }
        Toastr.success('Successfully logged in');
        resetForm();
        history.push({
          pathname: `${ROUTES.presenterDashboard.getPath('Dashboard')}`,
        });
      })
      .catch((error) => {
        Toastr.error(error);
        console.error(error);
        setIsDisableBtns(false);
      });
  };

  return (
    <div className="login__form-wrapper">
      <h1>Teacher</h1>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        enableReinitialize={true}
        validationSchema={TEACHER_LOGIN_SCHEMA}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, resetForm);
        }}
      >
        {({ errors, touched }) => (
          <Form className="login__form login__form--entity">
            <div className="input-wrapper">
              <label htmlFor="email">Email</label>
              <Field type="email" name="email" placeholder="Your email here" />
              {errors.email && touched.email ? <div className="login__error">{errors.email}</div> : null}
            </div>

            <div className="input-wrapper">
              <label htmlFor="password">Password</label>
              <Field type={showPassword ? 'text' : 'password'} name="password" placeholder="Your password here" />
              {errors.password && touched.password ? <div className="login__error">{errors.password}</div> : null}
            </div>

            <div className="input-wrapper">
              <label htmlFor="checked" className="show-pass-input">
                <Field
                  type="checkbox"
                  name="checked"
                  value="Show password"
                  checked={showPassword}
                  onChange={() => {
                    const updatedCheckValue = !showPassword;
                    setShowPassword(updatedCheckValue);
                  }}
                />
                Show password
              </label>
            </div>
            {/* //TODO: add loader */}
            <button className="login__form-btn btn btn--green" type="submit" disabled={isDisableBts}>
              Login
            </button>
          </Form>
        )}
      </Formik>

      <div className="login__forget-password-container">
        <h3 className="login__forget-password-title">Forgot you password? Contact Orcas at</h3>
        <p className="login__forget-password-email">partners@orcas.io</p>
      </div>
    </div>
  );
};
export default TeacherLogin;
