import React, { useState, useEffect } from 'react';
import CloseModalIcon from '../../../../../static/images/icons/popup_close.svg';
import { Formik, Form, Field, FormikState } from 'formik';
import { AddTeacherBody, singleTeacher } from '../../../../definitions/interfaces/teacher';
import { object, string } from 'yup';
import ImageUploader from 'src/ts/react-components/common/image-uploader';
import { EntityTeacher } from 'src/ts/api/entity-teacher';
import Toastr from 'src/ts/react-components/common/toastr';
import SelectField from 'src/ts/react-components/common/select-field';

export interface editTeacherProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  getAllTeachers: () => void;
  teacherId: string;
}

/**
 * schema to validate add teacher form.
 */
export const EDIT_TEACHER_SCHEMA = object().shape({
  firstName: string().required('Please add teacher first name'),
  lastName: string().required('Please add teacher last name'),
  title: string().required('Please add teacher title'),
  email: string().required('Please add teacher email'),
  // password: string().required('Please add teacher password'),
});

const selectOptions = [
  { value: 'mr', label: 'Mr' },
  { value: 'mrs', label: 'Mrs' },
  { value: 'dr', label: 'Dr' },
  { value: 'prof', label: 'Prof' },
];

const EditTeacher: React.FunctionComponent<editTeacherProps> = ({
  setShowModal,
  teacherId,
  getAllTeachers,
}: editTeacherProps) => {
  const [teacher, setTeacher] = useState<singleTeacher | null>(null);
  const [serverError, setServerErrors] = useState<string>('');
  const [selectedTitletOption, setSelectedTitleOption] = useState<{
    value: string;
    label?: string;
  } | null>(null);
  const [isloading, setIsLoading] = useState(false);

  // if (teacherId.length > 0) {
  useEffect(() => {
    EntityTeacher.getEntityTeacherById(teacherId).then((response) => {
      setTeacher(response.data);
      setSelectedTitleOption({
        value: response.data.title,
        label: response.data.title,
      });
    });
  }, []);
  // }

  const handelEditTeacher = (
    values: AddTeacherBody,
    resetForm: (nextState?: Partial<FormikState<AddTeacherBody>>) => void
  ) => {
    // e.preventDefault();
    const editTeacherInput: AddTeacherBody = {
      firstName: values.firstName,
      lastName: values.lastName,
      image: values.image.toString().split(',')[1],
      title: values.title,
      email: values.email,
      password: values.password,
    };
    EntityTeacher.editEntityTeacher(teacher.presenterId, editTeacherInput)
      .then(() => {
        getAllTeachers();
        setShowModal(false);
        Toastr.success('Updated Teacher Successfully');
        setServerErrors('');
        resetForm();
        setIsLoading(false);
      })
      .catch((errors) => {
        setServerErrors(errors);
        console.error(errors);
        setIsLoading(false);
      });
  };
  return (
    <div className="add-teacher">
      <div className="add-teacher__header">
        <h1 className="add-teacher__header-title">Update Teacher</h1>
        <button
          type="button"
          className="add-teacher__header-button"
          onClick={() => {
            setShowModal(false);
          }}
        >
          <img className="add-teacher__header-image" src={CloseModalIcon} alt="" />
        </button>
      </div>
      <div className="add-teacher__body">
        <Formik
          initialValues={{
            title: teacher ? teacher.title : '',
            firstName: teacher ? teacher.firstName : '',
            lastName: teacher ? teacher.lastName : '',
            image: teacher ? teacher.image : '',
            email: teacher ? teacher.email : '',
            password: '',
          }}
          enableReinitialize={true}
          validationSchema={EDIT_TEACHER_SCHEMA}
          onSubmit={(values, { resetForm }) => {
            handelEditTeacher(values, resetForm);
          }}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form className="edit-teacher__form">
              <div className="form-inputs-wrapper">
                <div className="input-wrapper image-input-wrapper">
                  <ImageUploader
                    image={values.image}
                    name="teacher"
                    onChange={(newImage) => {
                      setFieldValue('image', newImage);
                    }}
                  />
                </div>
                <div className="add-teacher__form-inputs-container">
                  <div className="add-teacher__upper-form-inputs">
                    <div className="add-class__form-time">
                      <label htmlFor="title">
                        Teacher title <span className="required-field"> *</span>
                      </label>
                      <SelectField
                        title="title"
                        showTitle={false}
                        isSearchable={true}
                        placeholder="Choose title"
                        optionValues={selectOptions ?? []}
                        onChange={(title) => {
                          setSelectedTitleOption(title);
                          setFieldValue('title', title.value);
                        }}
                        selectedOption={selectedTitletOption}
                      />
                      {errors.title && touched.title ? <div className="edit-teacher-error">{errors.title}</div> : null}
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="firstName">
                        Teacher first name <span className="required-field"> *</span>
                      </label>
                      <Field type="firstName" name="firstName" placeholder="Teacher first name here" />
                      {errors.firstName && touched.firstName ? (
                        <div className="add-teacher-error">{errors.firstName}</div>
                      ) : null}
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="lastName">
                        Teacher last name <span className="required-field"> *</span>
                      </label>
                      <Field type="lastName" name="lastName" placeholder="Teacher last name here" />
                      {errors.lastName && touched.lastName ? (
                        <div className="edit-teacher-error">{errors.lastName}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="add-teacher__down-form-inputs">
                    <div className="input-wrapper">
                      <label htmlFor="email">
                        Teacher email <span className="required-field"> *</span>
                      </label>
                      <Field type="email" name="email" placeholder="Teacher email here" />
                      {errors.email && touched.email ? <div className="add-teacher-error">{errors.email}</div> : null}
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="password">
                        Teacher password <span className="required-field"> *</span>
                      </label>
                      <Field
                        inputprops={{
                          autoComplete: 'password',
                          form: {
                            autoComplete: 'off',
                          },
                        }}
                        type="password"
                        name="password"
                        placeholder="Teacher password here"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="edit-teacher-error edit-teacher-error--server">{serverError}</div>
              <div className="btn-wrapper">
                <button
                  className="edit-teacher__form-btn btn btn--green"
                  disabled={isloading ? true : false}
                  type="submit"
                >
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditTeacher;
