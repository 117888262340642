import React from 'react';

interface HamburgerProps {
  className: any;
  onClick: any;
}
const Hamburger: React.FunctionComponent<HamburgerProps> = (props) => {
  const { className, onClick } = props;

  return (
    <button onClick={onClick} className={className}>
      <span className="hamburger__box">
        <span className="hamburger__inner" />
      </span>
    </button>
  );
};
export default Hamburger;
