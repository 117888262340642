import React, { useEffect, useState } from 'react';
import ReviewLogo from '../../../../../static/images/icons/review_stars.svg';
import CurrentClass from '../../../../../static/images/icons/current_class.svg';
import ReadingLogo from '../../../../../static/images/icons/reading.svg';
import { Session as SessionUtilities } from '../../../../utilities/session';

import Pagination from 'src/ts/react-components/common/pagination';
import { CourseBody } from '../../../../definitions/interfaces/entity-courses-listing';
import { ROUTES } from '../../../../definitions/consts/routes';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { capitalizeFirstLetter } from 'src/ts/utilities/common';
import Loader from 'src/ts/react-components/common/loader';
import { EntityCourse as EntityCourseUtilities } from '../../../../utilities/entity-course';
import { Subject as SubjectInterface } from 'src/ts/definitions/interfaces/subject';
import { SchoolSystem as SchoolSystemInterface } from 'src/ts/definitions/interfaces/entity-course';
import Toastr from 'src/ts/react-components/common/toastr';
import filterIcon from 'src/static/images/filterIcon.svg';
import searchIcon from 'src/static/images/ic_search.svg';
import { Formik, Form, Field } from 'formik';
import openCollapse from 'src/static/images/icons/ic_filter_expand.svg';
import closeCollapse from 'src/static/images/icons/ic_filter_collapse.svg';
import close_filter from 'src/static/images/icons/close_filter.svg';
import { withRouter } from 'react-router-dom';
import { formate } from 'src/ts/utilities/moment-formate-Date';
import placeHolderImg from 'src/static/images/placeholder.png';

const Courses: React.FunctionComponent = () => {
  const [courses, setCourses] = useState<CourseBody[] | null>(null);
  const [loadingCourses, setLoadingCourses] = useState<boolean>(false);
  const [currentCourses, setcurrentCourses] = useState(0);
  const [coursesPerPage] = useState(10);
  const [isLastPage, setisLastPage] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  // const url = new URL(location.search);
  const params = new URLSearchParams(location?.search);

  const [keyword, setKeyword] = useState<string>(params.get('query') || '');
  const [typing, setTyping] = useState<boolean>();
  //
  const [schoolSystems, setSchoolSystems] = useState<SchoolSystemInterface[] | null>(null);
  const [subjectsData, setSubjectsData] = useState<SubjectInterface[]>([]);
  const [searchBody, setSearchBody] = useState({
    subjects: [],
    publishingStatus: [],
    gradeIds: [],
  });

  useEffect(() => {
    setLoadingCourses(true);

    if (location.search) {
      const inputState = params.get('q-state');
      setIsSearchOpen(inputState == 'open' ? true : false);
      history.push({ search: params.toString() });
    }

    // history.push({ search: params.toString() });

    async function getCourses() {
      await SessionUtilities.getAllCourses(currentCourses, coursesPerPage, { ...searchBody, keyword })
        .then((response) => {
          setLoadingCourses(false);
          if (response.length > 0) {
            setCourses(response);
            setisLastPage(false);
          } else {
            setisLastPage(true);
          }
        })
        .catch((error) => {
          console.error(error);
          setCourses(null);
        });
    }
    getCourses();
  }, [currentCourses, history]);

  // Change page
  const paginate = (pageNumber) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    setcurrentCourses(pageNumber);
  };
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);

  //call Api get subjects and school systems
  useEffect(() => {
    //fetch Course details based on id
    EntityCourseUtilities.getAllSubjects()
      .then((response) => {
        setSubjectsData(response);
      })
      .catch((error: any) => {
        Toastr.error(error);
        console.error(error);
      });

    EntityCourseUtilities.getAllSchoolSystems()
      .then((response) => {
        setSchoolSystems(response);
      })
      .catch((error: any) => {
        Toastr.error(error);
        console.error(error);
      });
  }, []);

  async function setParamsToFilters(values) {
    await callApi(values);
    await history.push({ search: params.toString() });
  }
  const handleSubmitSearch = async (values) => {
    setSearchBody(values);
    setParamsToFilters(values);
    setCourses([]);
    setLoadingCourses(true);
  };

  const callApi = (values) => {
    SessionUtilities.getAllCourses(currentCourses, coursesPerPage, { ...values, keyword })
      .then((response) => {
        setLoadingCourses(false);
        if (response.length > 0) {
          setCourses(response);
          setisLastPage(false);
        } else {
          setisLastPage(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setCourses(null);
      });
  };

  useEffect(() => {
    let delayDebounceFn;
    if (typing) {
      delayDebounceFn = setTimeout(() => {
        handleSubmitSearch(searchBody);
      }, 3000);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [keyword]);

  const schoolSystemIds = [];
  const gradeIds = [];

  if (schoolSystems) {
    for (const schoolSystem of schoolSystems) {
      schoolSystemIds.push(schoolSystem.id);
      for (const grade of schoolSystem.grades) {
        gradeIds.push(grade.id);
      }
    }
  }

  const schoolSystemsOptions = schoolSystems?.map((schoolSystem) => {
    return {
      label: schoolSystem.name,
      options: schoolSystem.grades.map((g) => {
        return {
          value: g.id,
          label: g.name,
        };
      }),
    };
  });
  const subjectOptions = subjectsData?.map((subject) => {
    return {
      value: subject.groupFocusId,
      label: subject.groupFocusName,
    };
  });
  const inputRef = React.useRef(null);
  const [showGrades, setShowGrades] = useState(false);
  const [showSubjects, setShowSubjects] = useState(false);
  const [publishState, setPublishState] = useState(false);

  const resetForm = () => {
    setSearchBody({ subjects: [], publishingStatus: [], gradeIds: [] });
  };

  const handleChangeSearch = (e) => {
    setTyping(true);
    setKeyword(e.target.value);
    params.set('query', e.target.value);
    history.push({ search: params.toString() });
  };

  // let url =
  const handleGoCourseDetails = (courseId) => {
    history.replace({
      pathname: `${ROUTES.entityCourse.getPath && ROUTES.entityCourse.getPath(courseId)}`,
      search: location.search,
    });
  };

  const handleCloseFilters = () => {
    setIsFiltersOpen(false);
    // if (!searchBody.subjects.length) setShowSubjects(false);
    setShowSubjects(false);
    // if (!searchBody.gradeIds.length) setShowGrades(false);
    setShowGrades(false);
    // if (!searchBody.publishingStatus.length) setPublishState(false);
    setPublishState(false);
  };

  return (
    <>
      <div className="courses-title">
        <h2 className="section-title">My Courses</h2>
        <div className="section-search_btn">
          <div className="section-search">
            <div
              className={isSearchOpen ? 'search-input move-img' : 'search-input'}
              onClick={() => {
                setIsSearchOpen(true);
                params.set('q-state', 'open');
                history.push({ search: params.toString() });
                inputRef.current.focus();
              }}
            >
              <input
                className={`${isSearchOpen ? 'rounded-icon rounded-icon-open' : 'rounded-icon'}`}
                type="text"
                placeholder={`${isSearchOpen ? 'Search by teachers or course name' : ''}`}
                value={keyword}
                // onChange={handleOnChange}
                onChange={handleChangeSearch}
                autoComplete="off"
                ref={inputRef}
              />
              <div className="img-search-icon">
                <img src={searchIcon} alt="search" />
              </div>
            </div>
            <div className="filtersIcon" onClick={() => setIsFiltersOpen(!isFiltersOpen)}>
              <span className="rounded-item">
                <img src={filterIcon} alt="filters" />
              </span>
            </div>
          </div>
          <Link className="btn btn--green add-new-course" to={ROUTES.addEntityCourse.path}>
            <span>+ </span>
            <p> Add new course</p>
          </Link>
        </div>
      </div>
      <div className="courses-filters">
        <div className="coursesResult">
          {loadingCourses ? (
            <Loader />
          ) : (
            courses &&
            courses?.map((item) => {
              return (
                <div
                  onClick={() => {
                    handleGoCourseDetails(item.course.id);
                  }}
                  key={item.course.id}
                  className="course-card"
                >
                  <div className="course-card__image">
                    <img src={item.course.image} alt="Course Image" />
                  </div>
                  <div className="course-card__container">
                    <div className="course-card__content">
                      <div className="course-card__course-details">
                        <p className="course-card__creation-date">Created date {formate(item.course.createdAt)}</p>
                        <h2 className="course-card__name">{item.course.title}</h2>
                        <p className="course-card__description text-initial">
                          {`${item.course.groupFocusValue} | ${item.course?.schoolSystems?.map((system) => {
                            return (
                              ' ' +
                              system?.name +
                              ' ' +
                              `(${system?.grades?.map((grade) => {
                                return grade?.name + ' ';
                              })})`
                            );
                          })}`}
                        </p>
                        <div className="course-card__review">
                          <img src={ReviewLogo} alt="Review Icon" />
                          <span>
                            {item.course?.reviewSummary?.rate} ({item.course?.reviewSummary?.count}{' '}
                            {item.course?.reviewSummary?.count > 1 ? 'Learners' : 'Learner'})
                          </span>
                        </div>
                      </div>

                      <div className="course-card__course-info">
                        <div className="course-card__author-details">
                          <img className="author-image" src={item.presenter.image || placeHolderImg} alt="" />
                          <span>
                            by {`${capitalizeFirstLetter(item.presenter.title)}.`} {item.presenter.firstName} &nbsp;
                            {item.presenter.lastName}
                          </span>
                        </div>
                        <div className="course-card__class-details">
                          <img className="class-icon" src={CurrentClass} alt="Class Icon" />
                          <span>Current class: {item.course.currentClassName}</span>
                        </div>
                        <div className="course-card__booking-details">
                          <img className="booking-icon" src={ReadingLogo} alt="Booking Icon" />
                          <span>{item.course.capacity.fullBookingCount} Learner has booked</span>
                          <span className="max-booking">Max ({item.course.capacity.perClass})</span>
                        </div>
                      </div>
                    </div>
                    <div className="course-card__course-labels">
                      <div className="label">
                        <p>{item.course.type.name}</p>
                      </div>
                      <div
                        className={
                          'label ' + (item.course.status.name === 'unpublished' ? 'red-border' : 'green-border')
                        }
                      >
                        <p style={{ color: item.course.status.name === 'unpublished' ? '#F85353' : '#13C19F' }}>
                          {item.course.status.name === 'published' ? 'Available For Booking' : 'Not Published'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
          {courses?.length ? (
            <Pagination
              currentItemsLength={courses.length}
              currentDisplayedCourses={currentCourses}
              itemPerPage={coursesPerPage}
              paginate={paginate}
              isLastPage={isLastPage}
            />
          ) : (
            ''
          )}
        </div>
        {isFiltersOpen && (
          <>
            <div className="filters">
              <div className="filters__heading">
                <h3>Filter by</h3>
                <img src={close_filter} alt="close" className="close" onClick={handleCloseFilters} />
              </div>
              <div className="filters__filters-options">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    gradeIds: searchBody.gradeIds || [],
                    publishingStatus: searchBody.publishingStatus || [],
                    subjects: searchBody.subjects || [],
                  }}
                  onSubmit={handleSubmitSearch}
                >
                  {() => (
                    <Form className="add-class__form">
                      <div className="filters__grades-school-system">
                        <div className="school-system-wrapper">
                          <div className="add-class__select-school-system">
                            <div className="filters__publish-title" onClick={() => setShowGrades(!showGrades)}>
                              <span>Grade/School system</span>
                              <img className="publish-arrow" src={showGrades ? closeCollapse : openCollapse} />
                            </div>
                            {showGrades && (
                              <div className="add-class__select-subject">
                                {schoolSystemsOptions.map((item) => (
                                  <div key={item?.label}>
                                    <h4>{item.label}</h4>

                                    {item.options.map((item) => (
                                      <div className="checkbox-container" key={item.value}>
                                        <Field
                                          type="checkbox"
                                          name="gradeIds"
                                          value={item.value.toString()}
                                          // id={item.value}
                                        />
                                        <label htmlFor="grades">{item.label}</label>
                                      </div>
                                    ))}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="filters__subject">
                        <div className="add-class__subjects-and-systems input-wrapper">
                          <div className="filters__content">
                            <div className="filters__publish-title" onClick={() => setShowSubjects(!showSubjects)}>
                              <span> Subject</span>
                              <img className="publish-arrow" src={showSubjects ? closeCollapse : openCollapse} />
                            </div>
                            {showSubjects && (
                              <div className="add-class__select-subject">
                                {subjectOptions.map((item) => (
                                  <div className="checkbox-container" key={item?.value}>
                                    <Field
                                      type="checkbox"
                                      name="subjects"
                                      value={item.value.toString()}
                                      // id="subjects"
                                    />
                                    <label htmlFor="subjects">{item.label}</label>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="filters__publish-status">
                          <div className="filters__publish-title" onClick={() => setPublishState(!publishState)}>
                            <span>Published Status</span>
                            <img className="publish-arrow" src={publishState ? closeCollapse : openCollapse} />
                          </div>

                          {publishState && (
                            <>
                              <div className="checkbox-container">
                                <Field type="checkbox" name="publishingStatus" htmlFor="puplished" value="1" />
                                <label htmlFor="puplished">Published</label>
                              </div>
                              <div className="checkbox-container">
                                <Field type="checkbox" name="publishingStatus" value="2" />
                                <label htmlFor="notpuplished">Not published</label>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="filters-btns">
                        <button type="reset" className="btn btn--greenLight" onClick={resetForm}>
                          Reset
                        </button>
                        <button
                          className="add-class__form-btn btn btn--green"
                          type="submit"
                          // disabled={isloading ? true : false}
                        >
                          Apply
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default withRouter(Courses);
