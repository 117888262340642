/**
 * @author Salma hefnawy
 * @date 2021-08-30
 * @description declaration of modal component.
 * @filename modal.tsx
 */
import React, { useEffect, useRef } from 'react';

interface ModalProps {
  showModal?: boolean;
  children: React.ReactNode;
  onClose?: () => void;
  modalExtraClass?: string;
}
const Modal: React.FC<ModalProps> = ({ children, showModal, onClose, modalExtraClass }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const modalRefContainer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (showModal) {
      if (modalRef && modalRef.current) {
        modalRef.current.classList.add('visible');
      }
    } else {
      if (modalRef && modalRef.current) {
        modalRef.current.classList.remove('visible');
      }
    }
  }, [showModal]);

  return (
    <React.Fragment>
      <div ref={modalRef} className={`modal__wrap ${modalExtraClass}`} onClick={onClose}>
        <div
          ref={modalRefContainer}
          className="modal__container"
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Modal;
