/**
 * @author Raghda Wessam
 * @date 2021-08-10
 * @description implementation of endpoints in the api.
 * @filename endpoints.ts
 */

/**
 * set of endpoints in the API.
 */

//this is const across all auth endpoints
const endpointsAuthPrefix = '/api_v4/user/auth';

const endpointWebsitePrefix = '/web-api-v1/user';

const endpointEntityPrefix = '/web-api-v1/organizations';
const endpointsEntityAuthPrefix = '/web-api-v1/organizations/auth';
const endpointsEntityCoursesPrefix = '/web-api-v1/organizations/courses';

const endpointTutorPrefix = '/api_v2/partner';
const endPointTutorDashboard = '/api_v3/partner/partners';

// teacher prefixes
const endpointPresenter = `/web-api-v1/presenters`;

export const ENDPOINTS = {
  login: {
    path: `${endpointsAuthPrefix}/login`,
    method: 'POST',
  },
  getUser: {
    path: `${endpointsAuthPrefix}/user`,
    method: 'GET',
  },
  logout: {
    path: `${endpointsAuthPrefix}/logout`,
    method: 'POST',
  },
  getLiveNowSessions: {
    path: `${endpointWebsitePrefix}/sessions/live`,
    method: 'GET',
  },
  refreshToken: {
    path: `${endpointsAuthPrefix}/token/refresh`,
    method: 'POST',
  },
  getUnconfirmedCourses: {
    path: `${endpointWebsitePrefix}/sessions/not-confirmed`,
    method: 'GET',
  },
  updateAttendance: {
    path: `${endpointWebsitePrefix}/sessions/attendance`,
    method: 'PUT',
  },
  addClassReview: {
    path: `${endpointWebsitePrefix}/sessions/review`,
    method: 'POST',
  },
  joinOnlineSession: {
    path: `${endpointWebsitePrefix}/online/sessions/join`,
    method: 'POST',
  },
  joinOnlineCourse: {
    path: `${endpointWebsitePrefix}/online/courses/join`,
    method: 'POST',
  },
  leaveClassroom: {
    url: (sessionId: string): string => `${endpointWebsitePrefix}/online/sessions/${sessionId}/leave`,
    method: 'GET',
  },
  getLearnerCourseDetails: {
    url: (courseId: string): string => `${endpointWebsitePrefix}/courses/${courseId}/details`,
    method: 'GET',
  },
  getLearnerLessonDetails: {
    url: (courseId: string): string => `${endpointWebsitePrefix}/courses/${courseId}/details/lessons`,
    method: 'GET',
  },
  getEntityCourseDetails: {
    url: (courseId: string): string => `${endpointEntityPrefix}/courses/${courseId}`,
    method: 'GET',
  },
  getEntityCourseLessons: {
    url: (courseId: string): string => `${endpointEntityPrefix}/courses/${courseId}/lessons`,
    method: 'GET',
  },

  addEntityClass: {
    url: (courseId: string, lessonId: string): string =>
      `${endpointEntityPrefix}/courses/${courseId}/lessons/${lessonId}/classes/create`,
    method: 'POST',
  },
  editEntityClass: {
    url: (courseId: string, lessonId: string, classId: string): string =>
      `${endpointEntityPrefix}/courses/${courseId}/lessons/${lessonId}/classes/${classId}/edit`,
    method: 'PUT',
  },
  addEntityLesson: {
    url: (courseId: string): string => `${endpointEntityPrefix}/courses/${courseId}/lessons/create`,
    method: 'POST',
  },
  editEntityLesson: {
    url: (courseId: string, lessonId: string): string =>
      `${endpointEntityPrefix}/courses/${courseId}/lessons/${lessonId}/edit`,
    method: 'PATCH',
  },
  deleteEntityLesson: {
    url: (courseId: string, lessonId: string): string =>
      `${endpointEntityPrefix}/courses/${courseId}/lessons/${lessonId}/delete`,
    method: 'DELETE',
  },
  getEntityClass: {
    url: (courseId: string, lessonId: string, classId: string): string =>
      `${endpointEntityPrefix}/courses/${courseId}/lessons/${lessonId}/classes/${classId}`,
    method: 'GET',
  },
  entityLogin: {
    path: `${endpointsEntityAuthPrefix}/login`,
    method: 'POST',
  },
  entityLogout: {
    path: `${endpointsEntityAuthPrefix}/logout`,
    method: 'POST',
  },
  refreshEntityToken: {
    path: `${endpointsEntityAuthPrefix}/token/refresh`,
    method: 'POST',
  },
  getEntity: {
    path: `${endpointsEntityAuthPrefix}/organization`,
    method: 'GET',
  },
  getAllCourses: {
    path: (offset: number, limit: number): string =>
      `${endpointEntityPrefix}/courses/all?offset=${offset}&limit=${limit}`,
    method: 'POST',
  },

  updatePublishCourseStatus: {
    url: (courseId: string): string => `${endpointEntityPrefix}/courses/${courseId}/publish-status`,
    method: 'PATCH',
  },
  addEntityCourse: {
    url: `${endpointEntityPrefix}/courses/create`,
    method: 'POST',
  },
  editEntityCourse: {
    url: (courseId: string): string => `${endpointEntityPrefix}/courses/${courseId}/edit`,
    method: 'PUT',
  },
  getAllSubjects: {
    path: `${endpointEntityPrefix}/lookups/subjects`,
    method: 'GET',
  },
  getAllSchoolSystems: {
    path: `${endpointEntityPrefix}/lookups/school-systems`,
    method: 'GET',
  },
  getAllPresenters: {
    path: `${endpointEntityPrefix}/lookups/presenters`,
    method: 'GET',
  },
  getAllBranches: {
    path: `${endpointEntityPrefix}/lookups/branches`,
    method: 'GET',
  },
  getAllTeachers: {
    url: `${endpointEntityPrefix}/presenters/all?offset=0&limit=1000`,
    method: 'GET',
  },
  deleteTeacher: {
    url: (teacherId: string): string => `${endpointEntityPrefix}/presenters/${teacherId}/delete`,
    method: 'DELETE',
  },
  getUpcommingClasses: {
    url: (offset: number, limit: number): string =>
      `${endpointsEntityCoursesPrefix}/upcoming?offset=${offset}&limit=${limit}`,
    method: 'GET',
  },
  getUnconfirmedClasses: {
    url: (offset: number, limit: number): string =>
      `${endpointsEntityCoursesPrefix}/unconfirmed?offset=${offset}&limit=${limit}`,
    method: 'GET',
  },
  confirmClass: {
    path: `${endpointsEntityCoursesPrefix}/complete-class`,
    method: 'PATCH',
  },
  getEntityBranches: {
    url: (offset: number, limit: number): string =>
      `${endpointEntityPrefix}/branches/all?offset=${offset}&limit=${limit}`,
    method: 'GET',
  },
  deleteBranch: {
    url: (branchId: string): string => `${endpointEntityPrefix}/branches/${branchId}/delete`,
    method: 'DELETE',
  },
  getBranchById: {
    url: (branchId: string): string => `${endpointEntityPrefix}/branches/${branchId}`,
    method: 'GET',
  },
  editeBranch: {
    url: (branchId: string): string => `${endpointEntityPrefix}/branches/${branchId}/edit`,
    method: 'PUT',
  },
  createNewBranch: {
    path: `${endpointEntityPrefix}/branches/create`,
    method: 'POST',
  },
  startOnlineClass: {
    url: (courseId: string, lessonId: string, classId: string): string =>
      `${endpointsEntityCoursesPrefix}/${courseId}/lessons/${lessonId}/classes/${classId}/join`,
    method: 'POST',
  },
  addEntityTeacher: {
    url: `${endpointEntityPrefix}/presenters/create`,
    method: 'POST',
  },
  editEntityTeacher: {
    url: (teacherId: string): string => `${endpointEntityPrefix}/presenters/${teacherId}/edit`,
    method: 'PUT',
  },
  getEntityTeacherById: {
    url: (teacherId: string): string => `${endpointEntityPrefix}/presenters/${teacherId}`,
    method: 'GET',
  },
  getAllCityAreas: {
    path: `${endpointEntityPrefix}/lookups/cities?`,
    method: 'GET',
  },
  // tutor
  tutorLogin: {
    path: `${endpointTutorPrefix}/login`,
    method: 'POST',
  },
  tutorLogout: {
    path: `${endpointTutorPrefix}/logout`,
    method: 'POST',
  },
  refreshTutorToken: {
    path: `${endpointTutorPrefix}/refresh-token`,
    method: 'POST',
  },
  getTutor: {
    path: `${endpointTutorPrefix}/partner-data`,
    method: 'GET',
  },
  getTutorJobs: {
    path: `${endPointTutorDashboard}/jobs?offset=0&limit=1000&type=current`,
    method: 'GET',
  },
  joinClassRoomTutor: {
    path: `${endPointTutorDashboard}/online/sessions/create`,
    method: 'POST',
  },
  loginPresenter: {
    path: `${endpointPresenter}/auth/login`,
    method: 'POST',
  },
  getPresenter: {
    path: `${endpointPresenter}/auth/presenter`,
    method: 'GET',
  },
  getPresenterCourses: {
    path: (offset: number, limit: number): string => `${endpointPresenter}/courses/all?offset=${offset}&limit=${limit}`,
    method: 'POST',
  },
  addPresenterCourse: {
    url: `${endpointPresenter}/courses/create`,
    method: 'POST',
  },
  editPresenterCourse: {
    url: (courseId: string): string => `${endpointPresenter}/courses/${courseId}/edit`,
    method: 'PUT',
  },
  addPresenterClass: {
    url: (courseId: string, lessonId: string): string =>
      `${endpointPresenter}/courses/${courseId}/lessons/${lessonId}/classes/create`,
    method: 'POST',
  },
  editPresenterClass: {
    url: (courseId: string, lessonId: string, classId: string): string =>
      `${endpointPresenter}/courses/${courseId}/lessons/${lessonId}/classes/${classId}/edit`,
    method: 'PUT',
  },
  getPresenterClass: {
    url: (courseId: string, lessonId: string, classId: string): string =>
      `${endpointPresenter}/courses/${courseId}/lessons/${lessonId}/classes/${classId}`,
    method: 'GET',
  },
  updatePublishCourseStatusPresenter: {
    url: (courseId: string): string => `${endpointPresenter}/courses/${courseId}/publish-status`,
    method: 'PATCH',
  },
  addPresenterLesson: {
    url: (courseId: string): string => `${endpointPresenter}/courses/${courseId}/lessons/create`,
    method: 'POST',
  },
  editPresenterLesson: {
    url: (courseId: string, lessonId: string): string =>
      `${endpointPresenter}/courses/${courseId}/lessons/${lessonId}/edit`,
    method: 'PATCH',
  },
  deletePresenterLesson: {
    url: (courseId: string, lessonId: string): string =>
      `${endpointPresenter}/courses/${courseId}/lessons/${lessonId}/delete`,
    method: 'DELETE',
  },
  getAllPresenterSubjects: {
    path: `${endpointPresenter}/lookups/subjects`,
    method: 'GET',
  },
  getAllPresenterSchoolSystems: {
    path: `${endpointPresenter}/lookups/school-systems`,
    method: 'GET',
  },
  getPresenterCourseDetails: {
    url: (courseId: string): string => `${endpointPresenter}/courses/${courseId}`,
    method: 'GET',
  },
  getPresenterUpcomming: {
    url: (offset: number, limit: number): string =>
      `${endpointPresenter}/courses/upcoming?offset=${offset}&limit=${limit}`,
    method: 'GET',
  },
  getPresenterUnconfirmedClasses: {
    url: (offset: number, limit: number): string =>
      `${endpointPresenter}/courses/unconfirmed?offset=${offset}&limit=${limit}`,
    method: 'GET',
  },
  getPresenterCourseLessons: {
    url: (courseId: string): string => `${endpointPresenter}/courses/${courseId}/lessons`,
    method: 'GET',
  },
  confirmPresenterClass: {
    path: `${endpointPresenter}/courses/complete-class`,
    method: 'PATCH',
  },
  startPresenterOnlineClass: {
    url: (courseId: string, lessonId: string, classId: string): string =>
      `${endpointPresenter}/courses/${courseId}/lessons/${lessonId}/classes/${classId}/join`,
    method: 'POST',
  },
  getAllPresenterBranches: {
    path: `${endpointPresenter}/lookups/branches`,
    method: 'GET',
  },
  presenterLogout: {
    path: `${endpointPresenter}/auth/logout`,
    method: 'POST',
  },
};
