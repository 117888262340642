/**
 * implementation of Common attributes across data model
 */
export const common = {
  //implementation of Common attributes across data model
};

export interface EntityCommonInterface<N extends unknown = string> {
  id: number;
  name: N;
}

export enum UserType {
  student = 'student',
  tutor = 'tutor',
  entity = 'entity',
  teacher = 'teacher',
}

export enum ClassType {
  Online = 1,
  Offline = 2,
}
