/**
 * @author Raghda Wessam
 * @date 2021-03-18
 * @description not found layout..
 * @filename not-found.tsx
 */
import React from 'react';

const NotFound: React.FunctionComponent = () => {
  return (
    <div>
      <h1>Url Not Found!</h1>
    </div>
  );
};

export default NotFound;
