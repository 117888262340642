/**
 * @author Raghda Wessam
 * @date 2021-08-10
 * @description user dashboard page.
 * @filename user-dashboard.tsx
 */
import React, { useContext, useEffect, useState } from 'react';
// import { CSSTransition } from 'react-transition-group';
import orderBy from 'lodash.orderby';
import { USER_CONTEXT } from '../../../contexts/user-context';
import Card, { CardProps } from '../../common/card';
import { Session as SessionUtilities } from '../../../utilities/session';
import { Session as SessionInterface } from '../../../definitions/interfaces/session';
import { Course as CourseInterface } from '../../../definitions/interfaces/course';

const UserDashboard: React.FunctionComponent = () => {
  const userContext = useContext(USER_CONTEXT);
  const [liveNowSessions, setLiveNowSessions] = useState<{
    sessions: SessionInterface[];
    courses: CourseInterface[];
  } | null>(null);
  // const [showCoursesReview, setShowCoursesReview] = useState(false);
  // const [unconfirmedCourses, setUnconfirmedCourses] = useState<UnconfirmedCoursesInterface | null>(null);
  let liveNowCards: CardProps[] | null = null;

  useEffect(() => {
    //get live now sessions
    if (userContext?.user) {
      SessionUtilities.getLiveNowSessions()
        .then((response) => {
          setLiveNowSessions(response);
        })
        .catch((error) => {
          console.error(error);
        });

      // SessionUtilities.getUnconfirmedCourses()
      //   .then((response) => {
      //     setUnconfirmedCourses(response);
      //     setShowCoursesReview(true);
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    }
  }, [userContext?.user]);

  if (liveNowSessions) {
    //merge courses and sessions
    const onlineSessionsCards: CardProps[] = liveNowSessions.sessions.map((session) => {
      return {
        sessionId: session?.job?.id,
        type: 'onlineSession',
        date: session?.sessionStartDate,
        title: `${session?.job?.focusValue} (${session?.job?.schoolSystem}) tutoring sessions by ${session?.partner?.name}`,
        image: session?.job?.focusValueImage,
        learners: `${session?.job?.learnersCount} learner(s)`,
        consumedHours: `${session?.job?.consumedMinutes / 60} out of ${session?.job?.totalMinutes / 60} hours consumed`,
      };
    });
    const coursesCards: CardProps[] = liveNowSessions.courses.map((course) => {
      return {
        courseId: course?.id,
        classId: course?.class?.id,
        type: 'courseSession',
        date: course?.class?.startedAt,
        title: `${course.title} Course by ${course?.presenter?.title} ${course?.presenter?.name}`,
        image: course?.image,
        lessonName: course?.class?.lessonName,
        className: course?.class?.name,
        canJoinOnline: course?.class?.canJoinOnline,
      };
    });

    //sort sessions according to date, course name and
    //type(display course sessions 1st if overlapping with online session time)
    liveNowCards = orderBy([...onlineSessionsCards, ...coursesCards], ['date', 'title', 'type'], ['asc', 'asc', 'asc']);
  }

  // const emptyUnconfirmedCourses = () => {
  //   setUnconfirmedCourses(null);
  // };

  //TODO: add loader
  return (
    <>
      {/* {unconfirmedCourses && unconfirmedCourses?.count > 0 ? (
        <CSSTransition in={showCoursesReview} timeout={500} classNames="courses-review-transitions" unmountOnExit>
          <CoursesReview unconfirmedCourses={unconfirmedCourses} onConfirmAllCourses={emptyUnconfirmedCourses} />
        </CSSTransition>
      ) : null} */}
      <h2 className="section-title">Live now</h2>
      <div className="cards cards--live">
        {liveNowCards?.map((session, index) => {
          return <Card key={index} {...session} />;
        })}
      </div>
    </>
  );
};

export default UserDashboard;
