import React, { useState } from 'react';
import { EntityProfile } from 'src/ts/api/entity-profile';

import Toastr from 'toastr';
import CloseModal from '../../../../../static/images/icons/popup_close.svg';
import AreasField from './areas-field';
export interface SelectedBranch {
  branchId: string;
  branchTitle: string;
  addressName: string;
  area: Area;
  lat: number;
  lng: number;
}
export interface EntityCommonInterface {
  areaId: number;
  areaNameEn: string;
  areaNameAr: string;
}

export interface EditProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedBranch: SelectedBranch;
  getBranches: () => void;
}
export interface Area {
  name: string;
  id: string;
}

const EditBranch: React.FunctionComponent<EditProps> = (props) => {
  const { setShowModal, selectedBranch, getBranches } = props;
  const [formData, setFormData] = useState({
    branchAddress: selectedBranch?.addressName,
    branchName: selectedBranch?.branchTitle,
    branchArea: selectedBranch?.area.name,
    lat: selectedBranch?.lat,
    lng: selectedBranch?.lng,
  });
  const [selectedArea, setSelectedArea] = useState({
    value: selectedBranch?.area.id,
    label: selectedBranch?.area.name,
  });

  const handleEditeBranch = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitEditBranch = (e) => {
    e.preventDefault();
    EntityProfile.editeBranch(
      {
        branchTitle: formData.branchName,
        addressName: formData.branchAddress,
        lat: formData.lat,
        lng: formData.lng,
        areaId: selectedArea.value,
      },
      selectedBranch.branchId
    ).then(() => {
      getBranches();
      setShowModal(false);
      Toastr.success('Branch Edited Successfully');
    });
  };

  return (
    <div className="add-branch-wrapper">
      <div className="adding-head">
        <h4>Add New Branch</h4>
        <img src={CloseModal} alt="close model" onClick={() => setShowModal(false)} />
      </div>

      <hr />

      <div className="add-branch-wrapper__form">
        <div className="form-multi-fields">
          <div className="field-container">
            <label htmlFor="branchName">
              Branch name <span className="required-field"> *</span>
            </label>
            <input
              type="text"
              id="branchName"
              name="branchName"
              defaultValue={selectedBranch?.branchTitle}
              onChange={handleEditeBranch}
              placeholder="Branch name here"
            />
          </div>
          <div className="field-container">
            <label htmlFor="branchArea">
              Branch area <span className="required-field"> *</span>
            </label>
            <div className="add-class__select-school-system">
              <AreasField selectedArea={selectedArea} setSelectedAreas={setSelectedArea} />
            </div>
          </div>
        </div>
        <div className="form-full-width">
          <div className="field-container">
            <label htmlFor="branchAddress">
              Branch address <span className="required-field"> *</span>
            </label>
            <input
              type="text"
              id="branchAddress"
              name="branchAddress"
              onChange={handleEditeBranch}
              defaultValue={selectedBranch?.addressName}
              placeholder="Full Address here"
            />
          </div>
        </div>
        <div className="form-multi-fields">
          <div className="field-container">
            <label htmlFor="lat">
              Location Latitude <span className="required-field"> *</span>
            </label>
            <input
              type="text"
              id="lat"
              name="lat"
              onChange={handleEditeBranch}
              defaultValue={selectedBranch?.lat}
              placeholder="Enter latitude here"
            />
          </div>
          <div className="field-container">
            <label htmlFor="lng">
              Location longitude <span className="required-field"> *</span>
            </label>
            <input
              type="text"
              id="lng"
              name="lng"
              defaultValue={selectedBranch?.lng}
              onChange={handleEditeBranch}
              placeholder="Enter longitude here"
            />
          </div>
        </div>
      </div>
      {/* <button className="btn btn--green" type="submit" onClick={(e) => submitCreateBranch(e)}>
        Save
      </button> */}
      <button className="btn btn--green" type="submit" onClick={submitEditBranch}>
        Update
      </button>
    </div>
  );
};

export default EditBranch;
