/* eslint-disable prettier/prettier */
import { Network } from './network';
import { Payload } from '../definitions/interfaces/payload';
import { ENDPOINTS } from './endpoints';
import { CreateNewBranch, EntityBranches } from '../definitions/interfaces/entity-branches';

export class EntityProfile {
  /**
   * fetch entity branches
   *
   * @static
   * @returns {Promise<Payload<Teacher>>}
   * @memberof  Entity profile
   */

  public static getAllBranches(offset: number, limit: number): Promise<Payload<EntityBranches[]>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getEntityBranches.url(offset, limit)}`, {
      method: ENDPOINTS.getEntityBranches.method,
    });
  }
  public static deleteBranch(branchId: string): Promise<Payload> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.deleteBranch.url(branchId)}`, {
      method: ENDPOINTS.deleteBranch.method,
    });
  }
  public static getBranchById(branchId: string): Promise<Payload> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getBranchById.url(branchId)}`, {
      method: ENDPOINTS.getBranchById.method,
    });
  }

  public static editeBranch(input: CreateNewBranch,branchId:string): Promise<Payload> {
    const body = {
      ...input,
    };
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.editeBranch.url(branchId)}`, {
      method: ENDPOINTS.editeBranch.method,
      body: JSON.stringify(body),
    });
  }
  public static createNewBranch(input: CreateNewBranch): Promise<Payload<EntityBranches[]>> {
    const body = {
      ...input,
    };
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.createNewBranch.path}`, {
      method: ENDPOINTS.createNewBranch.method,
      body: JSON.stringify(body),
    });
  }
  /**
   * Get all Areas
   *
   * @static
   * @returns {Promise<Payload<AreasLookup>>}
   * @memberof EntityProfile
   */
  public static getAllCityAreas(): Promise<Payload<[]>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getAllCityAreas.path}`, {
      method: ENDPOINTS.getAllCityAreas.method,
    });
  }
}
