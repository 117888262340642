/**
 * @author Ahmed Serag
 * @date 2021-09-04
 * @description React component responsible for selecting the image and display the updated images
 * @filename image-uploader.tsx
 */
import React from 'react';

export interface ImageUploaderProps {
  image?: string;
  name?: string;
  onChange: (newImage: string) => void;
}

const ImageUploader: React.FunctionComponent<ImageUploaderProps> = (props) => {
  const onSelectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target?.files[0]);
    reader.onload = () => {
      props.onChange(reader.result?.toString());
    };
    reader.onerror = (error) => {
      console.error('error', error);
    };
  };

  if (props.image) {
    return (
      <div className="image-uploader filled">
        <img src={props.image} />
        <label className="update" htmlFor="file">
          Update
        </label>
        <input type="file" name="file" id="file" accept="image/*" onChange={onSelectImage} />
      </div>
    );
  }

  return (
    <div className="image-uploader">
      <label className="upload text-initial" htmlFor="file">
        {props.name ? `Upload ${props.name} picture` : 'Upload course picture'}
      </label>
      <input type="file" name="file" id="file" accept="image/*" onChange={onSelectImage} />
    </div>
  );
};

export default ImageUploader;
