import React, { useState, useEffect } from 'react';
import Toastr from 'toastr';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../../../definitions/consts/routes';
import {
  CourseStatus,
  PresenterCourse as PresenterCourseInterface,
} from '../../../../definitions/interfaces/presenter-course-details';
import { PresenterLessons as PresenterLessonsInterface } from '../../../../definitions/interfaces/presenter-lesson';

import Loader from 'src/ts/react-components/common/loader';
import PresenterCourseLessons from './presenter-course-lessons';
import AddEditLessonModalPresenter from './add-edit-lesson-modal';

import { PresenterCourse as PresenterCourseUtilities } from 'src/ts/utilities/presenter-course';
import PresenterCourseInfo from './presenter-course-info';
// import Loader from 'src/ts/react-components/common/loader';
interface SelectOption {
  value: number;
  label: string;
}

const PresenterCourseDetails: React.FunctionComponent = () => {
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(null);
  const [loadingCourseDetails, setLoadingCourseDetails] = useState<boolean>(true);
  const [courseDetails, setCourseDetails] = useState<PresenterCourseInterface | null>(null);
  const [courseLessons, setCourseLessons] = useState<PresenterLessonsInterface | null>(null);
  const { courseId } = useParams<{ courseId: string; lessonId: string }>();
  const [showModal, setShowModal] = useState(false);
  const [shouldRefreshLessonsListing, setShouldRefreshLessonsListing] = useState<boolean>(false);
  const [modalState, setModalState] = useState('');
  const history = useHistory();

  useEffect(() => {
    //fetch Course details based on id
    if (courseId) {
      setLoadingCourseDetails(true);
      PresenterCourseUtilities.getPresenterCourseDetails(courseId)
        .then((response) => {
          setCourseDetails(response);
          setLoadingCourseDetails(false);
          setSelectedOption({
            value: response.status.id,
            label: response.status.name === CourseStatus.published ? 'Published' : 'Not published',
          });
        })
        .catch((error: any) => {
          Toastr.error(error);
          console.error(error);
        });
    }
  }, [courseId]);

  useEffect(() => {
    //fetch Course details based on id
    if (courseId) {
      PresenterCourseUtilities.getPresenterCourseLessons(courseId)
        .then((response) => {
          setCourseLessons(response);
        })
        .catch((error: any) => {
          Toastr.error(error);
          console.error(error);
        });
    }
  }, [courseId, shouldRefreshLessonsListing]);

  let weekDays = ``;
  const publishOptions: SelectOption[] = [];
  if (courseDetails?.status?.name === CourseStatus.unpublished) {
    publishOptions.push({ value: 1, label: 'Published' });
  }

  if (courseDetails?.status?.name === CourseStatus.published) {
    publishOptions.push({ value: 2, label: 'Not published' });
  }

  for (let index = 0; index < courseDetails?.weekDays.length; index = index + 1) {
    if (index < courseDetails?.weekDays.length - 2) {
      weekDays += `${courseDetails?.weekDays[index]}, `;
    } else if (index < courseDetails?.weekDays.length - 1) {
      weekDays += `${courseDetails?.weekDays[index]} & `;
    } else {
      weekDays += `${courseDetails?.weekDays[index]}`;
    }
  }

  const [selectedLessonId, setSelectedLessonId] = useState('');
  const [selectedLessonName, setSelectedLessonName] = useState('');
  const handleOpenModal = (state, lessonId?: string, lessonName?: string) => {
    setSelectedLessonId(lessonId);
    setSelectedLessonName(lessonName);
    if (state == 'add') {
      setModalState('add');
      setShowModal(true);
    } else if (state == 'edit') {
      setModalState('edit');
      setShowModal(true);
    }
  };

  // const selectedLessonId = courseLessons?.lessons?.find((lesson) => lesson.id == lessonId);
  const handleEditeCourse = () => {
    history.push({
      pathname: `${ROUTES.editPresenterCourse.getPath && ROUTES.editPresenterCourse.getPath(courseId)}`,
      state: courseDetails,
    });
  };

  // const [searchParams, setSearchParams] = useState<any>();
  // useEffect(() => {
  //   setSearchParams(location.search);
  //   return () => {
  //     if (history.action === 'POP') {
  //       console.log(location.search);
  //       history.push({
  //         pathname: `${ROUTES.presenterDashboard.getPath('Courses')}`,
  //         search: searchParams,
  //       });
  //     }
  //   };
  // }, [history, searchParams]);

  if (loadingCourseDetails) return <Loader />;
  else {
    return (
      <>
        <PresenterCourseInfo
          courseImage={courseDetails?.image}
          createdAt={courseDetails?.createdAt}
          courseTitle={courseDetails?.title}
          groupFocusName={courseDetails?.groupFocusValue.name}
          schooleSystems={courseDetails?.schoolSystems}
          reviewSummary={courseDetails?.reviewSummary}
          presenter={courseDetails?.presenter}
          courseStatusName={courseDetails?.status?.name}
          isCourseStatusPuplished={CourseStatus.published}
          handleEditeCourse={handleEditeCourse}
          capacity={courseDetails?.capacity}
          currentValue={courseDetails?.capacity.fullBooking}
          totalValue={courseDetails?.capacity.total}
          description={courseDetails?.description}
          objectives={courseDetails?.objectives}
        />
        {/* start of course Rules Section */}
        <div className="entity-course__rules">
          <h2 className="entity-course__rules-title">course rules</h2>
          <p className="entity-course__rules-paragraph">{`Weekdays: ${weekDays}`}</p>
          <p className="entity-course__rules-paragraph">{`Takes place ${courseDetails?.type.name} ${
            courseDetails?.branch?.area?.name && ` & at ${courseDetails?.branch.area.name} branch`
          }`}</p>
          <p className="entity-course__rules-paragraph">{`Walk-in is ${
            courseDetails?.individualBookingEnabled ? 'available (24 hours prior)' : 'not available'
          } `}</p>
        </div>
        {/* end of course Rules Section */}
        {/* start of course Lessons Section */}
        <PresenterCourseLessons
          courseDetailsId={courseDetails?.id}
          lessonsCount={courseLessons?.count?.lessons}
          classesCount={courseLessons?.count?.classes}
          courseDetailsStatus={courseDetails?.status}
          courseDetailsLessons={courseLessons?.lessons}
          handleOpenModal={handleOpenModal}
          setShouldRefreshLessonsListing={setShouldRefreshLessonsListing}
          courseId={courseId}
          coursePuplishedStatus={CourseStatus?.published}
          selectedOption={selectedOption}
          presenter={courseDetails?.presenter}
        />
        {/* end Lessons Accordion section */}
        {/* Modal of edit and add leson */}
        <div className="add-lesson__modal">
          {showModal && courseId && (
            <AddEditLessonModalPresenter
              modalState={modalState}
              showModal={showModal}
              selectedLessonId={selectedLessonId}
              selectedLessonName={selectedLessonName}
              setShowModal={setShowModal}
              courseId={courseId}
              setShouldRefreshLessonsListing={setShouldRefreshLessonsListing}
            />
          )}
        </div>
      </>
    );
  }
};

export default PresenterCourseDetails;
