import React, { useState, useEffect } from 'react';
import { PresenterCourse as PresenterCourseAPI } from 'src/ts/api/presenter-course';

const MarkAsDone: React.FunctionComponent<{ classId: string; btnState?: string }> = (props) => {
  const btnState = props.btnState;
  const [buttonState, setButtonState] = useState(btnState);

  useEffect(() => {
    if (btnState) setButtonState(props.btnState);
  }, []);
  const markAsDone = () => {
    PresenterCourseAPI.confirmClass(props.classId)
      .then(() => setButtonState('confirmed'))
      .catch((error) => console.error(error));
  };

  let result = (
    <button className="accordion-content__done-button btn" type="button" onClick={() => setButtonState('confirming')}>
      <p>mark as done</p>
    </button>
  );

  switch (buttonState) {
    case 'confirmed':
      result = (
        <button className="accordion-content__confirming-button btn btn--green" disabled>
          &#10003; Marked as done
        </button>
      );
      break;
    case 'confirming':
      result = (
        <button className="btn accordion-content__confirming-button btn--green" onClick={() => markAsDone()}>
          confirm
        </button>
      );
      break;
    default:
      break;
  }

  return result;
};

export default MarkAsDone;
