import React from 'react';
import Modal from 'src/ts/react-components/common/modal';
import AddLesson from '../../add-lesson/add-lesson';
import EditLesson from '../edit-lesson';

interface addEditLesson {
  modalState: string;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  courseId: string;
  setShouldRefreshLessonsListing: React.Dispatch<React.SetStateAction<boolean>>;
  selectedLessonId: string;
  selectedLessonName: string;
}
const AddEditLessonModal: React.FunctionComponent<addEditLesson> = (props) => {
  const {
    modalState,
    showModal,
    setShowModal,
    courseId,
    setShouldRefreshLessonsListing,
    selectedLessonId,
    selectedLessonName,
  } = props;
  if (modalState == 'add') {
    return (
      <Modal showModal={showModal}>
        <AddLesson
          setShowModal={setShowModal}
          courseId={courseId}
          setShouldRefreshListing={setShouldRefreshLessonsListing}
        />
      </Modal>
    );
  } else if (modalState == 'edit') {
    return (
      <Modal showModal={showModal}>
        <EditLesson
          setShowModal={setShowModal}
          courseId={courseId}
          setShouldRefreshListing={setShouldRefreshLessonsListing}
          lessonId={selectedLessonId}
          lessonName={selectedLessonName}
        />
      </Modal>
    );
  }
};

export default AddEditLessonModal;
