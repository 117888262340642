import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import Toastr from 'toastr';
import { ADD_COURSE_SCHEMA } from 'src/ts/utilities/presenter-course';
import { PresenterCourse as PresenterCourseUtilities } from '../../../../utilities/presenter-course';
import { ROUTES } from 'src/ts/definitions/consts/routes';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AddCourseInput } from 'src/ts/definitions/interfaces/inputs/add-course-input';
import { Subject as SubjectInterface } from 'src/ts/definitions/interfaces/subject';
import { BranchLookup, EntityCourse as EntityCourseInterface } from '../../../../definitions/interfaces/entity-course';
import { SchoolSystem as SchoolSystemInterface } from 'src/ts/definitions/interfaces/entity-course';
// import { Presenter as PresenterInterface } from 'src/ts/definitions/interfaces/entity-course';
import SelectField from '../../../common/select-field';
import ImageUploader from '../../../common/image-uploader';
import Loader from '../../../common/loader';
import { USER_CONTEXT } from 'src/ts/contexts/user-context';
import { UserType } from '../../../../definitions/interfaces/common';
import { Teacher } from 'src/ts/definitions/interfaces/teacher-entity';

const AddPresenterCourse: React.FunctionComponent = () => {
  const userContext = useContext(USER_CONTEXT);
  const [addCourseErrors, setAddCourseErrors] = useState<string[]>();
  const [courseDetails, setCourseDetails] = useState<EntityCourseInterface | null>(null);
  const [subjects, setSubjects] = useState<SubjectInterface[] | null>(null);
  const [schoolSystems, setSchoolSystems] = useState<SchoolSystemInterface[] | null>(null);
  const [branches, setBranches] = useState<BranchLookup[] | null>(null);
  const [selectedSubjectOption, setSelectedSubjectOption] = useState<{
    value: number;
    label?: string;
  } | null>(null);

  const [selectedClassTypeOption, setSelectedClassTypeOption] = useState<{
    value: number;
    label?: string;
  } | null>(null);
  const [selectedGrades, setSelectedGrades] = useState<
    | {
        value: number;
      }[]
    | null
  >(null);

  const [selectedBranchOption, setSelectedBranchOption] = useState<{
    value: string;
    label?: string;
  } | null>(null);

  const [isloading, setIsLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { courseId } = useParams<{ courseId: string }>();
  const locationState = location.state as EntityCourseInterface;

  useEffect(() => {
    //fetch Course details based on id
    if (courseId && locationState) {
      setCourseDetails(locationState);

      setSelectedSubjectOption({
        value: locationState?.groupFocusValue.id,
        label: locationState?.groupFocusValue.name,
      });

      setSelectedClassTypeOption({
        value: locationState?.type.id,
        label: locationState?.type.name,
      });
      const gradeIds = [];
      for (const schoolSystem of locationState?.schoolSystems) {
        for (const grade of schoolSystem.grades) {
          gradeIds.push({
            value: grade.id,
            label: grade.name,
          });
        }
      }
      setSelectedGrades(gradeIds);
    }
    PresenterCourseUtilities.getAllBranches()
      .then((response) => {
        setBranches(response);
      })
      .catch((error: any) => {
        Toastr.error(error);
        console.error(error);
      });
  }, [courseId]);

  useEffect(() => {
    //fetch Course details based on id
    PresenterCourseUtilities.getAllSubjects()
      .then((response) => {
        setSubjects(response);
      })
      .catch((error: any) => {
        Toastr.error(error);
        console.error(error);
      });

    PresenterCourseUtilities.getAllSchoolSystems()
      .then((response) => {
        setSchoolSystems(response);
      })
      .catch((error: any) => {
        Toastr.error(error);
        console.error(error);
      });
  }, []);

  const handleAddCourse = (values: AddCourseInput, actions: FormikHelpers<FormikValues>) => {
    setIsLoading(true);

    if (courseDetails) {
      PresenterCourseUtilities.editPresenterCourse(courseId, { ...values, image: values.image?.split(',')[1] })
        .then(() => {
          Toastr.success('Course edited successfully');
          setAddCourseErrors([]);
          setIsLoading(false);
          history.push(`${ROUTES.presenterCourseDetails.getPath && ROUTES.presenterCourseDetails.getPath(courseId)}`);
        })
        .catch((error) => {
          setAddCourseErrors(error);
          console.error(error);
          setIsLoading(false);
        });
    } else {
      PresenterCourseUtilities.addPresenterCourse({
        ...values,
        image: values.image?.split(',')[1],
      })
        .then((newCourse) => {
          Toastr.success('Course Added Successfully');
          history.push(
            `${ROUTES.presenterCourseDetails.getPath && ROUTES.presenterCourseDetails.getPath(newCourse.id)}`
          );
          setIsLoading(false);
        })
        .catch((errors) => {
          setAddCourseErrors(errors);
          console.error(errors);
          setIsLoading(false);
          actions.setSubmitting(false);
        });
    }
  };

  const subjectOptions = subjects?.map((subject) => {
    return {
      value: subject.groupFocusId,
      label: subject.groupFocusName,
    };
  });

  const schoolSystemsOptions = schoolSystems?.map((schoolSystem) => {
    return {
      label: schoolSystem.name,
      options: schoolSystem.grades.map((g) => {
        return {
          value: g.id,
          label: g.name,
        };
      }),
    };
  });

  const branchOptions = branches?.map((branch) => {
    return {
      value: branch.branchId,
      label: branch.branchName,
    };
  });

  const classTypesOptions = [
    { value: 1, label: 'online' },
    { value: 2, label: 'offline' },
    { value: 3, label: 'both' },
  ];
  const weekDaysOptions = [
    {
      value: 0,
      label: 'Sundays',
    },
    {
      value: 1,
      label: 'Mondays',
    },
    {
      value: 2,
      label: 'Tuesdays',
    },
    {
      value: 3,
      label: 'Wednesdays',
    },
    {
      value: 4,
      label: 'Thursdays',
    },
    {
      value: 5,
      label: 'Fridays',
    },
    {
      value: 6,
      label: 'Saturdays',
    },
  ];

  const schoolSystemIds = [];
  const gradeIds = [];

  if (courseDetails?.schoolSystems) {
    for (const schoolSystem of courseDetails?.schoolSystems) {
      schoolSystemIds.push(schoolSystem.id);
      for (const grade of schoolSystem.grades) {
        gradeIds.push(grade.id);
      }
    }
  }

  const selectedWeekdays = courseDetails?.weekDays?.map((weekDay) => {
    return weekDaysOptions.find((weekDayOption) => weekDayOption.label.includes(weekDay))?.value;
  });
  // if (isloading) <Loader />;
  return (
    <>
      <div className="add-class">
        <h1 className="add-class__title">{courseId ? 'edit course' : 'Add new Course'}</h1>
        <div className="add-class__form-wrapper">
          <Formik
            enableReinitialize={true}
            validationSchema={ADD_COURSE_SCHEMA}
            onSubmit={handleAddCourse}
            initialValues={{
              name: courseDetails ? courseDetails.title : '',
              groupFocusValueId: courseDetails ? courseDetails.groupFocusValue.id : null,
              schoolSystemIds,
              gradeIds,
              presenterId: (userContext?.user as Teacher)?.id,
              branchId: courseDetails ? courseDetails.branch.id : '',
              weekDays: selectedWeekdays ?? [],
              description: courseDetails ? courseDetails.description : '',
              objectives: courseDetails ? courseDetails.objectives : '',
              typeId: courseDetails ? courseDetails.type.id : 1,
              capacity: courseDetails ? courseDetails.capacity.total : 0,
              individualBookingEnabled: courseDetails ? courseDetails.individualBookingEnabled : true,
              image: courseDetails ? courseDetails.image : '',
            }}
          >
            {({ errors, touched, setFieldValue, values, setFieldTouched }) => (
              <Form className="add-class__form">
                <div>
                  <h2 className="add-class__subtitle">basic info</h2>
                  <ImageUploader
                    image={values.image}
                    onChange={(newImage) => {
                      setFieldValue('image', newImage);
                      setFieldTouched('image', true);
                    }}
                  />
                  {errors.image && touched.image ? <div className="add-class__error">{errors.image}</div> : null}
                  <div className="input-wrapper">
                    <label htmlFor="name">
                      Course name <span className="required-field">*</span>
                    </label>
                    <Field type="name" name="name" placeholder="Class name here" />
                    {errors.name && touched.name ? <div className="add-class__error">{errors.name}</div> : null}
                  </div>
                  <div className="add-class__subjects-and-systems input-wrapper">
                    <div>
                      <label htmlFor="groupFocusValueId">
                        Course subject <span className="required-field">*</span>
                      </label>
                      <div className="add-class__select-subject">
                        <SelectField
                          title="subject"
                          showTitle={false}
                          isSearchable={true}
                          placeholder="choose subject"
                          optionValues={subjectOptions ?? []}
                          onChange={(subject) => {
                            setSelectedSubjectOption(subject);
                            setFieldValue('groupFocusValueId', subject.value);
                          }}
                          selectedOption={selectedSubjectOption}
                        />
                      </div>
                      {errors.groupFocusValueId && touched.groupFocusValueId ? (
                        <div className="add-class__error">{errors.groupFocusValueId}</div>
                      ) : null}
                    </div>
                    <div className="school-system-wrapper">
                      <label>
                        Course school system <span className="required-field">*</span>
                      </label>
                      <div className="add-class__select-school-system">
                        <SelectField
                          title="school-system"
                          isMulti
                          showTitle={false}
                          isSearchable={true}
                          placeholder="choose a system"
                          optionValues={schoolSystemsOptions ?? []}
                          className="basic-multi-select"
                          onChange={(selectedItems) => {
                            const gradeIds = selectedItems.map((s) => s.value);
                            const schoolSystemIds = schoolSystems.reduce((prev, cur) => {
                              const exist = cur.grades.find((grade) => {
                                return gradeIds.includes(grade.id);
                              });
                              if (exist) {
                                return [...prev, cur.id];
                              }
                              return prev;
                            }, []);
                            setSelectedGrades(selectedItems);
                            setFieldValue('gradeIds', gradeIds);
                            setFieldValue('schoolSystemIds', schoolSystemIds);
                          }}
                          selectedOption={selectedGrades}
                        />
                      </div>
                      {errors.schoolSystemIds && touched.schoolSystemIds ? (
                        <div className="add-class__error">{errors.schoolSystemIds}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="presenterId">
                      Assign course to <span className="required-field">*</span>
                    </label>
                    <div className="add-class__select-subject">
                      <div
                        className="select select__wrapper react-select 
                         add-class__select-school-system select basic-multi-select__control teacher-select-container"
                      >
                        <select
                          id="presenterId"
                          className="basic-multi-select__control"
                          value={(userContext?.user as Teacher)?.id}
                        >
                          <option>
                            {userContext?.type === UserType.teacher && (userContext?.user as Teacher)?.name}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="typeId">
                      Course type
                      <span className="required-field"> *</span>
                    </label>
                    <div className="add-class__select-subject">
                      <SelectField
                        title="class type"
                        showTitle={false}
                        isSearchable={true}
                        placeholder="Choose course type"
                        optionValues={classTypesOptions ?? []}
                        onChange={(classType) => {
                          setSelectedClassTypeOption(classType);
                          setFieldValue('typeId', classType.value);
                        }}
                        selectedOption={selectedClassTypeOption}
                      />
                    </div>
                    {errors.typeId && touched.typeId ? <div className="add-class__error">{errors.typeId}</div> : null}
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="description">
                      Course description <span className="required-field">*</span>
                    </label>
                    <Field
                      as="textarea"
                      type="description"
                      name="description"
                      placeholder="Describe your course here"
                    />
                    {errors.description && touched.description ? (
                      <div className="add-class__error">{errors.description}</div>
                    ) : null}
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="objectives">
                      Course objectives <span className="required-field">*</span>
                    </label>
                    <Field
                      as="textarea"
                      type="objectives"
                      name="objectives"
                      placeholder="Write course objectives here"
                    />
                    {errors.objectives && touched.objectives ? (
                      <div className="add-class__error">{errors.objectives}</div>
                    ) : null}
                  </div>
                </div>
                <div className="add-class__form-rules">
                  <h2 className="add-class__subtitle">course rules</h2>
                  <div className="add-class__form-rules-days">
                    <label className="add-class__form-rules-days-title">
                      Classes will take place on <span className="required-field">*</span>
                    </label>
                    <div className="add-class__form-rules-days-content">
                      {weekDaysOptions.map((day) => (
                        <span
                          key={`${day.value}-${values?.weekDays?.includes(day.value)}`}
                          className={`option ${values?.weekDays?.includes(day.value) ? 'active' : ''}`}
                          onClick={() => {
                            const newWeekDays = [...values.weekDays] ?? [];
                            if (newWeekDays.includes(day.value)) {
                              newWeekDays.splice(newWeekDays.indexOf(day.value), 1);
                            } else {
                              newWeekDays.push(day.value);
                            }
                            setFieldValue('weekDays', newWeekDays);
                          }}
                        >
                          {day.label}
                        </span>
                      ))}
                    </div>
                    {errors.weekDays && touched.weekDays ? (
                      <div className="add-class__error">{errors.weekDays}</div>
                    ) : null}
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="presenterId">
                      Select a branch{' '}
                      <span className="required-field">
                        <span className="required-field">*</span>
                      </span>
                    </label>
                    <div className="add-class__select-subject">
                      <SelectField
                        title="branch"
                        showTitle={false}
                        isSearchable={true}
                        placeholder="select a branch"
                        optionValues={branchOptions ?? []}
                        onChange={(branch) => {
                          setSelectedBranchOption(branch);
                          setFieldValue('branchId', branch.value);
                        }}
                        selectedOption={selectedBranchOption}
                      />
                    </div>
                    {errors.branchId && touched.branchId ? (
                      <div className="add-class__error">{errors.branchId}</div>
                    ) : null}
                  </div>
                  <div className="add-class__form-price-container add-class__form-capacity-container input-wrapper">
                    <label htmlFor="capacity">
                      Course capacity<span className="required-field"> *</span>
                    </label>
                    <div className="add-class__form-price add-class__form-capacity">
                      <Field type="text" name="capacity" />
                      <span>Learner</span>
                    </div>
                    {errors.capacity && touched.capacity ? (
                      <div className="add-class__error">{errors.capacity}</div>
                    ) : null}
                  </div>

                  <div className="add-class__form-recording">
                    <p className="add-class__form-recording-label">Walk in availability</p>
                    <div className="add-class__form-recording-container">
                      <label className="add-class__form-recording-checkbox">
                        <Field type="checkbox" name="individualBookingEnabled" />
                        <span className="slider round"></span>
                      </label>
                      <p>available</p>
                    </div>
                  </div>
                  {errors.individualBookingEnabled && touched.individualBookingEnabled ? (
                    <div className="add-class__error">{errors.individualBookingEnabled}</div>
                  ) : null}
                </div>
                <div className="add-class__error add-class__error--server">
                  {addCourseErrors &&
                    addCourseErrors.map((addCourseError, index) => {
                      return <p key={index}>{addCourseError}</p>;
                    })}
                </div>
                <button
                  className="add-class__form-btn btn btn--green"
                  type="submit"
                  disabled={isloading ? true : false}
                >
                  {courseId ? 'Update' : 'Save'}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {isloading && <Loader />}
    </>
  );
};
export default AddPresenterCourse;
