import * as YUP from 'yup';
import { TutorAuthentication as TutorAuthenticationAPI } from '../api/tutor-authentication';
import { TutorLoginInput } from '../definitions/interfaces/inputs/tutor-login';
import { Payload } from '../definitions/interfaces/payload';
import { Tutor } from '../definitions/interfaces/tutor';

/**
 * schema to validate the fields of tutor login.
 */
export const TUTOR_LOGIN_SCHEMA = YUP.object().shape({
  email: YUP.string().required('Please enter email'),
  password: YUP.string().required('Please enter password').min(6, 'Password should be at least 6 characters'),
});

/**
 * a class that contains tutor authentication related utilities.
 *
 * @export
 * @class TutorAuthentication
 */
export class TutorAuthentication {
  /**
   *tutor login with email & password
   *
   * @static
   * @param {TutorLoginInput} input
   * @returns {Promise<Payload<Tutor>>}
   * @memberof TutorAuthentication
   */
  public static tutorLogin(input: TutorLoginInput): Promise<Payload<Tutor>> {
    return TutorAuthenticationAPI.tutorLogin(input)
      .then((result: Payload<Tutor>) => {
        return result;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   *fetch current logged in tutor
   *
   * @static
   * @returns {Promise<Payload<Tutor>>}
   * @memberof TutorAuthentication
   */
  public static getTutor(): Promise<Payload<Tutor>> {
    return TutorAuthenticationAPI.getTutor()
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  /**
   *logout current Tutor
   *
   * @static
   * @returns {Promise<Payload>}
   * @memberof TutorAuthentication
   */
  public static tutorLogout(): Promise<Payload> {
    return TutorAuthenticationAPI.tutorLogout()
      .then((result: Payload) => {
        if (process.env.REACT_APP_TOKEN_DETAILS) localStorage.removeItem(process.env.REACT_APP_TOKEN_DETAILS);
        return result;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
