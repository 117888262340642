/**
 * @author Raghda Wessam
 * @date 2021-08-08
 * @description declaration header component layout.
 * @filename header.tsx
 */
import React, { useContext, useRef, useEffect, useState } from 'react';
import { NavLink, useHistory, withRouter } from 'react-router-dom';
import Toastr from './toastr';
import { ROUTES } from 'src/ts/definitions/consts/routes';
import LogoIcon from 'src/static/images/icons/orcas_logo_new.svg';
import { USER_CONTEXT } from '../../contexts/user-context';
import { exist as _exist, userLogout } from '../../utilities/common';
import LogoutIcon from '../../../static/images/icons/logout.svg';
import { Student } from 'src/ts/definitions/interfaces/user';
import { Entity } from 'src/ts/definitions/interfaces/entity';
import { Tutor } from 'src/ts/definitions/interfaces/tutor';
import { UserType } from 'src/ts/definitions/interfaces/common';
import HamburgerMenu from './mob-nav/HamburgerMenu';
import { Teacher } from 'src/ts/definitions/interfaces/teacher-entity';
import placeHolderImg from 'src/static/images/placeholder.png';

const Header: React.FunctionComponent = () => {
  const userContext = useContext(USER_CONTEXT);
  const [showLogout, setShowLogout] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const currentPathName = history?.location?.pathname;

  const [isDisableBts, setIsDisableBtns] = useState<boolean>(false);

  //handling clicking outside an html element
  const handleClickOutside = (event: Event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setShowLogout(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const logoutUser = () => {
    setIsDisableBtns(true);

    userLogout().then((type) => {
      if (userContext?.updateUser) userContext.updateUser(undefined, undefined);
      Toastr.success('Successfully logged out');
      setShowLogout(false);
      history.push({
        pathname: `${
          type === UserType?.student
            ? ROUTES.login.path
            : type === UserType?.entity || UserType.teacher
            ? ROUTES.entityLogin.path
            : UserType.tutor
            ? ROUTES.tutorLogin.path
            : ''
        }`,
      });
    });
  };

  const currentUserName =
    userContext?.type === UserType.student
      ? (userContext?.user as Student)?.firstName
      : userContext?.type === UserType.entity
      ? (userContext?.user as Entity)?.name
      : userContext?.type === UserType.tutor
      ? (userContext?.user as Tutor)?.firstName + ' ' + (userContext?.user as Tutor)?.lastName ||
        (userContext?.user as Tutor)?.name
      : userContext?.type === UserType.teacher
      ? (userContext?.user as Teacher)?.name
      : '';

  const currentUserImage =
    userContext?.type === UserType.student
      ? (userContext?.user as Student)?.userImage
      : userContext?.type === UserType.entity
      ? (userContext?.user as Entity)?.image
      : userContext?.type === UserType.tutor
      ? (userContext?.user as Tutor)?.partnerImage || (userContext?.user as Tutor)?.image
      : userContext?.type === UserType.teacher
      ? (userContext?.user as Teacher)?.image
      : placeHolderImg;

  const [isOpen, setIsOpen] = useState<boolean | any>(false);
  const handleToggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const goHomeFormPrivacy = () => {
    window.location?.reload();
  };
  return (
    <header className="header">
      <h1 className="header__title">
        {currentPathName !== '/privacy-policy' ? (
          <NavLink to="/" exact>
            <img src={LogoIcon} alt="logo" />
          </NavLink>
        ) : (
          <NavLink to="/" exact>
            <img src={LogoIcon} alt="logo" onClick={goHomeFormPrivacy} />
          </NavLink>
        )}
      </h1>

      {currentPathName !== '/privacy-policy' ? (
        _exist(userContext?.user) ? (
          <div
            className="header__student-info"
            onClick={() => {
              setShowLogout(true);
            }}
          >
            <p>{`Hello, ${currentUserName}`}</p>
            <img src={currentUserImage} alt="user image" />

            <div
              className={showLogout ? 'header__logout show' : 'header__logout'}
              ref={wrapperRef}
              onClick={() => {
                logoutUser();
              }}
            >
              <button disabled={isDisableBts}>
                <img src={LogoutIcon} alt="logout" />
                Logout
              </button>
            </div>
          </div>
        ) : (
          <>
            <nav className="header__nav">
              <ul className="header__nav-list unstyle">
                <li className="header__nav-item">
                  <NavLink to={ROUTES.login.path} exact className="header__nav-link">
                    Join your Classroom
                  </NavLink>
                </li>
                <li className="header__nav-item">
                  <NavLink to={ROUTES.entityLogin.path} exact className="header__nav-link">
                    Login as an entity
                  </NavLink>
                </li>
                <li className="header__nav-item">
                  <NavLink to={ROUTES.tutorLogin.path} exact className="header__nav-link">
                    Login as a tutor
                  </NavLink>
                </li>
              </ul>
            </nav>

            <HamburgerMenu isOpen={isOpen} handleClick={handleToggleMenu}>
              <nav className="header__nav mob-nav">
                <ul className="header__nav-list unstyle">
                  <li className="header__nav-item" onClick={() => setIsOpen(!isOpen)}>
                    <NavLink to={ROUTES.login.path} exact className="header__nav-link">
                      Join your Classroom
                    </NavLink>
                  </li>
                  <li className="header__nav-item" onClick={() => setIsOpen(!isOpen)}>
                    <NavLink to={ROUTES.entityLogin.path} exact className="header__nav-link">
                      Login as entity
                    </NavLink>
                  </li>
                  <li className="header__nav-item" onClick={() => setIsOpen(!isOpen)}>
                    <NavLink to={ROUTES.tutorLogin.path} exact className="header__nav-link">
                      Login as a tutor
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </HamburgerMenu>
          </>
        )
      ) : (
        ''
      )}
    </header>
  );
};

export default withRouter(Header);
