import React, { useState, useEffect } from 'react';
import { EntityProfile } from 'src/ts/api/entity-profile';
import { EntityBranches } from 'src/ts/definitions/interfaces/entity-branches';
import EditIcon from '../../../../../static/images/icons/edit_teacher.svg';
import Modal from 'src/ts/react-components/common/modal';
import AddBranch from './add-new-branch';
import EditBranch from './edit-branch';
import Toastr from 'toastr';
import DeleteIcon from '../../../../../static/images/icons/delete_icon.svg';
import Loader from 'src/ts/react-components/common/loader';
import { formate } from 'src/ts/utilities/moment-formate-Date';

const Profile: React.FunctionComponent = () => {
  const [entityBranches, setEntityBranches] = useState<EntityBranches[] | null>(null);
  const [loadingBranches, setLoadingBranches] = useState<boolean | null>(null);
  const getEntityBranches = () => {
    setLoadingBranches(true);
    EntityProfile.getAllBranches(0, 10).then((response) => {
      setEntityBranches(response.data);
      setLoadingBranches(false);
    });
  };
  useEffect(() => {
    getEntityBranches();
  }, []);
  const [showModal, setShowModal] = useState(false);
  const [addEdit, setAddEdit] = useState('');
  const [selectedBranch, setSelectedBranch] = useState();
  const addEditBranch = (branch?: any) => {
    setShowModal(true);
    if (branch) {
      setAddEdit('edit');
      setSelectedBranch(branch);
    } else setAddEdit('add');
  };

  const deleteBranch = (branchId: string) => {
    EntityProfile.deleteBranch(branchId).then(() => {
      Toastr.success('Branch deleted successfully');
      getEntityBranches();
    });
  };
  if (loadingBranches) return <Loader />;
  else
    return (
      <>
        <div className="profile-container">
          <div className="profile-wrapper">
            <div className="headings-btn-wrapper">
              <h1 className="section-title">My Profile</h1>
              <button className="add-branch-btn btn--green" onClick={() => addEditBranch()}>
                <span>+ </span>
                <p> Add New Branch</p>
              </button>
            </div>
            <h2 className="course_heading">Entity Branches</h2>

            {entityBranches &&
              entityBranches.map((branch) => {
                return (
                  <div key={branch.branchId} className="branch-item">
                    <div className="branch-item__teacher-deatils">
                      <div className="teacher-wrapper__name-and-date-of-join">
                        <span>Created {formate(branch.createdAt)}</span>
                        <h3>{branch.branchName}</h3>
                        <span>{branch.addressName}</span>
                      </div>
                    </div>
                    {/* <div className="branch-item__edit-btn"> */}
                    <div className="teacher-wrapper__cta-btn">
                      <button className="delete-btn" onClick={() => deleteBranch(branch.branchId)}>
                        <img src={DeleteIcon} alt="" />
                        <p> Delete Branch</p>
                      </button>
                      <button onClick={() => addEditBranch(branch)}>
                        <img src={EditIcon} alt="edit_icon" />
                        <p> Edit Branch</p>
                      </button>
                      {/* </div> */}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="add-branch__modal">
            {showModal && addEdit == 'add' ? (
              <Modal showModal={showModal}>
                <AddBranch setShowModal={setShowModal} getBranches={getEntityBranches} />
              </Modal>
            ) : showModal && addEdit == 'edit' ? (
              <Modal showModal={showModal}>
                <EditBranch
                  setShowModal={setShowModal}
                  selectedBranch={selectedBranch}
                  getBranches={getEntityBranches}
                />
              </Modal>
            ) : (
              ''
            )}
          </div>
        </div>
      </>
    );
};

export default Profile;
