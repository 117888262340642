/**
 * @author Raghda Wessam
 * @date 2021-08-15
 * @description implementation of session related API calls.
 * @filename session.ts
 */

import { Network } from './network';
import { ENDPOINTS } from './endpoints';
import { Payload } from '../definitions/interfaces/payload';
import { UnconfirmedCourses as UnconfirmedCoursesInterface } from '../definitions/interfaces/unconfirmed-courses';
import { UpdateAttendanceInput } from '../definitions/interfaces/inputs/update-attendance-input';
import { AddReviewInput } from '../definitions/interfaces/inputs/add-review-input';
import { LiveNowPayload } from '../definitions/interfaces/payload';
import { Course } from '../definitions/interfaces/course';
import { Session as SessionInterface } from '../definitions/interfaces/session';
import { CourseBody } from '../definitions/interfaces/entity-courses-listing';
import { SearchBody } from '../definitions/interfaces/search-body';

export class Session {
  /**
   *Get live now sessions
   *
   * @static
   * @returns {Promise<LiveNowPayload>}
   * @memberof Sessions
   */
  public static getLiveNowSessions(): Promise<LiveNowPayload> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getLiveNowSessions.path}`, {
      method: ENDPOINTS.getLiveNowSessions.method,
    });
  }
  /**
   *Get unconfirmed courses
   *
   * @static
   * @returns {Promise<Payload<UnconfirmedCoursesInterface>>}
   * @memberof Sessions
   */
  public static getUnconfirmedCourses(): Promise<Payload<UnconfirmedCoursesInterface>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getUnconfirmedCourses.path}`, {
      method: ENDPOINTS.getUnconfirmedCourses.method,
    });
  }

  /**
   *Get All courses
   *
   * @static
   * @returns {Promise<Payload<Courses>>}
   * @memberof Sessions
   */
  public static getAllCourses(offset: number, limit: number, searchBody?: SearchBody): Promise<Payload<CourseBody[]>> {
    const body = {
      ...searchBody,
    };
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getAllCourses.path(offset, limit)}`, {
      method: ENDPOINTS.getAllCourses.method,
      body: JSON.stringify(body),
    });
  }

  /**
   *update attendance
   *
   * @static
   * @param {UpdateAttendanceInput} input
   * @returns {Promise<Payload>}
   * @memberof Sessions
   */
  public static updateAttendance(input: UpdateAttendanceInput): Promise<Payload> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.updateAttendance.path}`, {
      method: ENDPOINTS.updateAttendance.method,
      body: JSON.stringify(input),
    });
  }

  /**
   * add class review
   *
   * @static
   * @param {AddReviewInput} input
   * @returns {Promise<Payload>}
   * @memberof Sessions
   */
  public static addClassReview(input: AddReviewInput): Promise<Payload> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.addClassReview.path}`, {
      method: ENDPOINTS.addClassReview.method,
      body: JSON.stringify(input),
    });
  }

  /**
   *join online session
   *
   * @static
   * @param {string} sessionId
   * @returns {Promise<Session>}
   * @memberof Session
   */
  public static joinOnlineSession(sessionId: string): Promise<Payload<SessionInterface>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.joinOnlineSession.path}`, {
      method: ENDPOINTS.joinOnlineSession.method,
      body: JSON.stringify({ jobId: sessionId }),
    });
  }

  /**
   *join online course
   *
   * @static
   * @param {string} classId
   * @returns {Promise<Course>}
   * @memberof Session
   */
  public static joinOnlineCourse(classId: string): Promise<Payload<Course>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.joinOnlineCourse.path}`, {
      method: ENDPOINTS.joinOnlineCourse.method,
      body: JSON.stringify({ classId: classId }),
    });
  }

  /**
   *leave online classroom / session
   *
   * @static
   * @param {string} sessionId
   * @returns {Promise<Payload>}
   * @memberof Session
   */
  public static leaveClassroom(sessionId: string): Promise<Payload> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.leaveClassroom.url(sessionId)}`, {
      method: ENDPOINTS.leaveClassroom.method,
    });
  }
}
