import React, { Children } from 'react';
import { CarouselProps } from 'src/ts/definitions/interfaces/carousel-props';
const Carousel = ({
  children,
  show,
  containerClassName,
  wrapperClassName,
  contentWrapperClassName,
  contentClassName,
}: CarouselProps): JSX.Element => {
  /**
   * Total item
   */
  const length = Children.count(children);

  /**
   * Current Index Item of the Carousel
   */
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);

  /**
   * Move forward to the next item
   */
  const nextItem = () => {
    if (currentIndex < length - show) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };
  /**
   * Move backward to the previous item
   */
  const previousItem = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  return (
    <div className={`carousel-container ${containerClassName || ''}`}>
      <div className={`carousel-wrapper ${wrapperClassName || ''}`}>
        {currentIndex > 0 ? (
          <button onClick={previousItem} className="left-arrow-button">
            <span className="left-arrow" />
          </button>
        ) : null}
        <div className={`carousel-content-wrapper ${contentWrapperClassName || ''}`}>
          <div
            className={`carousel-content show-${show} ${contentClassName || ''}`}
            style={{
              transform: `translateX(-${currentIndex * (100 / show)}%)`,
            }}
          >
            {children}
          </div>
        </div>
        {currentIndex < length - show ? (
          <button onClick={nextItem} className="right-arrow-button">
            <span className="right-arrow" />
          </button>
        ) : null}
      </div>
    </div>
  );
};
export default Carousel;
