import { Network } from './network';
import { ENDPOINTS } from './endpoints';
import { Payload } from '../definitions/interfaces/payload';
import { UserType } from '../definitions/interfaces/common';
import { Tutor } from '../definitions/interfaces/tutor';
import { TutorLoginInput } from '../definitions/interfaces/inputs/tutor-login';

/**
 * implement network related calls.
 */
export class TutorAuthentication {
  /**
   * call Login endpoint and set organization authentication keys in local storage.
   *
   * @param input tutor login inputs.
   */

  public static tutorLogin(input: TutorLoginInput): Promise<Payload<Tutor>> {
    const body = {
      ...input,
    };
    const promise = Network.fetch(`${process.env.REACT_APP_API_ENDPOINT_PARTNER}${ENDPOINTS.tutorLogin.path}`, {
      method: ENDPOINTS.tutorLogin.method,
      body: JSON.stringify(body),
    });

    return promise.then((payload: Payload<Tutor>) => {
      if (payload?.data?.token && process.env.REACT_APP_TOKEN_DETAILS) {
        Network.storeTokenDetails(
          payload.data.token.access,
          payload.data.token.refresh,
          Number(payload.data.token.expireAt),
          UserType.tutor
        );
      }
      return payload;
    });
  }

  /**
   *fetch current logged in tutor
   *
   * @static
   * @returns {Promise<Payload<Tutor>>}
   * @memberof TutorAuthentication
   */
  public static getTutor(): Promise<Payload<Tutor>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT_PARTNER}${ENDPOINTS.getTutor.path}`, {
      method: ENDPOINTS.getTutor.method,
    });
  }

  /**
   *logout tutor
   *
   * @static
   * @returns {Promise<Payload>}
   * @memberof TutorAuthentication
   */
  public static tutorLogout(): Promise<Payload> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT_PARTNER}${ENDPOINTS.tutorLogout.path}`, {
      method: ENDPOINTS.tutorLogout.method,
    });
  }
}
