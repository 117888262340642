import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, FormikState } from 'formik';
import Toastr from 'toastr';
import { ADD_CLASS_SCHEMA } from 'src/ts/utilities/presenter-course';
import { AddClassInput, AddClassFormInput } from '../../../../definitions/interfaces/inputs/add-class-input';
import { PresenterClass as PresenterClassInterface } from '../../../../definitions/interfaces/presenter-class';
import { ClassType } from '../../../../definitions/interfaces/common';
import { ROUTES } from 'src/ts/definitions/consts/routes';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CourseStatus } from 'src/ts/definitions/interfaces/entity-course';
import moment from 'moment';
import Loader from '../../../common/loader';
import { isArray, isString } from 'lodash';
//
import { PresenterCourse as PresenterCourseUtilities } from '../../../../utilities/presenter-course';

const AddPresenterClass: React.FunctionComponent = () => {
  const [serverError, setServerError] = useState<string[]>();
  const [entityClass, setEntityClass] = useState<PresenterClassInterface | null>(null);
  const [isloading, setIsLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { courseId, lessonId, classId } = useParams<{ courseId: string; lessonId: string; classId?: string }>();

  useEffect(() => {
    //fetch Class details based on id
    if (classId) {
      PresenterCourseUtilities.getPresenterClass(courseId, lessonId, classId)
        .then((response) => {
          setEntityClass(response);
        })
        .catch((error: any) => {
          Toastr.error(error);
          console.error(error);
        });
    }
  }, [classId]);

  const handleSubmit = (
    values: AddClassFormInput,
    resetForm: (nextState?: Partial<FormikState<AddClassFormInput>>) => void
  ) => {
    setIsLoading(true);
    const formatedStartDateToUTC = moment.utc(moment(values?.startDate).utc()).format('YYYY-MM-DD HH:mm:ss');
    const formatedEndDateToUTC = moment.utc(moment(values?.endDate).utc()).format('YYYY-MM-DD HH:mm:ss');

    const addClassInput: AddClassInput = {
      className: values.className,
      startDate: formatedStartDateToUTC,
      endDate: formatedEndDateToUTC,
      classType: values.classType === 'offline' ? ClassType.Offline : ClassType.Online,
      isRecorded: values.isRecorded,
      //   fullClassPrice: values.fullClassPrice,
      // TODO: Remove this after MVP
      // individualClassPrice: values.fullClassPrice,
    };

    if (entityClass) {
      PresenterCourseUtilities.editPresenterClass(courseId, lessonId, classId, addClassInput)
        .then(() => {
          Toastr.success('edited class Successfully');
          setServerError([]);
          setIsLoading(false);
          resetForm();
          history.push(`${ROUTES.presenterCourseDetails.getPath && ROUTES.presenterCourseDetails.getPath(courseId)}`);
        })
        .catch((error) => {
          setServerError(error);
          console.error(error);
          setIsLoading(false);
        });
    } else {
      PresenterCourseUtilities.addPresenterClass(courseId, lessonId, addClassInput)
        .then(() => {
          Toastr.success('added class Successfully');
          setServerError([]);
          resetForm();
          setIsLoading(false);
          history.push(`${ROUTES.presenterCourseDetails.getPath && ROUTES.presenterCourseDetails.getPath(courseId)}`);
        })
        .catch((error) => {
          setServerError(error);
          console.error(error);
          setIsLoading(false);
        });
    }
  };
  // if (isloading) return <Loader />;
  return (
    <>
      <div className="add-class">
        <h1 className="add-class__title">{entityClass ? 'edit class' : 'Add new class'}</h1>
        <div className="add-class__form-wrapper">
          <Formik
            initialValues={{
              className: entityClass ? entityClass?.name : '',
              startDate: entityClass
                ? moment(entityClass?.startAt).utc(true).local().format('YYYY-MM-DDTHH:mm:ss')
                : '',
              endDate: entityClass ? moment(entityClass?.endAt).utc(true).local().format('YYYY-MM-DDTHH:mm:ss') : '',
              classType: entityClass ? entityClass?.type.name : '',
              isRecorded: entityClass ? entityClass?.isRecorded : false,
              // fullClassPrice: entityClass ? entityClass?.price.fullBooking : 0,
            }}
            enableReinitialize={entityClass && !!Object.keys(entityClass).length}
            validationSchema={ADD_CLASS_SCHEMA}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, resetForm);
            }}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form className="add-class__form">
                <div>
                  <div className="input-wrapper">
                    <label htmlFor="className">
                      Class name <span className="required-field">*</span>
                    </label>
                    <Field
                      type="className"
                      name="className"
                      placeholder="Class name here"
                      disabled={location.state === CourseStatus.published}
                    />
                    {errors.className && touched.className ? (
                      <div className="add-class__error">{errors.className}</div>
                    ) : null}
                  </div>
                  <div className="add-class__form-date input-wrapper">
                    <div className="add-class__form-date-container">
                      <div className="add-class__form-date-input">
                        <label htmlFor="startDate">Start (Date and time).</label>
                        <input
                          type="datetime-local"
                          id="startDate"
                          name="startDate"
                          required
                          min={moment().format('YYYY-MM-DDTkk:mm')}
                          value={values?.startDate}
                          onChange={(e) => {
                            setFieldValue('startDate', e.target.value);
                          }}
                        />
                      </div>
                      {errors.startDate && touched.startDate ? (
                        <div className="add-class__error">{errors.startDate}</div>
                      ) : null}
                    </div>

                    <div className="add-class__form-date-container">
                      <div className="add-class__form-date-input">
                        <label htmlFor="endDate">End Date (date and time):</label>
                        <input
                          type="datetime-local"
                          id="endDate"
                          name="endDate"
                          required
                          min={values?.startDate}
                          value={values?.endDate}
                          onChange={(e) => {
                            setFieldValue('endDate', e.target.value);
                          }}
                        />
                      </div>
                      {errors.startDate && touched.startDate ? (
                        <div className="add-class__error">{errors.startDate}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="add-class__form-class-types">
                    <button
                      type="button"
                      className={`add-class__form-class-type ${
                        values.classType === 'online' && 'add-class__form-class-type--active'
                      }`}
                      onClick={() => {
                        setFieldValue('classType', 'online');
                      }}
                      disabled={location.state === CourseStatus.published}
                    >
                      online
                    </button>
                    <button
                      type="button"
                      className={`add-class__form-class-type ${
                        values.classType === 'offline' && 'add-class__form-class-type--active'
                      }`}
                      onClick={() => {
                        setFieldValue('classType', 'offline');
                      }}
                      disabled={location.state === CourseStatus.published}
                    >
                      At the branch (offline)
                    </button>
                    {errors.classType && touched.classType ? (
                      <div className="add-class__error">{errors.classType}</div>
                    ) : null}
                  </div>

                  <div className="add-class__form-recording">
                    <p className="add-class__form-recording-label">Recording the class ?</p>
                    <div className="add-class__form-recording-container">
                      <label className="add-class__form-recording-checkbox">
                        <Field type="checkbox" name="isRecorded" disabled={location.state === CourseStatus.published} />
                        <span className="slider round"></span>
                      </label>
                      <p>recorded</p>
                    </div>
                  </div>
                  {errors.isRecorded && touched.isRecorded ? (
                    <div className="add-class__error">{errors.isRecorded}</div>
                  ) : null}
                </div>
                {/* {userContext?.type !== UserType.teacher && (
                  <div className="add-class__form-prices">
                    <div className="add-class__form-price-container">
                      <label htmlFor="fullClassPrice">
                        Class price in full course <span className="required-field">*</span>
                      </label>
                      <div className="add-class__form-price">
                        <Field name="fullClassPrice" disabled={location.state === CourseStatus.published} />
                        <span className="currency">EGP </span>
                      </div>
                      {errors.fullClassPrice && touched.fullClassPrice ? (
                        <div className="add-class__error">Full class price must be greater than or equal to 1</div>
                      ) : null}
                    </div>
                  </div>
                )} */}
                <div className="add-class__error add-class__error--server">
                  {isArray(serverError) ? (
                    serverError.length &&
                    serverError.map((serverError, index) => {
                      return <p key={index}>{serverError}</p>;
                    })
                  ) : isString(serverError) ? (
                    <p>{serverError}</p>
                  ) : (
                    ''
                  )}
                </div>
                <button
                  className="add-class__form-btn btn btn--green"
                  type="submit"
                  disabled={isloading ? true : false}
                >
                  {entityClass ? 'update' : 'save'}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {isloading && <Loader />}
    </>
  );
};
export default AddPresenterClass;
