/**
 * @author Raghda Wessam
 * @date 2021-08-10
 * @description implementation of Authentication related utilities.
 * @filename authentication.ts
 */
import * as YUP from 'yup';
import { Authentication as AuthenticationAPI } from '../api/authentication';
import { LoginInput } from '../definitions/interfaces/inputs/login';
import { Payload } from '../definitions/interfaces/payload';
import { Student } from '../definitions/interfaces/user';

/**
 * schema to validate the fields for editing supplier profile.
 */
export const LOGIN_SCHEMA = YUP.object().shape({
  mobileNumber: YUP.string().required('Please enter mobile number'),
  countryCode: YUP.string().required('Please choose country code'),
  password: YUP.string().required('Please enter password').min(6, 'Password should be at least 6 characters'),
});

/**
 * a class that contains authentication related utilities.
 *
 * @export
 * @class Authentication
 */
export class Authentication {
  /**
   *login with mobile number & password
   *
   * @static
   * @param {LoginInput} input
   * @returns {Promise<Payload<Student>>}
   * @memberof Authentication
   */
  public static login(input: LoginInput): Promise<Payload<Student>> {
    return AuthenticationAPI.login(input)
      .then((result: Payload<Student>) => {
        return result;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   *fetch current logged in user
   *
   * @static
   * @returns {Promise<Payload<Student>>}
   * @memberof Authentication
   */
  public static getUser(): Promise<Payload<Student>> {
    return AuthenticationAPI.getUser()
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   *logout current user
   *
   * @static
   * @returns {Promise<Payload>}
   * @memberof Authentication
   */
  public static logout(): Promise<Payload> {
    return AuthenticationAPI.logout()
      .then((result: Payload) => {
        if (process.env.REACT_APP_TOKEN_DETAILS) localStorage.removeItem(process.env.REACT_APP_TOKEN_DETAILS);
        return result;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
