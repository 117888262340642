import Toastr from 'toastr';

Toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: 'toast-bottom-center',
  preventDuplicates: false,
  //   hideDuration: 3000000,
  //   extendedTimeOut: 3000000,
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
};

export default Toastr;
