/**
 * @author Raghda Wessam
 * @date 2021-08-14
 * @description declaration card component layout.
 * @filename card.tsx
 */
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/ts/definitions/consts/routes';
import TimeIcon from '../../../static/images/icons/time.svg';
import { Session as SessionUtilities } from '../../utilities/session';
import { USER_CONTEXT } from 'src/ts/contexts/user-context';
// import moment from 'moment';
import { formate } from 'src/ts/utilities/moment-formate-Date';
import Toastr from './toastr';

export interface CardProps {
  /**
   *id of online session
   *
   * @type {string}
   * @memberof CardProps
   */

  sessionId?: string;

  courseId?: string;
  /**
   *id of online class
   *
   * @type {string}
   * @memberof CardProps
   */
  classId?: string;
  /**
   * type name (onlineSession or courseSession)
   */
  type?: 'onlineSession' | 'courseSession';

  /**
   * Date session starts
   */
  date: any;

  /**
   * Card title / header
   */
  title: string;

  /**
   * Card image
   */
  image: string;

  /**
   * lesson name incase of course card
   */
  lessonName?: string;

  /**
   * class name incase of course card
   */
  className?: string;

  /**
   * canJoinOnline incase of course card
   */
  canJoinOnline?: boolean;

  /**
   * number of learners incase of online session
   */
  learners?: string;

  /**
   * consumed hours incase of online session
   */
  consumedHours?: string;
}

const Card: React.FunctionComponent<CardProps> = (props) => {
  const {
    courseId,
    classId,
    sessionId,
    date,
    title,
    image,
    lessonName,
    className,
    canJoinOnline,
    learners,
    consumedHours,
    type,
  } = props;

  const userContext = useContext(USER_CONTEXT);
  const currentUser = userContext.type;
  const history = useHistory();
  const [isDisableBts, setIsDisableBtns] = useState<boolean>(false);

  const joinOnlineSession = (sessionId: string) => {
    setIsDisableBtns(true);
    SessionUtilities.joinOnlineSession(sessionId)
      .then((response) => {
        setIsDisableBtns(false);
        const joinUrl = response?.onLineSession?.joinUrl;
        if (joinUrl) {
          window.open(joinUrl, '_blank');
        }
      })
      .catch((error) => {
        setIsDisableBtns(false);
        console.error(error);
      });
  };

  const joinOnlineCourse = (classId: string) => {
    setIsDisableBtns(true);
    SessionUtilities.joinOnlineCourse(classId)
      .then((response) => {
        setIsDisableBtns(false);
        const joinUrl = response?.onLineSession?.joinUrl;

        if (joinUrl) {
          window.open(joinUrl, '_blank');
        }
      })
      .catch((error) => {
        setIsDisableBtns(false);
        console.error(error);
      });
  };

  const formattedDate = formate(date);

  const handleJoinOnlineCourse = () => {
    if (canJoinOnline && classId) {
      joinOnlineCourse(classId);
    } else if (!canJoinOnline) Toastr.error('The teacher has not started the session yet');
  };

  return (
    <div className="card">
      <img className="card__image" src={image} alt="course image" />
      <div className="card__body">
        <div className="card__text-wrapper">
          <p className="card__date">
            <img src={TimeIcon} alt="duration" />
            {`Starts ${formattedDate}`}
          </p>
          <h2 className="card__title">{title}</h2>
          {type === 'courseSession' ? (
            <p className="card__subtitle">
              <span className="lesson">{lessonName}</span>
              <span className="seperator">|</span>
              <span className="class">{className}</span>
            </p>
          ) : (
            <p className="card__subtitle">
              <span>{learners}</span>
              <span className="seperator">|</span>
              <span>{consumedHours}</span>
            </p>
          )}
        </div>

        {type === 'courseSession' ? (
          <div className="card__action">
            {currentUser !== 'student' && (
              <>
                <button
                  className="btn"
                  type="button"
                  onClick={() => {
                    history.push(`${ROUTES.courseDetails?.getPath(courseId)}`);
                  }}
                  disabled={isDisableBts}
                >
                  View course details
                </button>
              </>
            )}
            <button className="btn btn--green" type="button" onClick={handleJoinOnlineCourse} disabled={isDisableBts}>
              Join class
            </button>
          </div>
        ) : (
          <div className="card__action">
            <button
              className="btn btn--green"
              type="button"
              onClick={() => {
                if (sessionId) joinOnlineSession(sessionId);
              }}
              disabled={isDisableBts}
            >
              Join online session
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
