/**
 * @author Salma Hefnawy
 * @date 2021-08-29
 * @description implementation of date comparison function.
 * @filename date-compare.ts
 */
import { convertStringDateFromUTC } from './format-date';

export function dateCompare(d1?: string, d2?: string): string {
  const startDate = convertStringDateFromUTC(d1);
  const endDate = convertStringDateFromUTC(d2);
  const now = new Date();

  const minutesToSubtract = 10;
  const timeBeforeStartDateByXMinutes = new Date(startDate.getTime() - minutesToSubtract * 60000);

  if (endDate < now) {
    return 'ended';
  } else if (endDate > now && timeBeforeStartDateByXMinutes <= now) {
    return 'ongoing';
  } else if (timeBeforeStartDateByXMinutes > now) {
    return 'upcoming';
  }
}
