/**
 * @author Raghda Wessam
 * @date 2021-03-10
 * @description declaration of available Error messages in the app.
 * @filename errors.ts
 */

/**
 * set of available errors in the App.
 */
export const ERRORS = {
  unexpected: 'Please make sure you are adding valid values.',
};
