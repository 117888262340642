import React, { useEffect, useState } from 'react';
import ReviewLogo from '../../../../../static/images/icons/review_stars.svg';
import CurrentClass from '../../../../../static/images/icons/current_class.svg';
import ReadingLogo from '../../../../../static/images/icons/reading.svg';
import Pagination from 'src/ts/react-components/common/pagination';
import { CourseBody } from '../../../../definitions/interfaces/entity-courses-listing';
import { ROUTES } from '../../../../definitions/consts/routes';
import { Link, useHistory } from 'react-router-dom';
import { capitalizeFirstLetter } from 'src/ts/utilities/common';
import Loader from 'src/ts/react-components/common/loader';
import { PresenterCourse as PresenterCourseUtilities } from 'src/ts/api/presenter-course';
import { formate } from 'src/ts/utilities/moment-formate-Date';
import placeHolderImg from 'src/static/images/placeholder.png';

const PresenterCourses: React.FunctionComponent = () => {
  const [courses, setCourses] = useState<CourseBody[] | null>(null);
  const [loadingCourses, setLoadingCourses] = useState<boolean | null>(null);
  const [currentCourses, setcurrentCourses] = useState(0);
  const [coursesPerPage] = useState(10);
  const [isLastPage, setisLastPage] = useState(false);
  const history = useHistory();
  const searchBody = { keyword: '', subjects: [], publishingStatus: [], gradeIds: [] };

  useEffect(() => {
    setLoadingCourses(true);
    PresenterCourseUtilities.getAllCourses(currentCourses, coursesPerPage, { ...searchBody })
      .then((response) => {
        setLoadingCourses(false);
        if (response?.data.length > 0) {
          setCourses(response?.data);
          setisLastPage(false);
        } else {
          setisLastPage(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setCourses(null);
      });
  }, [currentCourses]);

  // Change page
  const paginate = (pageNumber) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    setcurrentCourses(pageNumber);
  };

  if (loadingCourses) return <Loader />;
  return (
    <>
      <div className="courses-title">
        <h2 className="section-title">My Courses</h2>
        <Link className="btn btn--green add-new-course" to={ROUTES.addPresenterCourse.path}>
          <span>+ </span>
          <p> Add new course</p>
        </Link>
      </div>
      {courses &&
        courses?.map((item) => {
          return (
            <div
              onClick={() => {
                history.push(
                  `${ROUTES.presenterCourseDetails.getPath && ROUTES.presenterCourseDetails.getPath(item.course.id)}`
                );
              }}
              key={item.course.id}
              className="course-card"
            >
              <div className="course-card__image">
                <img src={item.course.image} alt="Course Image" />
              </div>
              <div className="course-card__container">
                <div className="course-card__content">
                  <div className="course-card__course-details">
                    <p className="course-card__creation-date">Created date {formate(item.course.createdAt)}</p>
                    <h2 className="course-card__name">{item.course.title}</h2>
                    <p className="course-card__description text-initial">
                      {`${item.course.groupFocusValue} | ${item.course?.schoolSystems?.map((system) => {
                        return (
                          ' ' +
                          system?.name +
                          ' ' +
                          `(${system?.grades?.map((grade) => {
                            return grade?.name + ' ';
                          })})`
                        );
                      })}`}
                    </p>
                    <div className="course-card__review">
                      <img src={ReviewLogo} alt="Review Icon" />
                      <span>
                        {item.course?.reviewSummary?.rate} ({item.course?.reviewSummary?.count}{' '}
                        {item.course?.reviewSummary?.count > 1 ? 'Learners' : 'Learner'})
                      </span>
                    </div>
                  </div>

                  <div className="course-card__course-info">
                    <div className="course-card__author-details">
                      <img className="author-image" src={item.presenter.image || placeHolderImg} alt="" />
                      <span>
                        by {`${capitalizeFirstLetter(item.presenter.title)}.`} {item.presenter.firstName}
                        &nbsp;
                        {item.presenter.lastName}
                      </span>
                    </div>
                    <div className="course-card__class-details">
                      <img className="class-icon" src={CurrentClass} alt="Class Icon" />
                      <span>Current class: {item.course.currentClassName}</span>
                    </div>
                    <div className="course-card__booking-details">
                      <img className="booking-icon" src={ReadingLogo} alt="Booking Icon" />
                      <span>{item.course.capacity.fullBookingCount} Learner has booked</span>
                      <span className="max-booking">Max ({item.course.capacity.perClass})</span>
                    </div>
                  </div>
                </div>
                <div className="course-card__course-labels">
                  <div className="label">
                    <p>{item.course.type.name}</p>
                  </div>
                  <div
                    className={'label ' + (item.course.status.name === 'unpublished' ? 'red-border' : 'green-border')}
                  >
                    <p style={{ color: item.course.status.name === 'unpublished' ? '#F85353' : '#13C19F' }}>
                      {item.course.status.name === 'published' ? 'Available For Booking' : 'Not Published'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      {courses && (
        <Pagination
          currentItemsLength={courses.length}
          currentDisplayedCourses={currentCourses}
          itemPerPage={coursesPerPage}
          paginate={paginate}
          isLastPage={isLastPage}
        />
      )}
    </>
  );
};

export default PresenterCourses;
