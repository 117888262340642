import React, { useEffect, useState } from 'react';
import { UserCourses } from 'src/ts/api/user-courses';
import { CourseDetailsInterface } from 'src/ts/definitions/interfaces/CourseDetailsInterface';
import { LessonDetailsInterface } from 'src/ts/definitions/interfaces/LessonDetailsInterface';
import { useParams } from 'react-router-dom';
import LessonsOutline from './outline';
import CourseInfo from './course-info';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/ts/definitions/consts/routes';

const CourseDetails: React.FunctionComponent = () => {
  const [courseDetails, setCourseDetails] = useState<CourseDetailsInterface | null>();
  const [loadingCourseDetails, setLoadingCourseDetails] = useState<boolean>(true);
  const [lessonDetails, setLessonDetails] = useState<LessonDetailsInterface | null>();
  const history = useHistory();
  const { courseId } = useParams<{ courseId: string }>();

  const getCourseDetails = () => {
    setLoadingCourseDetails(true);
    UserCourses.getLearnerCourseDetails(courseId)
      .then((response) => {
        setCourseDetails(response.data);
        setLoadingCourseDetails(false);
      })
      .catch((error) => {
        history.push({
          pathname: `${ROUTES.userDashboard.path}`,
        });
        console.error(error);
      });
  };
  const getLessonDetails = () => {
    UserCourses.getLearnerLessonDetails(courseId)
      .then((response) => {
        setLessonDetails(response.data);
      })
      .catch((error) => console.error({ error }));
  };

  useEffect(() => {
    getCourseDetails();
    getLessonDetails();
  }, []);
  const courseInfo = courseDetails?.course;
  const schoolSystems = courseDetails?.schoolSystems?.map((s) => s).join(' , ');
  const grades = courseDetails?.grades?.map((s) => s).join(' , ');
  const courseDescription = courseDetails?.course?.description;
  const courseObjective = courseDetails?.course?.objective;
  const courseImage = courseDetails?.course?.image;
  //
  const presenterInfo = courseDetails?.presenter;
  //
  const organizationInfo = courseDetails?.organization;
  //
  const courseDateWeekDays = courseDetails?.course?.weekDays?.map((day) => day).join(' & ');
  //
  const courseCapacity = courseDetails?.course?.capacity;
  //
  const courseType = courseDetails?.course?.type;

  //

  const lessonsCount = lessonDetails?.count?.lessons;
  const classesCountInLesson = lessonDetails?.count?.classes;
  if (loadingCourseDetails) {
    return (
      <div className="loading-container">
        <p>Loading...</p>
      </div>
    );
  }
  return (
    <div className="course-details-wrapper">
      <div className="course-details-wrapper__container">
        <div className="course-details-wrapper__course-mainInfo">
          <CourseInfo
            courseTitle={courseInfo?.title}
            subjectName={courseInfo?.groupFocusName}
            schoolSystems={schoolSystems}
            grades={grades}
            courseDescription={courseDescription}
            courseObjective={courseObjective}
            courseImage={courseImage}
            presenterImage={presenterInfo?.image}
            presenterTitle={presenterInfo?.title}
            presenterFName={presenterInfo?.firstName}
            presenterLName={presenterInfo?.lastName}
            organizationImage={organizationInfo?.image}
            organizationName={organizationInfo?.name}
            organizationAddress={organizationInfo?.branch?.addressName}
          />
        </div>
        <div className="courseDetails_hr"></div>
        {/* Course Weekly Dates Section */}
        <div className="course-details-wrapper__course-dates">
          <h2>Course weekly dates: {courseDateWeekDays}</h2>
          <h4>
            Group of {courseCapacity} students | {courseType?.name} sessions
          </h4>
        </div>
        {/* End of Course Weekly Dates Section */}
        <div className="courseDetails_hr"></div>
        {/* Start of Course Lessons Section */}

        <div className="course-details-wrapper__lesson-content">
          <h2>Lesson content</h2>
          <h4>
            {lessonsCount} Lessons | {classesCountInLesson} classes
          </h4>
          {lessonDetails?.lessons?.map((lesson) => (
            <div className="accordion-lessons" key={lesson?.id}>
              <LessonsOutline lesson={lesson} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default CourseDetails;
