import React, { useState } from 'react';
import Toastr from 'toastr';
import CloseModalIcon from '../../../../../static/images/icons/popup_close.svg';
import { PresenterCourse } from 'src/ts/api/presenter-course';
import { AddLessonInput } from 'src/ts/definitions/interfaces/inputs/add-lesson-input';

export interface EditLessonProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  courseId: string;
  lessonId?: any;
  lessonName: string;
  setShouldRefreshListing: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditPresenterLesson: React.FunctionComponent<EditLessonProps> = (props) => {
  const { setShowModal, courseId, setShouldRefreshListing, lessonId, lessonName } = props;
  const [newLessonName, setNewLessonName] = useState(lessonName);

  const handleAddLesson = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const editLessonInput: AddLessonInput = {
      lessonName: newLessonName,
    };
    PresenterCourse.editPresenterLesson(courseId, lessonId, editLessonInput)
      .then(() => {
        Toastr.success('Lesson updated successfully');
        setShouldRefreshListing(true);
      })
      .catch((error) => {
        Toastr.error(error);
        console.error(error);
      })
      .finally(() => {
        setShowModal(false);
        setShouldRefreshListing(false);
      });
  };

  return (
    <div className="add-lesson">
      <div className="add-lesson__header">
        <h1 className="add-lesson__header-title">Edit lesson</h1>
        <button
          type="button"
          className="add-lesson__header-button"
          onClick={() => {
            setShowModal(true);
            setShowModal(false);
          }}
        >
          <img className="add-lesson__header-image" src={CloseModalIcon} alt="" />
        </button>
      </div>
      <div className="add-lesson__content">
        <div className="add-lesson__input">
          <label htmlFor="lesson-name">
            lesson name <span className="required-field"> *</span>
          </label>
          <input
            placeholder="Lesson name here"
            type="text"
            defaultValue={lessonName}
            onChange={(e) => setNewLessonName(e.target.value)}
          />
        </div>
        <button type="button" className="add-lesson__content-button btn" onClick={(e) => handleAddLesson(e)}>
          Update
        </button>
      </div>
    </div>
  );
};
export default EditPresenterLesson;
