import { Network } from './network';
import { ENDPOINTS } from './endpoints';
import { Payload } from '../definitions/interfaces/payload';
import { Teacher } from './../definitions/interfaces/teacher-entity';
import { UserType } from '../definitions/interfaces/common';
import { TeacherLoginInput } from './../definitions/interfaces/inputs/teacher-login';

/**
 * implement network related calls.
 */
export class TeacherAuthentication {
  /**
   * call Login endpoint and set organization authentication keys in local storage.
   *
   * @param input teacher login inputs.
   */

  public static presenterLogin(input: TeacherLoginInput): Promise<Payload<Teacher>> {
    const body = {
      ...input,
    };
    const promise = Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.loginPresenter.path}`, {
      method: ENDPOINTS.loginPresenter.method,
      body: JSON.stringify(body),
    });

    return promise.then((payload: Payload<Teacher>) => {
      if (payload?.data?.token && process.env.REACT_APP_TOKEN_DETAILS) {
        Network.storeTokenDetails(
          payload.data.token.access,
          payload.data.token.refresh,
          Number(payload.data.token.expireAt),
          UserType.teacher
        );
      }
      return payload;
    });
  }

  /**
   *fetch current logged in teacher
   *
   * @static
   * @returns {Promise<Payload<Teacher>>}
   * @memberof TeacherAuthentication
   */
  public static getTeacher(): Promise<Payload<Teacher>> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.getPresenter.path}`, {
      method: ENDPOINTS.getPresenter.method,
    });
  }

  /**
   *logout teacher
   *
   * @static
   * @returns {Promise<Payload>}
   * @memberof TeacherAuthentication
   */
  public static teacherLogout(): Promise<Payload> {
    return Network.fetch(`${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.presenterLogout.path}`, {
      method: ENDPOINTS.presenterLogout.method,
    });
  }
}
